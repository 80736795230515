import { PatientSelector } from '../../components/PatientSelector/PatientSelector';
import { type IPatientSelectorModel } from '../../components/PatientSelector/PatientSelectorModel';
import { SelectedGroupPractice } from '../../components/SelectedGroupPractice/SelectedGroupPractice';
import { theme } from '../../theme/theme';
import { withAnalyticId, withOnClickEventDelegation } from '../../utilities/analytics/eventDelegation';
import {
	createPatientSelectorModel,
	createInitializedSingletonSelectedGroupPracticeModel,
} from '../SchedulePage/SchedulePage.factories';
import { sendPatientLookupHeaderClickAnalytic } from './PatientLookupHeader.analytics';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { type ComponentProps, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const PatientLookupHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	column-gap: ${theme.paddings.sm};
`;

interface PatientLookupHeaderProps extends ComponentProps<typeof PatientLookupHeaderContainer> {}

export const PatientLookupHeader = observer(function PatientLookupHeader({ ...props }: PatientLookupHeaderProps) {
	const selectedGroupPracticeModel = createInitializedSingletonSelectedGroupPracticeModel({});

	const history = useHistory();
	const patientSelectorModel = useMemo(
		function createAndInitializePatientSelectorModel() {
			const model = createPatientSelectorModel({
				selectedGroupPracticeModel,
				onSelectedPatientIdsChange: function navigateToPatientLookup(
					...selectedPatientIds: IPatientSelectorModel['selectedPatientIds']
				) {
					const [firstSelectedPatientId] = selectedPatientIds;
					if (firstSelectedPatientId) {
						history.push(`/patient-lookup/${firstSelectedPatientId}`);
						model.reset();
					}
				},
			});

			model.initializeResetReaction();

			return model;
		},
		[selectedGroupPracticeModel],
	);

	return (
		<PatientLookupHeaderContainer
			{...{
				...props,
				...withOnClickEventDelegation(sendPatientLookupHeaderClickAnalytic),
			}}
		>
			<SelectedGroupPractice
				{...{
					selectedGroupPracticeModel,
					...withAnalyticId('SelectGroupPractice'),
				}}
			/>
			<PatientSelector
				{...{
					mode: undefined,
					placeholder: 'Search for a patient',
					patientSelectorModel,
					value: [],
					showAction: ['click'],
					style: { inlineSize: 250 },
					allowClear: false,
					...withAnalyticId('SelectPatient'),
				}}
			/>
		</PatientLookupHeaderContainer>
	);
});
