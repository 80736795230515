import type { ProtectedRouteProps } from '../auth/ProtectedRoute';
import { BaseLayout } from '../layouts/BaseLayout';
import { EmptyLayout } from '../layouts/EmptyLayout';

const EXACT_LAYOUT_ROUTE_PROPS = {
	exact: true,
} as const satisfies Pick<ProtectedRouteProps, 'exact'>;

export const EXACT_BASE_LAYOUT_ROUTE_PROPS = {
	...EXACT_LAYOUT_ROUTE_PROPS,
	layout: BaseLayout,
} as const satisfies Pick<ProtectedRouteProps, 'layout'> & typeof EXACT_LAYOUT_ROUTE_PROPS;

export const EXACT_EMPTY_LAYOUT_ROUTE_PROPS = {
	...EXACT_LAYOUT_ROUTE_PROPS,
	layout: EmptyLayout,
} as const satisfies Pick<ProtectedRouteProps, 'layout'> & typeof EXACT_LAYOUT_ROUTE_PROPS;
