import { SUPPORTED_BROWSERS_REGEX } from '../../SupportedBrowsers.generated';
import { detect } from 'detect-browser';

let maybeDetectedBrowser: DetectBrowser | null = null;

function getOrCreateSingletonDetectedBrowser(userAgent: string): DetectBrowser {
	if (!maybeDetectedBrowser) {
		const detected = detect(userAgent);
		if (!detected) {
			throw new Error('Could not detect browser');
		}
		maybeDetectedBrowser = detected;
	}

	return maybeDetectedBrowser;
}

function capitalizeFirstLetter(text: string): string {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

type DetectBrowser = NonNullable<ReturnType<typeof detect>>;
type DetectBrowserName = DetectBrowser['name'];

const OFFICIALLY_SUPPORTED_BROWSERS: ReadonlyArray<DetectBrowserName> = ['chrome', 'chromium-webview'];

export enum BrowserSupportStatus {
	Unsupported = 'Unsupported',
	Outdated = 'Outdated',
	Supported = 'Supported',
}

const BROWSER_SUPPORT_ISSUES_PREFIX_MICRO_COPY = {
	[BrowserSupportStatus.Unsupported]: `We noticed you’re using`,
	[BrowserSupportStatus.Outdated]: `We noticed you’re using`,
	[BrowserSupportStatus.Supported]: 'You are using',
} as const satisfies Record<BrowserSupportStatus, string>;

type BrowserSupportIssuesPrefixMicroCopy =
	(typeof BROWSER_SUPPORT_ISSUES_PREFIX_MICRO_COPY)[keyof typeof BROWSER_SUPPORT_ISSUES_PREFIX_MICRO_COPY];

const BROWSER_SUPPORT_ISSUES_POSTFIX_MICRO_COPY = {
	[BrowserSupportStatus.Unsupported]: `which Navina doesn’t supported`,
	[BrowserSupportStatus.Outdated]: 'which is outdated',
	[BrowserSupportStatus.Supported]: 'which is supported',
} as const satisfies Record<BrowserSupportStatus, string>;

type BrowserSupportIssuesPostfixMicroCopy =
	(typeof BROWSER_SUPPORT_ISSUES_POSTFIX_MICRO_COPY)[keyof typeof BROWSER_SUPPORT_ISSUES_POSTFIX_MICRO_COPY];

export interface IBrowserSupportModel {
	readonly isBrowserSupported: boolean;

	readonly browserName: string;
	readonly browserVersion: string;

	readonly browserStatus: BrowserSupportStatus;
	readonly browserStatusTitle: `${BrowserSupportIssuesPrefixMicroCopy} ${string} ${BrowserSupportIssuesPostfixMicroCopy}`;

	readonly browserStatusDescription: string;
}

interface BrowserSupportProps {
	readonly userAgent: string;
}

export class BrowserSupportModel implements IBrowserSupportModel {
	readonly userAgent: string;

	constructor({ userAgent }: BrowserSupportProps) {
		this.userAgent = userAgent;
	}

	get browser(): DetectBrowser {
		return getOrCreateSingletonDetectedBrowser(this.userAgent);
	}

	static readonly create = (propsAndParams: BrowserSupportProps): BrowserSupportModel => {
		return new BrowserSupportModel(propsAndParams);
	};

	get browserName(): IBrowserSupportModel['browserName'] {
		return capitalizeFirstLetter(this.browser.name);
	}

	get browserVersion(): IBrowserSupportModel['browserVersion'] {
		return this.browser.version || 'N/A';
	}

	get isBrowserSupported(): IBrowserSupportModel['isBrowserSupported'] {
		return SUPPORTED_BROWSERS_REGEX.test(this.userAgent);
	}

	get browserStatus(): IBrowserSupportModel['browserStatus'] {
		if (this.isBrowserSupported) {
			return BrowserSupportStatus.Supported;
		}

		if (OFFICIALLY_SUPPORTED_BROWSERS.includes(this.browser.name)) {
			return BrowserSupportStatus.Outdated;
		}

		return BrowserSupportStatus.Unsupported;
	}

	get browserStatusTitle(): IBrowserSupportModel['browserStatusTitle'] {
		const prefixMicroCopy: BrowserSupportIssuesPrefixMicroCopy =
			BROWSER_SUPPORT_ISSUES_PREFIX_MICRO_COPY[this.browserStatus];
		const postfixMicroCopy: BrowserSupportIssuesPostfixMicroCopy =
			BROWSER_SUPPORT_ISSUES_POSTFIX_MICRO_COPY[this.browserStatus];
		const browserNameAndVersion = `${this.browserName} ${this.browserVersion}`;

		return `${prefixMicroCopy} ${browserNameAndVersion} ${postfixMicroCopy}`;
	}

	get browserStatusDescription(): IBrowserSupportModel['browserStatusDescription'] {
		if (this.isBrowserSupported) {
			return `Your browser is up to date and supported by Navina.`;
		}

		return `To ensure full compatibility and avoid potential issues, please switch to or update to the latest version of Google Chrome.`;
	}
}
