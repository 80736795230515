/**
 * THIS FILE IS GENERATED BY `update-supported-browsers.sh`.
 * DO NOT MODIFY IT DIRECTLY.
 */

const IS_STORYBOOK = process.env.STORYBOOK === 'true';

/**
 * The main supported browsers regex, generated by browserslist-useragent-regexp.
 */
const DEFAULT_SUPPORTED_BROWSERS_REGEX = /Edge?\/(12[6-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|Firefox\/(12[7-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|Chrom(ium|e)\/(109|1[1-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|(Maci|X1{2}).+ Version\/(17\.([5-9]|\d{2,})|(1[89]|[2-9]\d|\d{3,})\.\d+)([,.]\d+|)( \(\w+\)|)( Mobile\/\w+|) Safari\/|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(17[._]([3-9]|\d{2,})|(1[89]|[2-9]\d|\d{3,})[._]\d+)([._]\d+|)|Android.+Chrom(ium|e)\/(12[7-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)/;

/**
 * A fallback regex for Storybook and Chromatic tests.
 */
const STORYBOOK_SUPPORTED_BROWSERS_REGEX =
	/Edge?\/(12[6-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|Firefox\/(12[7-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|Chrom(ium|e)\/(109|1[1-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|(Maci|X1{2}).+ Version\/(17\.([5-9]|\d{2,})|(1[89]|[2-9]\d|\d{3,})\.\d+)([,.]\d+|)( \(\w+\)|)( Mobile\/\w+|) Safari\/|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(17[._]([3-9]|\d{2,})|(1[89]|[2-9]\d|\d{3,})[._]\d+)([._]\d+|)|Android.+Chrom(ium|e)\/(12[7-9]|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)/;

export const SUPPORTED_BROWSERS_REGEX = IS_STORYBOOK ? STORYBOOK_SUPPORTED_BROWSERS_REGEX : DEFAULT_SUPPORTED_BROWSERS_REGEX;
