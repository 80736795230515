import { getFormattedIso8601Date, getNowDate, type Iso8601DateFormat } from '../../../utilities/dateFormatting';
import { type ISelectedGroupPracticeModel } from '../../SelectedGroupPractice/SelectedGroupPracticeModel';
import { AppointmentRowModel, type IAppointmentRowModel } from './AppointmentRowModel';
import { type IScheduleFiltersContextStore } from './BaseScheduleSearchFiltersStore/BaseScheduleFiltersContextStore';
import { type IScheduleSearchFiltersStore } from './BaseScheduleSearchFiltersStore/BaseScheduleSearchFiltersStore';
import { EntitiesFetchingStatus } from './types';
import type { SchedulePageSearchRequestPayload, SchedulePageSearchResponsePayload } from '@navina/api-types';
import { action, flow, makeAutoObservable, reaction } from 'mobx';
import { sha1 } from 'object-hash';

export const ENTITIES_PER_PAGE_OPTIONS = [15, 30, 50] as const satisfies Array<number>; // AntD does not support readonly arrays
const DEFAULT_PAGE_SIZE: (typeof ENTITIES_PER_PAGE_OPTIONS)[number] = 15;
const MINIMUM_PAGE_NUMBER = 1;
const DATA_FETCHING_DEBOUNCE_DELAY_IN_MILLISECONDS = 100;

function isAppointmentRowsPerPageOption(
	entitiesPerPage: number,
): entitiesPerPage is (typeof ENTITIES_PER_PAGE_OPTIONS)[number] {
	return ENTITIES_PER_PAGE_OPTIONS.includes(entitiesPerPage as (typeof ENTITIES_PER_PAGE_OPTIONS)[number]);
}

type GetScheduleAppointmentsType = (
	schedulePageSearchRequestPayload: SchedulePageSearchRequestPayload,
) => Promise<SchedulePageSearchResponsePayload>;

export interface IAppointmentsScheduleStore {
	readonly pageNumber: SchedulePageSearchRequestPayload['pagination']['currentPage'];
	readonly appointmentRowsPerPage: number;
	readonly selectedIsoDate: Iso8601DateFormat;
	readonly totalAppointmentRows: number;
	readonly headerConfiguration?: HeaderConfiguration; // TODO - Move it away from here
	readonly getScheduleAppointments: GetScheduleAppointmentsType;
	readonly appointmentRowModels: ReadonlyArray<IAppointmentRowModel>;
	readonly appointmentRowsFetchingStatus: EntitiesFetchingStatus;
	readonly setPageNumberAndFetchAppointmentsRowsWithDebounce: (
		pageNumber: SchedulePageSearchRequestPayload['pagination']['currentPage'],
	) => void;
	readonly resetPageNumberAndFetchAppointmentsRowsWithDebounce: VoidFunction;
	readonly setAppointmentRowsPerPageAndFetchAppointments: (
		appointmentRowsPerPage: SchedulePageSearchRequestPayload['pagination']['entitiesPerPage'],
	) => void;
	readonly setSelectedIsoDateAndFetchAppointments: (
		selectedIsoDate: SchedulePageSearchRequestPayload['iso8601DateWithoutTime'],
	) => void;

	readonly scheduleFiltersContextStore: IScheduleFiltersContextStore;
	readonly scheduleSearchFiltersStore: IScheduleSearchFiltersStore;
	readonly selectedGroupPracticeModel: ISelectedGroupPracticeModel;

	readonly setPageNumberAndEntitiesPerPage: (newPageNumber: number, newEntitiesPerPage: number) => void;

	readonly fetchAppointmentRowsFlow: (debounceInMilliseconds?: number) => void;
	readonly fetchAppointmentRowsWithDebounce: (debounceInMilliseconds?: number) => void;

	readonly initializeAppointmentFetchReaction: VoidFunction;
	readonly initializeAppointmentFetch: VoidFunction;
}

interface HeaderConfiguration {
	readonly showDatePickerButton: boolean;
	readonly showTodayButton: boolean;
}

type ConstructorAppointmentsScheduleStoreProperties =
	| 'getScheduleAppointments'
	| 'scheduleSearchFiltersStore'
	| 'scheduleFiltersContextStore'
	| 'headerConfiguration'
	| 'selectedGroupPracticeModel';
export interface BaseAppointmentsScheduleStoreParams
	extends Pick<IAppointmentsScheduleStore, ConstructorAppointmentsScheduleStoreProperties> {}

export class BaseAppointmentsScheduleStore implements IAppointmentsScheduleStore {
	readonly scheduleFiltersContextStore: IScheduleFiltersContextStore;
	readonly getScheduleAppointments: GetScheduleAppointmentsType;
	readonly scheduleSearchFiltersStore: IScheduleSearchFiltersStore;
	readonly selectedGroupPracticeModel: ISelectedGroupPracticeModel;

	pageNumber = MINIMUM_PAGE_NUMBER;
	appointmentRowsPerPage = DEFAULT_PAGE_SIZE;
	totalAppointmentRows = 0;
	selectedIsoDate = getFormattedIso8601Date({ date: getNowDate() });

	// Consider moving this out from the logic
	headerConfiguration: HeaderConfiguration = {
		showDatePickerButton: true,
		showTodayButton: true,
	};

	appointmentRowModels: ReadonlyArray<IAppointmentRowModel> = [];
	appointmentRowsFetchingStatus = EntitiesFetchingStatus.Idle;

	private constructor({
		headerConfiguration,
		getScheduleAppointments,
		scheduleSearchFiltersStore,
		scheduleFiltersContextStore,
		selectedGroupPracticeModel,
	}: BaseAppointmentsScheduleStoreParams) {
		makeAutoObservable(this, {
			setPageNumber: action,
			setAppointmentRowsPerPage: action,
			setSelectedIsoDate: action,
			setTotalAppointmentRows: action,
			setHeaderConfiguration: action,

			fetchAppointmentRowsWithDebounce: action,
			fetchAppointmentRowsFlow: action,
		});

		this.scheduleSearchFiltersStore = scheduleSearchFiltersStore;
		this.getScheduleAppointments = getScheduleAppointments;
		this.scheduleFiltersContextStore = scheduleFiltersContextStore;
		this.selectedGroupPracticeModel = selectedGroupPracticeModel;

		if (headerConfiguration) {
			this.setHeaderConfiguration(headerConfiguration);
		}
	}

	readonly initializeAppointmentFetchReaction = (): void => {
		const { initializeAppointmentFetch } = this;

		reaction(() => this.selectedGroupPracticeModel.selectedGroupPracticeId, initializeAppointmentFetch);
	};

	readonly initializeAppointmentFetch = (): void => {
		this.fetchAppointmentRowsWithDebounce(0);
	};

	static readonly create = (propsAndParams: BaseAppointmentsScheduleStoreParams): BaseAppointmentsScheduleStore => {
		return new BaseAppointmentsScheduleStore(propsAndParams);
	};

	readonly setPageNumber = (pageNumber: typeof this.pageNumber): void => {
		if (pageNumber < MINIMUM_PAGE_NUMBER) {
			throw Error(`Invalid pageNumber '${pageNumber}'. It should be greater than or equal to ${MINIMUM_PAGE_NUMBER}`);
		}

		this.pageNumber = pageNumber;
	};

	readonly setPageNumberAndFetchAppointmentsRowsWithDebounce = (pageNumber: typeof this.pageNumber): void => {
		this.setPageNumber(pageNumber);
		this.fetchAppointmentRowsWithDebounce();
	};

	readonly resetPageNumberAndFetchAppointmentsRowsWithDebounce = (): void => {
		this.setPageNumberAndFetchAppointmentsRowsWithDebounce(MINIMUM_PAGE_NUMBER);
	};

	readonly setAppointmentRowsPerPageAndFetchAppointments = (
		appointmentRowsPerPage: typeof this.appointmentRowsPerPage,
	): void => {
		this.setAppointmentRowsPerPage(appointmentRowsPerPage);
		this.fetchAppointmentRowsWithDebounce();
	};

	readonly setSelectedIsoDateAndFetchAppointments = (selectedIsoDate: typeof this.selectedIsoDate): void => {
		this.setSelectedIsoDate(selectedIsoDate);
		this.resetPageNumberAndFetchAppointmentsRowsWithDebounce();
	};

	readonly setAppointmentRowsPerPage = (appointmentRowsPerPage: number): void => {
		if (!isAppointmentRowsPerPageOption(appointmentRowsPerPage)) {
			throw Error(`Invalid appointmentRowsPerPage: ${appointmentRowsPerPage}`);
		}

		this.appointmentRowsPerPage = appointmentRowsPerPage;
	};

	readonly setSelectedIsoDate = (selectedIsoDate: typeof this.selectedIsoDate): void => {
		this.selectedIsoDate = selectedIsoDate;
	};

	readonly setPageNumberAndEntitiesPerPage = (newPageNumber: number, newEntitiesPerPage: number) => {
		this.setPageNumber(newPageNumber);
		this.setAppointmentRowsPerPage(newEntitiesPerPage);
	};

	readonly setTotalAppointmentRows = (totalAppointmentRows: typeof this.totalAppointmentRows): void => {
		this.totalAppointmentRows = totalAppointmentRows;
	};

	readonly setHeaderConfiguration = (headerConfiguration: typeof this.headerConfiguration): void => {
		this.headerConfiguration = headerConfiguration;
	};

	private _ongoingFetchAppointmentRowsFlow: ReturnType<typeof this.fetchAppointmentRowsFlow> | null = null;
	readonly cancelAndFetchAppointmentRows = (): void => {
		this._ongoingFetchAppointmentRowsFlow?.cancel();
		this._ongoingFetchAppointmentRowsFlow = this.fetchAppointmentRowsFlow();
	};

	private _fetchAppointmentRowsTimeoutId: number | null = null;
	readonly fetchAppointmentRowsWithDebounce = (
		debounceInMilliseconds = DATA_FETCHING_DEBOUNCE_DELAY_IN_MILLISECONDS,
	): void => {
		if (this._fetchAppointmentRowsTimeoutId) {
			window.clearTimeout(this._fetchAppointmentRowsTimeoutId);
		}
		this._fetchAppointmentRowsTimeoutId = window.setTimeout(this.cancelAndFetchAppointmentRows, debounceInMilliseconds);
	};

	readonly retrieveCurrentScheduleCriteria = (): SchedulePageSearchRequestPayload => {
		const { getCurrentScheduleFilters } = this.scheduleSearchFiltersStore;
		const searchingFilters = getCurrentScheduleFilters();

		if (!this.selectedGroupPracticeModel.selectedGroupPracticeId) {
			throw Error('No selectedGroupPracticeId');
		}

		const getScheduleAppointmentsRequestPayload = {
			iso8601DateWithoutTime: this.selectedIsoDate,
			groupPracticeId: this.selectedGroupPracticeModel.selectedGroupPracticeId,
			pagination: {
				currentPage: this.pageNumber,
				entitiesPerPage: this.appointmentRowsPerPage,
			},
			filters: searchingFilters,
		} as const satisfies SchedulePageSearchRequestPayload;

		return getScheduleAppointmentsRequestPayload;
	};

	private _previousRequestPayloadSha1Checksum: string | null = null;
	readonly fetchAppointmentRowsFlow = flow(function* fetchAppointmentRowsFlow(this: BaseAppointmentsScheduleStore) {
		try {
			this.scheduleFiltersContextStore.setSchedulePageSearchRequestPayload(this.retrieveCurrentScheduleCriteria());

			const newRequestPayloadSha1Checksum = sha1(this.scheduleFiltersContextStore.payload);
			if (newRequestPayloadSha1Checksum !== this._previousRequestPayloadSha1Checksum) {
				this.appointmentRowsFetchingStatus = EntitiesFetchingStatus.Pending;
				this.appointmentRowModels = [];
				const response: Awaited<ReturnType<IAppointmentsScheduleStore['getScheduleAppointments']>> =
					yield this.getScheduleAppointments(this.scheduleFiltersContextStore.payload);

				const { entities, totalEntities } = response;

				this.appointmentRowModels = entities.map((entity) =>
					AppointmentRowModel.create({ schedulePageSummary: entity }),
				);
				this.totalAppointmentRows = totalEntities;
				this.appointmentRowsFetchingStatus = EntitiesFetchingStatus.Success;
			}

			this._previousRequestPayloadSha1Checksum = newRequestPayloadSha1Checksum;
		} catch (error) {
			console.error('Error while fetching appointment rows:', error);
			this.appointmentRowsFetchingStatus = EntitiesFetchingStatus.Error;
		}
	});
}

let maybeBaseAppointmentsScheduleStore: IAppointmentsScheduleStore | null = null;

export function getOrCreateBaseAppointmentsScheduleStore(
	propsAndParams: BaseAppointmentsScheduleStoreParams,
): IAppointmentsScheduleStore {
	if (maybeBaseAppointmentsScheduleStore) {
		return maybeBaseAppointmentsScheduleStore;
	}

	maybeBaseAppointmentsScheduleStore = BaseAppointmentsScheduleStore.create(propsAndParams);
	// TODO - Ideally move even further out
	maybeBaseAppointmentsScheduleStore.initializeAppointmentFetchReaction();

	return maybeBaseAppointmentsScheduleStore;
}

export function getBaseAppointmentsScheduleStoreOrNull(): IAppointmentsScheduleStore | null {
	return maybeBaseAppointmentsScheduleStore;
}
