import { PageContainerWithoutBackground } from '../PageContainerWithoutBackground';
import { type NavinaLayout } from '../auth/RouteType';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

interface ForeverSpinnerLayoutProps extends NavinaLayout {}

export const ForeverSpinnerLayout = observer(function ForeverSpinnerLayout({
	component: Component,
	...props
}: ForeverSpinnerLayoutProps) {
	return (
		<PageContainerWithoutBackground>
			<Suspense fallback={null}>
				<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
				<Component {...props} />
			</Suspense>
		</PageContainerWithoutBackground>
	);
});
