import { type GetClinicsDepartmentsProvidersResponsePayload } from '../types';

export type Department = GetClinicsDepartmentsProvidersResponsePayload['departments'][0];
export type Provider = GetClinicsDepartmentsProvidersResponsePayload['providers'][0];
export type InsuranceGroup = GetClinicsDepartmentsProvidersResponsePayload['insuranceGroups'][0];

// TODO - make those shared and exportable

export enum ScheduleFiltersCacheableEntity {
	Departments = 'Departments',
	Providers = 'Providers',
	InsuranceGroups = 'InsuranceGroups',
}

interface ScheduleFiltersCacheableEntities<T> {
	readonly getEntities: () => Promise<ReadonlyArray<T>>;
	readonly setEntities: (entities: ReadonlyArray<T>) => Promise<void>;
}

export type ScheduleFiltersCachingStrategies = {
	readonly [ScheduleFiltersCacheableEntity.Departments]: ScheduleFiltersCacheableEntities<Department>;
	readonly [ScheduleFiltersCacheableEntity.Providers]: ScheduleFiltersCacheableEntities<Provider>;
	readonly [ScheduleFiltersCacheableEntity.InsuranceGroups]: ScheduleFiltersCacheableEntities<InsuranceGroup>;
};
