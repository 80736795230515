import { type NavinaAuthRoute } from './RouteType';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';

interface RedirectToUrlProps {
	readonly url: string;
}

const RedirectToUrl = ({ url }: RedirectToUrlProps) => {
	useEffect(function navigateToUrl(): void {
		window.location.href = url;
	}, []);

	return null;
};

interface ExternalRedirectRouteProps extends NavinaAuthRoute, RedirectToUrlProps {}

export const ExternalRedirectRoute = observer(function ExternalRedirectRoute({
	url,
	...rest
}: ExternalRedirectRouteProps) {
	return <Route render={(props) => <RedirectToUrl url={url} {...rest} {...props} />} />;
});
