import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import { type SelectedGroupPracticeResponsePayload, type NavinaGroupPracticeId } from '@navina/api-types';
import type { SelectProps } from 'antd';
import { action, flow, makeAutoObservable } from 'mobx';

export interface ISelectedGroupPracticeModel {
	readonly selectedGroupPracticeId: NavinaGroupPracticeId | null;
	readonly accountsWithGroupPracticeList: SelectedGroupPracticeResponsePayload;
	readonly groupPracticeListFetchingStatus: EntitiesFetchingStatus;
	readonly setSelectedGroupPracticeId: (id: NavinaGroupPracticeId) => void;
	readonly selectOptions: NonNullable<SelectProps['options']>;
	readonly canSelectGroupPracticeId: boolean;
	readonly allGroupPractices: SelectedGroupPracticeResponsePayload[number]['groupPractices'];
	readonly initializeFetchGroupPractice: VoidFunction;
}

export interface SelectedGroupPracticeModelParams {
	readonly getAllGroupPracticeList: () => Promise<SelectedGroupPracticeResponsePayload>;
	readonly selectedGroupPracticeId?: NavinaGroupPracticeId;
}

export class SelectedGroupPracticeModel implements ISelectedGroupPracticeModel {
	readonly getAllGroupPracticeList: SelectedGroupPracticeModelParams['getAllGroupPracticeList'];
	accountsWithGroupPracticeList: ISelectedGroupPracticeModel['accountsWithGroupPracticeList'] = [];
	groupPracticeListFetchingStatus: ISelectedGroupPracticeModel['groupPracticeListFetchingStatus'] =
		EntitiesFetchingStatus.Idle;
	selectedGroupPracticeId: ISelectedGroupPracticeModel['selectedGroupPracticeId'] = null;

	constructor({ getAllGroupPracticeList, selectedGroupPracticeId: selectedId }: SelectedGroupPracticeModelParams) {
		makeAutoObservable(this, {
			setSelectedGroupPracticeId: action,
			setGroupPracticeList: action,
			setGroupPracticeListFetchingStatus: action,

			fetchGroupPracticeListFlow: flow,
		});

		this.getAllGroupPracticeList = getAllGroupPracticeList;
		if (selectedId) {
			this.selectedGroupPracticeId = selectedId;
		}
	}

	static readonly create = (propsAndParams: SelectedGroupPracticeModelParams): SelectedGroupPracticeModel => {
		return new SelectedGroupPracticeModel(propsAndParams);
	};

	readonly initializeFetchGroupPractice = (): void => {
		this.fetchGroupPracticeListFlow();
	};

	readonly setGroupPracticeList = (accountsWithGroupPracticeList: typeof this.accountsWithGroupPracticeList): void => {
		this.accountsWithGroupPracticeList = accountsWithGroupPracticeList;
	};

	readonly setGroupPracticeListFetchingStatus = (status: typeof this.groupPracticeListFetchingStatus): void => {
		this.groupPracticeListFetchingStatus = status;
	};

	readonly setSelectedGroupPracticeId = (id: typeof this.selectedGroupPracticeId): void => {
		this.selectedGroupPracticeId = id;
	};

	readonly fetchGroupPracticeListFlow = flow(function* fetchPatientOptionsFlow(this: SelectedGroupPracticeModel) {
		this.setGroupPracticeListFetchingStatus(EntitiesFetchingStatus.Pending);

		try {
			const groupPracticeList = yield this.getAllGroupPracticeList();
			this.setGroupPracticeList(groupPracticeList);
			this.setGroupPracticeListFetchingStatus(EntitiesFetchingStatus.Success);

			if (!this.selectedGroupPracticeId) {
				const [firstAccountWithGroupPractice] = groupPracticeList;
				const [firstGroupPractice] = firstAccountWithGroupPractice.groupPractices;
				this.setSelectedGroupPracticeId(firstGroupPractice.id);
			}
		} catch (error) {
			console.error('Error while fetching group practice list', { error, this: this });
			this.setGroupPracticeListFetchingStatus(EntitiesFetchingStatus.Error);
		}
	});

	get selectOptions(): ISelectedGroupPracticeModel['selectOptions'] {
		return this.accountsWithGroupPracticeList.map(
			function getAccountWithGroupPracticeOption(accountWithGroupPractices) {
				const { name: accountName, groupPractices } = accountWithGroupPractices;
				return {
					label: accountName,
					title: accountName,
					options: groupPractices.map(function getGroupPracticeOption(groupPractice) {
						const { name: groupName, id: groupId } = groupPractice;
						return {
							label: groupName,
							value: groupId,
						};
					}),
				} as const satisfies ISelectedGroupPracticeModel['selectOptions'][number];
			},
		);
	}

	get allGroupPractices(): ISelectedGroupPracticeModel['allGroupPractices'] {
		return this.accountsWithGroupPracticeList.flatMap(function getGroupPractices(accountWithGroupPractice) {
			return accountWithGroupPractice.groupPractices;
		});
	}

	get canSelectGroupPracticeId(): ISelectedGroupPracticeModel['canSelectGroupPracticeId'] {
		return this.allGroupPractices.length > 1;
	}
}

let maybeSelectedGroupPracticeStore: ISelectedGroupPracticeModel | null = null;

export function getOrCreateInitializedSelectedGroupPracticeStore(
	propsAndParams: SelectedGroupPracticeModelParams,
): ISelectedGroupPracticeModel {
	if (maybeSelectedGroupPracticeStore) {
		return maybeSelectedGroupPracticeStore;
	}

	maybeSelectedGroupPracticeStore = SelectedGroupPracticeModel.create(propsAndParams);
	maybeSelectedGroupPracticeStore.initializeFetchGroupPractice();

	return maybeSelectedGroupPracticeStore;
}
