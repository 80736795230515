import { format24HourTimeTo12HourTime, getFormattedDate } from '../../../utilities/dateFormatting';
import type { SchedulePageSummary } from '@navina/api-types';
import { makeAutoObservable } from 'mobx';

interface AppointmentRowModelParams {
	readonly schedulePageSummary: SchedulePageSummary;
}

export interface IAppointmentRowModel {
	readonly id: string;
	readonly patientFullName: string;
	readonly providerFullName: string;
	readonly formattedStartTime: string;
	readonly formattedDate: string;
	readonly emrPatientId: string;
	readonly visitReason: string;
	readonly lastScrubFormattedDate: string;
	readonly lastOfficeVisitFormattedDate: string;
	readonly insurance: string;
	readonly gapsCount: string;
	readonly timezoneFriendlyName: string;
}

export class AppointmentRowModel implements IAppointmentRowModel {
	readonly schedulePageSummary: SchedulePageSummary;

	private constructor({ schedulePageSummary }: AppointmentRowModelParams) {
		makeAutoObservable(this);

		this.schedulePageSummary = schedulePageSummary;
	}

	static create = (propsAndParams: AppointmentRowModelParams): IAppointmentRowModel => {
		return new AppointmentRowModel(propsAndParams);
	};

	get id(): IAppointmentRowModel['id'] {
		return this.schedulePageSummary.id;
	}

	get formattedStartTime(): IAppointmentRowModel['formattedStartTime'] {
		const { startTime24HoursFormat } = this.schedulePageSummary;
		return format24HourTimeTo12HourTime({ time: startTime24HoursFormat });
	}

	get emrPatientId(): IAppointmentRowModel['emrPatientId'] {
		return this.schedulePageSummary.patient.patientEmrId;
	}

	get visitReason(): IAppointmentRowModel['visitReason'] {
		return this.schedulePageSummary.visitReason;
	}

	get lastScrubFormattedDate(): IAppointmentRowModel['lastScrubFormattedDate'] {
		const { lastScrubTime } = this.schedulePageSummary;
		if (!lastScrubTime) {
			return '-';
		}

		return getFormattedDate({ date: lastScrubTime });
	}

	get lastOfficeVisitFormattedDate(): IAppointmentRowModel['lastOfficeVisitFormattedDate'] {
		const { previousEncounterDate } = this.schedulePageSummary;
		if (!previousEncounterDate) {
			return '-';
		}

		return getFormattedDate({ date: previousEncounterDate });
	}

	get providerFullName(): IAppointmentRowModel['providerFullName'] {
		return this.schedulePageSummary.provider.fullName;
	}

	get insurance(): IAppointmentRowModel['insurance'] {
		return this.schedulePageSummary.patient.insurance;
	}

	get patientFullName(): IAppointmentRowModel['patientFullName'] {
		return this.schedulePageSummary.patient.fullName;
	}

	get formattedDate(): IAppointmentRowModel['formattedDate'] {
		const { iso8601DateWithoutTime } = this.schedulePageSummary;
		return getFormattedDate({ date: iso8601DateWithoutTime });
	}

	get gapsCount(): IAppointmentRowModel['gapsCount'] {
		const { gapsCount } = this.schedulePageSummary;
		return gapsCount.toLocaleString();
	}

	get timezoneFriendlyName(): IAppointmentRowModel['timezoneFriendlyName'] {
		return this.schedulePageSummary.timezoneFriendlyName ?? 'Unknown timezone';
	}
}
