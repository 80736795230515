import { type ISelectedGroupPracticeModel } from './SelectedGroupPracticeModel';
import { Form, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps } from 'react';

const MICRO_COPY = {
	GROUP_LABEL: 'Group',
	GROUP_INPUT_ACTION: 'Select group practice',
} as const;

interface SelectedGroupPracticeProps extends Omit<ComponentProps<typeof Form>, 'children'> {
	readonly selectedGroupPracticeModel: ISelectedGroupPracticeModel;
}

export const SelectedGroupPractice = observer(function SelectedGroupPractice({
	selectedGroupPracticeModel,
	...props
}: SelectedGroupPracticeProps) {
	const { selectOptions, selectedGroupPracticeId, setSelectedGroupPracticeId, canSelectGroupPracticeId } =
		selectedGroupPracticeModel;

	return (
		<Tooltip {...{ title: MICRO_COPY.GROUP_INPUT_ACTION }}>
			<Form
				{...{
					hidden: !canSelectGroupPracticeId,
					layout: 'horizontal',
					...props,
				}}
			>
				<Form.Item
					{...{
						label: MICRO_COPY.GROUP_LABEL,
						style: { margin: 0 },
					}}
				>
					<Select
						{...{
							placeholder: MICRO_COPY.GROUP_INPUT_ACTION,
							style: { minInlineSize: 128 },
							options: [...selectOptions], // TODO - AntD refutes the use of a readonly array, fix this runtime hack
							value: selectedGroupPracticeId,
							onChange: setSelectedGroupPracticeId,
							dropdownStyle: { width: '400px' },
							showSearch: true,
							optionFilterProp: 'label',
						}}
					/>
				</Form.Item>
			</Form>
		</Tooltip>
	);
});
