import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import { Breadcrumb, type BreadcrumbProps, Skeleton } from 'antd';
import { type ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { observer } from 'mobx-react-lite';
import type { ReactNode, ComponentProps } from 'react';
import { Link } from 'react-router-dom';

interface PatientLookupBreadcrumbProps extends Omit<ComponentProps<typeof Breadcrumb>, 'children'> {
	readonly fetchingStatus: EntitiesFetchingStatus;
	readonly patientTitle: ReactNode;
}

const STATIC_BREADCRUMB_ITEMS = [
	{
		title: <Link to='/schedule'>Scheduling Overview</Link>,
	},
] as const satisfies BreadcrumbProps['items'];

export const PatientLookupBreadcrumb = observer(function PatientLookupBreadcrumb({
	patientTitle,
	fetchingStatus,
	...props
}: PatientLookupBreadcrumbProps) {
	const dynamicBreadcrumbItems = [
		{
			title: <>{patientTitle}'s Lookup</>,
		},
	] as const satisfies BreadcrumbProps['items'];

	const dynamicLoadingItems = dynamicBreadcrumbItems.map(function renderItemLoading(item): ItemType {
		return {
			...item,
			title: <Skeleton.Input {...{ active: true, size: 'small' }} />,
		} as const satisfies ItemType;
	});

	if (fetchingStatus === EntitiesFetchingStatus.Pending) {
		return (
			<Breadcrumb
				{...{
					items: [...STATIC_BREADCRUMB_ITEMS, ...dynamicLoadingItems],
					...props,
				}}
			/>
		);
	}

	return (
		<Breadcrumb
			{...{
				items: [...STATIC_BREADCRUMB_ITEMS, ...dynamicBreadcrumbItems],
				...props,
			}}
		/>
	);
});
