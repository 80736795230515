import { useNavinaData } from '../../utils/useNavinaData';
import { ReactComponent as NavinaLogoIcon } from './Logo.svg';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { type ComponentProps } from 'react';
import { Link } from 'react-router-dom';

export enum LogoSizes {
	Small = 'Small',
	Base = 'Base',
	Large = 'Large',
}

const LOGO_PIXEL_SIZES_MAP = {
	[LogoSizes.Small]: 30,
	[LogoSizes.Base]: 38,
	[LogoSizes.Large]: 44,
} as const satisfies Record<LogoSizes, number>;

export enum LogoDestination {
	Schedule = 'Schedule',
	QualityWorkspace = 'QualityWorkspace',
	Files = 'Files',
	Nowhere = 'Nowhere',
}

const LOGO_DESTINATIONS_MAP = {
	[LogoDestination.Schedule]: '/schedule',
	[LogoDestination.QualityWorkspace]: '/quality',
	[LogoDestination.Files]: '/files',
	[LogoDestination.Nowhere]: '',
} as const satisfies Record<LogoDestination, string>;

const LogoLinkContainer = styled(Link)`
	&,
	&:hover,
	&:focus,
	&:active {
		color: white;
	}

	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	block-size: var(--container-size);
	inline-size: var(--container-size);
`;

const LogoContainer = LogoLinkContainer.withComponent('div');

interface LogoProps extends Partial<ComponentProps<typeof LogoLinkContainer>> {
	readonly size: LogoSizes;
	readonly destination: LogoDestination;
}

export const Logo = observer(function Logo({ style, children = <NavinaLogoIcon />, destination, ...props }: LogoProps) {
	const { isFullScreen } = useNavinaData();

	const forwardToPage: boolean = isFullScreen && destination !== LogoDestination.Nowhere;

	const Container = forwardToPage ? LogoLinkContainer : LogoContainer;

	return (
		<Container
			{...{
				to: LOGO_DESTINATIONS_MAP[destination],
				'aria-label': 'Move to the home page',
				style: {
					...style,
					'--container-size': `${LOGO_PIXEL_SIZES_MAP[props.size]}px`,
				},
				children,
				...props,
			}}
		/>
	);
});
