import { theme } from '../../theme/theme';
import { BrowserSupportStatus, type IBrowserSupportModel } from './BrowserSupportModel';
import styled from '@emotion/styled/macro';
import { Alert, type AlertProps } from 'antd';
import { observer } from 'mobx-react-lite';

interface BrowserSupportProps {
	readonly browserSupportModel: IBrowserSupportModel;
}

const ALERT_TYPE_MAP = {
	[BrowserSupportStatus.Supported]: 'success',
	[BrowserSupportStatus.Outdated]: 'info',
	[BrowserSupportStatus.Unsupported]: 'warning',
} as const satisfies Record<BrowserSupportStatus, NonNullable<AlertProps['type']>>;

const AlignedAlert = styled(Alert)`
	text-align: start;
`;

const AlertTitle = styled.div`
	font-weight: ${theme.font.weights[500]};
`;

export const BrowserSupport = observer(function BrowserSupport({ browserSupportModel }: BrowserSupportProps) {
	const {
		browserStatusDescription: userBrowserStatusDescription,
		browserStatus: userBrowserStatus,
		browserStatusTitle: userBrowserStatusTitle,
	} = browserSupportModel;

	return (
		<AlignedAlert
			{...{
				message: <AlertTitle>{userBrowserStatusTitle}</AlertTitle>,
				description: userBrowserStatusDescription,
				type: ALERT_TYPE_MAP[userBrowserStatus],
				showIcon: true,
			}}
		/>
	);
});
