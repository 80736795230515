import type { DOMAttributes, MouseEvent } from 'react';

const EVENT_DELEGATION_ATTRIBUTE = 'data-analytics-tag';
const ANALYTICS_ATTRIBUTE_PREFIX = 'data-analytics-prop-';
const ANALYTICS_LIST_PROPS_SEPARATOR = ',';
const ANALYTICS_LIST_PROPS_NAMESPACE_SEPARATOR = '_';

type SendClickAnalytic = (analytic: Record<string, string>) => void;
interface SendClickAnalyticByDataAnalyticIdAttributeProps {
	readonly clickEvent: MouseEvent<HTMLElement>;
	readonly sendClickAnalyticCallback: SendClickAnalytic;
}

function sendClickAnalyticByDataAnalyticIdAttribute({
	clickEvent,
	sendClickAnalyticCallback,
}: SendClickAnalyticByDataAnalyticIdAttributeProps): void {
	const clickedElement =
		'target' in clickEvent && clickEvent.target instanceof window.Element ? clickEvent.target : null;
	if (!clickedElement) {
		return;
	}

	const analyticElement = clickedElement.closest(`[${EVENT_DELEGATION_ATTRIBUTE}]`);
	if (!analyticElement) {
		return;
	}

	const elementDataAnalyticId = analyticElement.getAttribute(EVENT_DELEGATION_ATTRIBUTE);
	if (!elementDataAnalyticId) {
		throw Error(`Element with ${EVENT_DELEGATION_ATTRIBUTE} attribute does not have a value`);
	}

	const analyticProps = analyticElement.getAttributeNames().reduce(function getAnalyticsPropFromAttributeName(
		accumulator,
		attributeName,
	): Record<string, string> {
		if (attributeName.startsWith(ANALYTICS_ATTRIBUTE_PREFIX)) {
			const analyticPropsName = attributeName.replace(ANALYTICS_ATTRIBUTE_PREFIX, '');
			const analyticPropsNameWithOriginalSpaces = analyticPropsName.replaceAll(
				ANALYTICS_LIST_PROPS_NAMESPACE_SEPARATOR,
				' ',
			);

			const firstLetterOfEachWordRegex = /(^\w{1})|(\s+\w{1})/g;
			const analyticPropsNameWithOriginalSpacesAndCamelCase = analyticPropsNameWithOriginalSpaces.replace(
				firstLetterOfEachWordRegex,
				function getUpperCaseLetter(match): string {
					return match.toUpperCase();
				},
			);

			// TODO - support numbers and booleans
			const analyticPropsValue = analyticElement.getAttribute(attributeName);

			if (analyticPropsValue !== null) {
				return Object.assign(accumulator, { [analyticPropsNameWithOriginalSpacesAndCamelCase]: analyticPropsValue });
			}
			return accumulator;
		}
		return accumulator;
	}, {});

	const analytic = {
		ActionID: elementDataAnalyticId,
		...analyticProps,
	} as const satisfies Record<string, string>;

	sendClickAnalyticCallback(analytic);
}

interface WithOnClickEventDelegationReturn extends Pick<DOMAttributes<HTMLElement>, 'onClickCapture'> {}

export function withOnClickEventDelegation(
	sendClickAnalyticCallback: SendClickAnalytic,
): WithOnClickEventDelegationReturn {
	return {
		onClickCapture: function sendClickAnalytic(event: MouseEvent<HTMLElement>): void {
			sendClickAnalyticByDataAnalyticIdAttribute({
				clickEvent: event,
				sendClickAnalyticCallback,
			} as const);
		},
	};
}

interface WithAnalyticReturn {
	readonly [EVENT_DELEGATION_ATTRIBUTE]: string;
}
export function withAnalyticId(analyticId: string): WithAnalyticReturn {
	return {
		[EVENT_DELEGATION_ATTRIBUTE]: analyticId,
	};
}

type AnalyticsValue = string | number | boolean | undefined | null;
type AnalyticsProps = AnalyticsValue | ReadonlyArray<AnalyticsValue>;

function getPropsValueAsString(value: AnalyticsProps): string {
	if (Array.isArray(value)) {
		return value.map(getPropsValueAsString).join(ANALYTICS_LIST_PROPS_SEPARATOR);
	}
	if (value === undefined || value === null) {
		return '';
	}
	return String(value);
}
function getPropsNameWithPrefix(analyticsPropsName: string): `${typeof ANALYTICS_ATTRIBUTE_PREFIX}${string}` {
	const spaceRegex = /\s/g;
	const transformedPropsName = analyticsPropsName.replace(spaceRegex, ANALYTICS_LIST_PROPS_NAMESPACE_SEPARATOR);

	return `${ANALYTICS_ATTRIBUTE_PREFIX}${transformedPropsName}`;
}
interface WithAnalyticPropsReturn {
	readonly [key: `data-analytics-prop-${string}`]: string;
}
export function withAnalyticProps(analyticPropsWithValues: Record<string, AnalyticsProps>): WithAnalyticPropsReturn {
	return Object.entries(analyticPropsWithValues).reduce(function transformAnalyticProps(
		accumulator,
		[analyticPropsName, analyticValue],
	): WithAnalyticPropsReturn {
		Object.assign(accumulator, { [getPropsNameWithPrefix(analyticPropsName)]: getPropsValueAsString(analyticValue) });
		return accumulator;
	}, {});
}
