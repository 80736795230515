import { getOrCreateAnalytics } from '../../utils/analytics';
import type { SchedulePageSearchRequestPayload, SchedulePageSearchResponsePayload } from '@navina/api-types';
import type { AxiosResponse } from 'axios';

export function sendSchedulePageClickAnalytic(props: Record<string, string>): void {
	const analytics = getOrCreateAnalytics();

	analytics.track(analytics.idsNames.SchedulePageClick, props);
}

export function sendSchedulePageLoadAnalytics(): void {
	const analytics = getOrCreateAnalytics();

	analytics.track(analytics.idsNames.SchedulePageLoad);
}

export function sendSchedulePageAppointmentsLoadAnalytics(
	response: AxiosResponse<SchedulePageSearchResponsePayload>,
	request: SchedulePageSearchRequestPayload,
): void {
	const analytics = getOrCreateAnalytics();

	const { data: responseData } = response;

	const { totalEntities, entities } = responseData;

	const { iso8601DateWithoutTime, groupPracticeId, filters } = request;

	analytics.track(analytics.idsNames.SchedulePageAppointmentsLoad, {
		'Number Of Total Appointments': totalEntities,
		'Number Of Current Page Appointments': entities.length,
		'Selected Date': iso8601DateWithoutTime,
		'Selected Group Practice Id': groupPracticeId,
		'Selected Department Ids': filters.selectedDepartmentIds,
		'Selected Provider Ids': filters.selectedProviderIds,
		'Selected Insurance Filter Groups Ids': filters.selectedInsuranceFilterGroups,
		'Selected Nids': filters.selectedNids,
	});
}
