import { MAX_WIDTH_LOWER_WIDTH } from '../../base-ui/Resolutions';
import { baseTextStyle, theme } from '../../theme/theme';
import styled from '@emotion/styled/macro';

const CenteredFlex = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;
`;

export const ErrorContainer = styled(CenteredFlex)`
	${baseTextStyle}
	block-size: 100%;
	padding-inline: ${theme.paddings.xxl};
	inline-size: 60%;
	margin-inline: auto;
	row-gap: ${theme.paddings.lg};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		inline-size: auto;
		padding-inline: ${theme.paddings.xxs};
	}
`;

export const ErrorMessageContainer = styled(CenteredFlex)`
	${baseTextStyle}
	row-gap: ${theme.paddings.lg};
`;

export const ErrorMessage = styled.div`
	${baseTextStyle}
`;

export const ErrorTitle = styled(ErrorMessage)`
	font-size: ${theme.font.sizes.xl};
	font-weight: bold;
`;
