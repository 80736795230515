// TODO - move to shared types
export enum EntitiesFetchingStatus {
	Idle = 'Idle',
	Pending = 'Pending',
	Success = 'Success',
	Error = 'Error',
}

// TODO - move to shared types
interface InsuranceGroup {
	readonly id: number;
	readonly dataSourceId: number;
	readonly name: string;
	readonly filterGroups: string;
	readonly aggregate: boolean;
}
interface Provider {
	readonly id: number;
	readonly username: string | null;
	readonly clinicId: number;
	readonly firstName: string | null;
	readonly lastName: string | null;
	readonly departmentIds: ReadonlyArray<string>;
	readonly analyticsMetadataDepartmentName: string | null;
}
interface Department {
	readonly id: number;
	readonly name: string;
	readonly clinicId: number;
}
interface Clinic {
	readonly id: number;
	readonly name: string;
}

export interface GetClinicsDepartmentsProvidersResponsePayload {
	readonly clinics: ReadonlyArray<Clinic>;
	readonly departments: ReadonlyArray<Department>;
	readonly providers: ReadonlyArray<Provider>;
	readonly insuranceGroups: ReadonlyArray<InsuranceGroup>;
}
