import { colors } from '../base-ui/Colors';
import {
	MAX_WIDTH_LOWER_WIDTH,
	MAX_WIDTH_MEDIUM_WIDTH,
	MIN_WIDTH_LOWER_WIDTH,
	MIN_WIDTH_MEDIUM_WIDTH,
} from '../base-ui/Resolutions';
import { getThemeValueByName } from '../utilities/styling/UseCssVarByName';
import antdTheme from './antd-tokens/light.json';
import { css } from '@emotion/react/macro';

const SCROLLBAR_WIDTH = 13;

const globalStyle = css`
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	[class][hidden] {
		display: none;
	}

	.navina-hidden {
		display: none !important;
	}

	body {
		margin: 0;
		font-family: Inter, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		block-size: 100%;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		text-align: left;
	}

	html {
		block-size: 100%;
		font-family: sans-serif;
		line-height: 1.15;
	}

	a {
		text-decoration: none;
		background-color: transparent;
	}

	button:focus {
		outline: none;
	}

	::selection {
		background: ${colors.accentLightGreen};
	}

	.rpv-search__highlight {
		background: ${colors.accentOrangeLight};
	}

	.rpv-search__highlight--current {
		background: ${colors.accentYellowLight};
	}

	[hidden] {
		display: none;
	}

	@supports selector(::-webkit-scrollbar) {
		::-webkit-scrollbar-track {
			background: none;
		}

		::-webkit-scrollbar {
			inline-size: ${SCROLLBAR_WIDTH}px;
			block-size: 10px;
		}

		::-webkit-scrollbar-thumb {
			background: #ccc;
			border-radius: 8px;
		}
	}

	.ph-item {
		background-color: initial;
		border: initial;
		padding: 0%;
		margin-block-end: initial;
	}

	.ph-picture {
		border-radius: 0.25rem;
		block-size: 66px;
	}

	.pdfobject-container {
		block-size: 100%;
	}

	.__react_component_tooltip {
		box-shadow: 2px 2px 4px 0 #00000040;
	}

	.hide-on-small-screen {
		@media ${MAX_WIDTH_LOWER_WIDTH} {
			display: none;
		}
	}

	.hide-on-small-and-medium-screen {
		@media ${MAX_WIDTH_MEDIUM_WIDTH} {
			display: none;
		}
	}

	.hide-on-large-screen {
		@media ${MIN_WIDTH_MEDIUM_WIDTH} {
			display: none;
		}
	}

	.hide-on-large-and-medium-screen {
		@media ${MIN_WIDTH_LOWER_WIDTH} {
			display: none;
		}
	}

	.Toastify__toast-container {
		inline-size: fit-content;
		inset-block-end: 45px;
	}

	.Toastify__toast--error {
		border: 2px solid #f24e1e;
		background: #fcdcd2;
		border-radius: 15px;
		padding: 4px;
	}

	.Toastify__toast--success {
		border: 2px solid #9bdea9;
		background: #d9f9eb;
		border-radius: 15px;
		padding: 4px;
		display: flex;
		align-items: center;
	}

	.Toastify__toast-body {
		text-overflow: ellipsis;
		overflow: hidden;
		inline-size: 100%;
		padding: 0;
	}

	.Toastify__toast-body > div {
		overflow: hidden;
	}

	.Toastify__close-button {
		align-self: center;
		margin: 0;
		color: white;
		opacity: 1;
		font-size: 12px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		cursor: pointer !important;
		outline: none !important;
	}

	.ant-select-dropdown {
		.ant-select-item-option-selected {
			background-color: ${colors.authInputBackground} !important;
		}

		.ant-select-item-option-active:not(.ant-select-item-option-selected) {
			background-color: ${colors.cartStroke} !important;
		}

		.ant-select-item-option-state {
			color: ${colors.secondary} !important;
		}
	}

	.ant-notification {
		.ant-notification-notice-content {
			.ant-notification-notice-with-icon {
				display: flex;
				align-items: center;
			}

			.ant-notification-notice-with-icon .ant-notification-notice-icon-info,
			.ant-notification-notice-with-icon .ant-notification-notice-icon-success {
				position: unset;
				inline-size: 1.3rem;
			}

			.ant-notification-notice-with-icon .ant-notification-notice-message {
				margin-block-end: unset;
				margin-inline-start: 1rem;
				font-size: 0.95rem;
			}

			.ant-notification-notice-with-icon .ant-notification-notice-description {
				display: none;
			}
		}

		.ant-notification-notice-wrapper .ant-notification-notice-close {
			position: unset;
		}
	}

	.anticon.anticon-info-circle.ant-alert-icon {
		color: ${colors.antInfoIconColor};
	}

	.ant-notification-notice-icon-success {
		color: ${antdTheme.colorSuccess} !important;
	}

	.ant-select-item-option-selected {
		font-weight: unset !important;
	}

	.ant-table-row-expand-icon {
		color: ${antdTheme.colorPrimary} !important;
		border-radius: 2px !important;
	}

	.ant-descriptions-item-content {
		background-color: ${antdTheme.colorBgContainer} !important;
	}

	.ant-popover-title {
		margin-block-end: 12px !important;
	}
	.no-padding-popup-items-for-select-drop-down .ant-select-item {
		padding: 0;
	}
`;

/* eslint-disable no-restricted-syntax -- we have a gradual plan to remove the usage of css prop */

export const globalStyleWithCssVar = css`
	${globalStyle}

	:root {
		--antd-borderRadius: ${getThemeValueByName('borderRadius')}px;
		--antd-borderRadiusLG: ${getThemeValueByName('borderRadiusLG')}px;
		--antd-borderRadiusSM: ${getThemeValueByName('borderRadiusSM')}px;
		--antd-boxShadow: ${getThemeValueByName('boxShadow')};
		--antd-boxShadowSecondary: ${getThemeValueByName('boxShadowSecondary')};
		--antd-colorBgBase: ${getThemeValueByName('colorBg')};
		--antd-colorBorder: ${getThemeValueByName('colorBorder')};
		--antd-colorBorderSecondary: ${getThemeValueByName('colorBorderSecondary')};
		--antd-colorFillQuaternary: ${getThemeValueByName('colorFillQuaternary')};
		--antd-colorFillSecondary: ${getThemeValueByName('colorFillSecondary')};
		--antd-colorFillTertiary: ${getThemeValueByName('colorFillTertiary')};
		--antd-colorPrimary: ${getThemeValueByName('colorPrimary')};
		--antd-colorPrimaryHover: ${getThemeValueByName('colorPrimaryHover')};
		--antd-colorText: ${getThemeValueByName('colorText')};
		--antd-colorTextQuaternary: ${getThemeValueByName('colorTextQuaternary')};
		--antd-colorTextSecondary: ${getThemeValueByName('colorTextSecondary')};
		--antd-colorTextTertiary: ${getThemeValueByName('colorTextTertiary')};
		--antd-colorWarningBg: ${getThemeValueByName('colorWarningBg')};
		--antd-fontSize: ${getThemeValueByName('fontSize')}px;
		--antd-fontSizeLG: ${getThemeValueByName('fontSizeLG')}px;
		--antd-fontSizeSM: ${getThemeValueByName('fontSizeSM')}px;
		--antd-geekblue-6: ${getThemeValueByName('geekblue.6')};
		--antd-gold-1: ${getThemeValueByName('gold.1')};
		--antd-gold-3: ${getThemeValueByName('gold.3')};
		--antd-gold-7: ${getThemeValueByName('gold.7')};
		--antd-marginLG: ${getThemeValueByName('marginLG')}px;
		--antd-marginSM: ${getThemeValueByName('marginSM')}px;
		--antd-padding: ${getThemeValueByName('padding')}px;
		--antd-paddingLG: ${getThemeValueByName('paddingLG')}px;
		--antd-paddingMD: ${getThemeValueByName('paddingMD')}px;
		--antd-paddingSM: ${getThemeValueByName('paddingSM')}px;
		--antd-paddingXS: ${getThemeValueByName('paddingXS')}px;
		--antd-paddingXXS: ${getThemeValueByName('paddingXXS')}px;
		--antd-purple-1: ${getThemeValueByName('purple.1')};
		--antd-purple-2: ${getThemeValueByName('purple.2')};
		--antd-purple-5: ${getThemeValueByName('purple.5')};
		--antd-purple-6: ${getThemeValueByName('purple.6')};
		--antd-purple-7: ${getThemeValueByName('purple.7')};
		--antd-purple-9: ${getThemeValueByName('purple.9')};
		--antd-white: ${getThemeValueByName('white')};
		--antd-yellow-2: ${getThemeValueByName('yellow.2')};
	}
`;

/* eslint-enable no-restricted-syntax */
