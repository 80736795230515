import classNames from 'classnames';
import { cloneElement, type ReactElement } from 'react';

const MEDIUM_WIDTH_NUM = 1460;
export const LOWEST_WIDTH_NUM = 1200;
export const LOWEST_HEIGHT_NUM = 700;

// TODO: convert to modern notation
export const MAX_WIDTH_LOWER_WIDTH = `(max-width: ${`${LOWEST_WIDTH_NUM}px`})` as const;
export const MAX_WIDTH_MEDIUM_WIDTH = `(max-width: ${`${MEDIUM_WIDTH_NUM}px`})` as const;
export const MIN_WIDTH_LOWER_WIDTH = `(min-width: ${`${LOWEST_WIDTH_NUM + 1}px`})` as const;
export const MIN_WIDTH_MEDIUM_WIDTH = `(min-width: ${`${MEDIUM_WIDTH_NUM + 1}px`})` as const;

type Props = {
	children: ReactElement;
	hideOnMedium?: boolean;
};

const AddClassName = ({ children, className }: { readonly children: ReactElement; readonly className: string }) => {
	const newClassNames = [children.props.className, className] as const;

	const props = {
		className: newClassNames.join(' '),
	} as const;

	const newChild = cloneElement(children, props);
	return newChild;
};

/******************************************************
Classname code is available at src/theme/globalStyle.ts
******************************************************/

export const HideOnSmallScreen = ({ children, hideOnMedium = false }: Props) => {
	return (
		<AddClassName
			className={classNames({ 'hide-on-small-and-medium-screen': hideOnMedium, 'hide-on-small-screen': !hideOnMedium })}
		>
			{children}
		</AddClassName>
	);
};

export const HideOnLargeScreen = ({ children, hideOnMedium = true }: Props) => {
	return (
		<AddClassName
			className={classNames({ 'hide-on-large-and-medium-screen': hideOnMedium, 'hide-on-large-screen': !hideOnMedium })}
		>
			{children}
		</AddClassName>
	);
};
