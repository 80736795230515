import { theme } from '../../../theme/theme';
import { FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Spin } from 'antd';
import { type ComponentProps } from 'react';

const IconOverlayContainer = styled.div`
	position: relative;
	display: flex;
	inline-size: 100%;
	block-size: 100%;
	justify-content: center;
	align-items: center;
`;

const BaseIcon = styled(FilterOutlined)`
	position: absolute;
	inset-block: 0;
	inset-inline: 0;
	font-size: 30px;
	z-index: 1;
	color: ${theme.colors.purple6};
`;

const OverlayIcon = styled(LoadingOutlined)`
	position: absolute;
	inset-block: 0;
	inset-inline: 0;
	font-size: 64px;
	z-index: 2;
	color: ${theme.colors.purple2};
`;

interface LoadingFilterIconProps extends Omit<ComponentProps<typeof IconOverlayContainer>, 'children'> {}

export const LoadingFilterIcon = (props: LoadingFilterIconProps) => {
	return (
		<IconOverlayContainer {...props}>
			<BaseIcon />
			<OverlayIcon {...{ spin: true }} />
		</IconOverlayContainer>
	);
};

export const LoadingIcon = () => (
	<Spin
		{...{
			indicator: (
				<LoadingOutlined
					{...{
						style: { fontSize: 48 },
						spin: true,
					}}
				/>
			),
		}}
	/>
);
