import { theme } from '../../theme/theme';
import { CardContainer } from './assets/CardContainer';
import styled from '@emotion/styled/macro';
import { Badge } from 'antd';

export const AppointmentsScheduleContainer = styled.div`
	display: flex;
	flex-direction: column;
	block-size: 100%;
	row-gap: ${theme.paddings.sm};
`;

export const TotalResultsContainer = styled.div`
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: auto;
`;

export const HeaderContainer = styled.div`
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: auto;
	padding-block: 0 1px;
`;

export const PaginationContainer = styled.div`
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: auto;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledBadge = styled(Badge)`
	cursor: pointer;
`;

export const AppointmentsTableOverlay = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${theme.colors.whiteTransparent};
	z-index: 100;
`;

export const AppointmentsTableContainer = styled(CardContainer)`
	position: relative;
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0%;
`;
