import { getOrCreateAnalytics } from '../../utils/analytics';
import { type PatientLookupResponsePayload } from '@navina/api-types';
import { type AxiosResponse } from 'axios';

export function sendPatientLookupAppointmentsLoadAnalytic(response: AxiosResponse<PatientLookupResponsePayload>): void {
	const analytics = getOrCreateAnalytics();

	const { data: responseData } = response;

	const { totalEntities, entities } = responseData;

	analytics.track(analytics.idsNames.PatientLookupPageAppointmentsLoad, {
		'Number Of Total Appointments': totalEntities,
		'Number Of Current Page Appointments': entities.length,
	});
}

export function sendPatientLookupPageLoadAnalytic(): void {
	const analytics = getOrCreateAnalytics();

	analytics.track(analytics.idsNames.PatientLookupPageLoad);
}

export function sendPatientLookupClickAnalytic(props: Record<string, string>): void {
	const analytics = getOrCreateAnalytics();

	analytics.track(analytics.idsNames.PatientLookupPageClick, props);
}
