import { LogoDestination } from '../../assets/Logo/Logo';
import type { ProtectedRouteProps } from '../auth/ProtectedRoute';
import { EXACT_BASE_LAYOUT_ROUTE_PROPS } from './CommonRouteProps';
import { lazy } from 'react';

const Quality = lazy(() =>
	import(/* webpackChunkName: "Quality" */ '../../pages/quality/care-team/PageContainer').then((module) => ({
		default: module.PageContainer,
	})),
);
const FilesManager = lazy(() =>
	import(/* webpackChunkName: "FileManagerPageContainer" */ '../../pages/file-manager/PageContainer').then(
		(module) => ({
			default: module.PageContainer,
		}),
	),
);

const CareTeamHeader = lazy(() =>
	import(/* webpackChunkName: "CareTeamHeader" */ '../../pages/quality/care-team/CareTeamHeader').then((module) => ({
		default: module.CareTeamHeader,
	})),
);

export const EXACT_FILES_ROUTE_PROPS = {
	...EXACT_BASE_LAYOUT_ROUTE_PROPS,
	headerAppMenuSelectedPage: 'files',
	headerTitle: 'File manager',
	component: FilesManager,
	ContactUsEnabled: true,
	headerLogoLink: LogoDestination.Files,
} as const satisfies Omit<ProtectedRouteProps, 'path'>;

export const EXACT_QUALITY_WORKSPACE_ROUTE_PROPS = {
	...EXACT_BASE_LAYOUT_ROUTE_PROPS,
	headerAppMenuSelectedPage: 'quality',
	component: Quality,
	ContactUsEnabled: true,
	headerComponent: CareTeamHeader,
	headerTitle: 'Quality Workspace',
	headerLogoLink: LogoDestination.Nowhere,
} as const satisfies Omit<ProtectedRouteProps, 'path'>;

export const EXACT_QUALITY_FIT_ROUTE_PROPS = {
	...EXACT_BASE_LAYOUT_ROUTE_PROPS,
	headerLogoLink: LogoDestination.Nowhere,
} as const satisfies typeof EXACT_BASE_LAYOUT_ROUTE_PROPS & Pick<ProtectedRouteProps, 'headerLogoLink'>;
