import { EMPTY_ICON_SETTINGS } from '../../theme/theme';
import { ErrorContainer } from './ErrorPage.styled';
import { Button, Empty } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps, ReactNode } from 'react';

interface ErrorPageAction {
	readonly name: string;
	readonly onAction: VoidFunction;
}

interface ErrorPageProps extends ComponentProps<typeof ErrorContainer> {
	readonly icon?: ReactNode;
	readonly children: ReactNode;
	readonly action?: ErrorPageAction;
}

export const ErrorPage = observer(function ErrorPage({ icon, children, action, ...props }: ErrorPageProps) {
	return (
		<ErrorContainer {...props}>
			<Empty
				{...{
					...EMPTY_ICON_SETTINGS,
					image: icon,
					description: children,
				}}
			/>
			{action && (
				<Button
					{...{
						type: 'primary',
						onClick: action.onAction,
					}}
				>
					{action.name}
				</Button>
			)}
		</ErrorContainer>
	);
});
