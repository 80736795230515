import { SomethingWentWrong } from './components/ErrorPage/SomethingWentWrong/SomethingWentWrong';
import { IS_PRODUCTION } from './config/Environment';
import { getOrCreateApiTraceServer } from './server/apiTrace';
import { theme } from './theme/theme';
import { getOrCreateAnalytics } from './utils/analytics';
import { getOrCreateLogger } from './utils/logger';
import styled from '@emotion/styled/macro';
import LogRocket from 'logrocket';
import { Component, type ReactNode } from 'react';

interface ErrorState {
	readonly hasError: boolean;
	readonly error?: Error;
	readonly errorInfo?: unknown;
	readonly shouldHideError: boolean;
}

interface ErrorBoundaryProps {
	readonly children: ReactNode;
}

const ErrorDetailsContainer = styled.code`
	display: block;
	block-size: 300px;
	overflow-y: auto;
	background-color: whitesmoke;
	outline: 1px solid black;
	text-align: start;
`;

const ErrorUserAgent = styled.code`
	font-size: ${theme.font.sizes.xs};
	color: ${theme.font.colors.secondary};
`;

// eslint-disable-next-line no-restricted-syntax -- Error boundaries only work with classes.
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorState> {
	public state: ErrorState = {
		hasError: false,
		error: undefined,
		errorInfo: undefined,
		shouldHideError: true,
	};

	readonly logErrorToMyService = (error: Error, info: unknown): void => {
		if (IS_PRODUCTION) {
			// TODO - save those logs elsewhere
			getOrCreateApiTraceServer()
				.logError('error_in_frontend', error, info)
				.catch(function logToConsole(loggingError): void {
					console.log('error logging the event', loggingError);
				});

			getOrCreateLogger().error('Error boundary', { type: 'error_in_frontend', error, info });
			console.log('Error boundary', { type: 'error_in_frontend', error, info });

			getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.Error, { error, info });
		}
	};

	readonly componentDidCatch = (error: Error, info: unknown): void => {
		LogRocket.captureException(error);
		// Display fallback UI
		this.setState({
			hasError: true,
			error,
			errorInfo: info,
		}); // You can also log the error to an error reporting service
		console.log(error, info);
		this.logErrorToMyService(error, info);
	};

	readonly render = () => {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			window.localStorage.setItem('hadError', 'true');

			const revealErrorMessage = (): void => {
				this.setState(() => ({ shouldHideError: false }));
			};

			return (
				<SomethingWentWrong
					{...{
						onDoubleClick: revealErrorMessage,
						action: {
							name: 'Reload page',
							onAction: function reloadPage(): void {
								window.location.reload();
							},
						},
					}}
				>
					{!this.state.shouldHideError && (
						<details>
							<summary>{this.state.error ? <code>{this.state.error.message}</code> : 'Additional details'}</summary>
							{this.state.errorInfo && (
								<ErrorDetailsContainer>{JSON.stringify(this.state.errorInfo)}</ErrorDetailsContainer>
							)}
							<ErrorUserAgent>{navigator.userAgent}</ErrorUserAgent>
						</details>
					)}
				</SomethingWentWrong>
			);
		}
		return this.props.children;
	};
}
