import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import { Descriptions, Skeleton, type DescriptionsProps } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ReactNode, ComponentProps } from 'react';

interface PatientLookupDemographicsProps extends Omit<ComponentProps<typeof Descriptions>, 'children'> {
	readonly fetchingStatus: EntitiesFetchingStatus;
	readonly genderText: ReactNode;
	readonly dateOfBirthText: ReactNode;
	readonly patientEmrIdText: ReactNode;
}

export const PatientLookupDemographics = observer(function PatientLookupDemographics({
	genderText,
	dateOfBirthText,
	patientEmrIdText,
	fetchingStatus,
	...props
}: PatientLookupDemographicsProps) {
	const items = [
		{
			key: '1',
			label: 'Patient ID',
			children: <code>{patientEmrIdText}</code>,
		},
		{
			key: '2',
			label: 'DOB',
			children: dateOfBirthText,
		},
		{
			key: '3',
			label: 'Gender',
			children: genderText,
		},
	] as const satisfies DescriptionsProps['items'];

	const loadingItems = items.map((item) => ({
		...item,
		children: <Skeleton.Input {...{ active: true, size: 'small' }} />,
	}));

	return (
		<Descriptions
			{...{
				items: fetchingStatus === EntitiesFetchingStatus.Pending ? loadingItems : items,
				column: 5,
				...props,
			}}
		/>
	);
});
