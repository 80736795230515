import { theme } from '../../../theme/theme';
import { EntitiesFetchingStatus } from '../models/types';
import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps } from 'react';

const TotalResultsContainer = styled.div``;

interface PaginationSummaryProps {
	readonly pageNumber: number;
	readonly rowsPerPage: number;
	readonly totalRows: number;
	readonly fetchingStatus: EntitiesFetchingStatus;
	readonly entityName?: string;
}

const Loader = (props: ComponentProps<typeof Spin>) => (
	<Spin
		{...{
			style: { paddingInline: theme.paddings.xs },
			indicator: <LoadingOutlined style={{ fontSize: theme.font.sizes.sm }} spin />,
			...props,
		}}
	/>
);

const PaginationSummary = observer(function PaginationSummary({
	pageNumber,
	rowsPerPage,
	totalRows,
	fetchingStatus,
	entityName = 'appointments',
}: PaginationSummaryProps) {
	const fromResult = (pageNumber - 1) * rowsPerPage + 1;
	const toResult = pageNumber * rowsPerPage;
	const toResultOrTotalRows = Math.min(toResult, totalRows);

	const formattedTotalRows = totalRows.toLocaleString();
	const formattedFromResult = fromResult.toLocaleString();
	const formattedToResult = toResult.toLocaleString();
	const formattedToResultOrTotalRows = toResultOrTotalRows.toLocaleString();

	if (fetchingStatus === EntitiesFetchingStatus.Idle) {
		return <>0-0 of - {entityName}</>;
	}

	if (fetchingStatus === EntitiesFetchingStatus.Pending) {
		return (
			<>
				{formattedFromResult}-{formattedToResult} of <Loader /> {entityName}
			</>
		);
	}

	const noResultsFromApi = totalRows === 0 && fetchingStatus === EntitiesFetchingStatus.Success;
	if (noResultsFromApi || fetchingStatus === EntitiesFetchingStatus.Error) {
		return <>No {entityName} results</>;
	}

	return (
		<>
			{formattedFromResult}-{formattedToResultOrTotalRows} of {formattedTotalRows} {entityName}
		</>
	);
});

interface TotalResultsType extends ComponentProps<typeof TotalResultsContainer>, PaginationSummaryProps {}

export const TotalResults = observer(function TotalResults({
	pageNumber,
	rowsPerPage,
	totalRows,
	fetchingStatus,
	entityName = 'appointments',
	children = <PaginationSummary {...{ pageNumber, rowsPerPage, totalRows, fetchingStatus, entityName }} />,
}: TotalResultsType) {
	return <TotalResultsContainer {...{ children }} />;
});
