import { theme } from '../../theme/theme';
import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import styled from '@emotion/styled/macro';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps } from 'react';

const TitleSkeleton = styled(Skeleton.Input)`
	inline-size: 400px;
	margin-block: 0;
	padding-block: 0;
`;

const Title = styled.div`
	text-transform: capitalize;
	display: flex;
	font-size: ${theme.font.sizes.title2};
	font-weight: ${theme.font.weights.bold};
`;

interface PatientLookupTitleProps extends ComponentProps<typeof Title> {
	readonly fetchingStatus: EntitiesFetchingStatus;
}

export const PatientLookupTitle = observer(function PatientLookupTitle({
	fetchingStatus,
	children,
	...props
}: PatientLookupTitleProps) {
	if (fetchingStatus === EntitiesFetchingStatus.Pending) {
		return (
			<Title {...props}>
				<TitleSkeleton {...{ active: true, size: 'large' }} />
			</Title>
		);
	}

	return <Title {...props}>{children}</Title>;
});
