import { LogoDestination } from '../../assets/Logo/Logo';
import { PatientLookupHeader } from '../../pages/PatientLookupPage/PatientLookupHeader';
import type { ProtectedRouteProps } from '../auth/ProtectedRoute';
import { EXACT_BASE_LAYOUT_ROUTE_PROPS } from './CommonRouteProps';
import { lazy } from 'react';

const SchedulePage = lazy(() =>
	import(/* webpackChunkName: "SchedulePage" */ '../../pages/SchedulePage/SchedulePage').then((module) => ({
		default: module.SchedulePage,
	})),
);

export const EXACT_SCHEDULE_ROUTE_PROPS = {
	...EXACT_BASE_LAYOUT_ROUTE_PROPS,
	headerAppMenuSelectedPage: 'patient-portrait',
	headerComponent: PatientLookupHeader,
	headerTitle: 'Scheduling Overview',
	component: SchedulePage,
	headerLogoLink: LogoDestination.Schedule,
} as const satisfies Omit<ProtectedRouteProps, 'path'>;
