import { Logo, LogoDestination, LogoSizes } from '../../assets/Logo/Logo';
import { AppsMenu } from '../../components/apps-menu/Menu';
import { UserMenuPopup } from '../../pages/common/UserMenu/UserMenuPopup';
import { FeedbackButton } from '../../pages/summary/FeedbackButton';
import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { theme } from '../../theme/theme';
import type { WithLayoutHeader, NavinaLayout } from '../auth/RouteType';
import styled from '@emotion/styled/macro';
import { ConfigProvider, Layout, theme as antdTheme } from 'antd';
import { observer } from 'mobx-react-lite';
import { Suspense, type ComponentProps } from 'react';

const StyledLayout = styled(Layout)`
	block-size: 100%;
`;

const StyledHeader = styled.div`
	position: relative;
	background-color: ${theme.colors.primary};
	padding-block: ${theme.paddings.base};
	padding-inline: ${theme.paddings.lg};

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const HeaderButtonsContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	column-gap: ${theme.paddings.xs};
`;

const PageTitle = styled.span`
	font-weight: ${theme.font.weights.bold};
	font-size: ${theme.font.sizes.title4};
	color: ${theme.colors.white};
`;

const HeaderLogoAndTitleContainer = styled(HeaderButtonsContainer)`
	gap: ${theme.paddings.base};
`;
interface BaseLayoutProps extends NavinaLayout, WithLayoutHeader {
	readonly headerAppMenuSelectedPage: ComponentProps<typeof AppsMenu>['selectedPage'];
}

export const HeaderConfigProvider = function (props: ComponentProps<typeof ConfigProvider>) {
	return (
		<ConfigProvider
			{...{
				theme: {
					algorithm: antdTheme.darkAlgorithm,
					// TODO - ask @shiran about the color of the button
					components: {
						Button: {
							primaryShadow: '0',
						},
						Form: {
							colorTextHeading: theme.colors.white,
						},
						Typography: {
							titleMarginBottom: '0',
							titleMarginTop: '0',
							colorTextHeading: theme.colors.white,
						},
					},
				},
				...props,
			}}
		/>
	);
};

const EmptyDiv = styled.div``;

export const BaseLayout = observer(function BaseLayout({
	component: Component,
	headerLogoLink = LogoDestination.Schedule,
	headerAppMenuSelectedPage,
	headerComponent: HeaderComponent = EmptyDiv,
	headerTitle,
	...props
}: BaseLayoutProps) {
	const showFeedbackButton = useFeatureFlag('ShowFeedbackButton', false);

	return (
		<StyledLayout>
			<StyledHeader>
				<HeaderConfigProvider>
					<HeaderLogoAndTitleContainer>
						<Logo {...{ size: LogoSizes.Base, destination: headerLogoLink }} />
						<PageTitle>{headerTitle}</PageTitle>
					</HeaderLogoAndTitleContainer>
					<HeaderButtonsContainer>
						<Suspense fallback={<EmptyDiv />}>
							<HeaderComponent {...props} />
						</Suspense>
						<UserMenuPopup />
						<AppsMenu
							{...{
								selectedPage: headerAppMenuSelectedPage,
							}}
						/>
					</HeaderButtonsContainer>
				</HeaderConfigProvider>
			</StyledHeader>
			<Layout.Content>
				<Suspense fallback={<EmptyDiv />}>
					<Component {...props} />
				</Suspense>
			</Layout.Content>
			{showFeedbackButton && <FeedbackButton />}
		</StyledLayout>
	);
});
