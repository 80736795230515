import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { useAuthStore } from '../../stores/authStore';
import { HasPermission } from '../user-permissions/HasPermission';
import { PopoverItem } from './PopoverItem';
import { PopoverItemSmall } from './PopoverItemSmall';
import { ReactComponent as AdminToolIcon } from './icons/AdminToolIcon.svg';
import { ReactComponent as FileManagerIcon } from './icons/FileManagerIcon.svg';
import { ReactComponent as OrgDashboardIcon } from './icons/OrgDashboardIcon.svg';
import { ReactComponent as PatientPortraitIcon } from './icons/PatientPortraitIcon.svg';
import { ReactComponent as QualityOfCareIcon } from './icons/QualityOfCareIcon.svg';
import styled from '@emotion/styled/macro';
import { Typography, theme } from 'antd';
import { observer } from 'mobx-react-lite';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden;
`;

const TitleContainer = styled.div`
	inline-size: 100%;
	padding-block: 10px;
	padding-inline: 14px;
`;

interface PopoverContentProps {
	readonly selectedPage: string;
}

export const PopoverContent = observer(function PopoverContent({ selectedPage }: PopoverContentProps) {
	const { token } = theme.useToken();
	const { isAdmin } = useAuthStore();

	const isQualityClinic = useFeatureFlag('configuration_OrganizationQuality', false) || isAdmin;
	const showAdminTool = useFeatureFlag('flag_AdminTool', false);

	return (
		<Container>
			<TitleContainer>
				<Typography.Text strong style={{ color: token.colorPrimaryText, fontSize: token.fontSizeSM }}>
					Navina Applications
				</Typography.Text>
			</TitleContainer>
			<PopoverItem
				icon={<PatientPortraitIcon />}
				isSelected={selectedPage === 'patient-portrait'}
				title='Patient Portraits'
				description='Concise summaries and actionable insights for your patients'
				link='/schedule'
				analyticsExtras={{ actionId: 'AppsMenuSchedule', SourcePage: selectedPage }}
			/>
			{isQualityClinic && (
				<HasPermission to='quality_tool'>
					<PopoverItem
						icon={<QualityOfCareIcon />}
						isSelected={selectedPage === 'quality'}
						title='Quality Workspace'
						description='Assess, manage and track patient care gaps'
						link='/quality'
						analyticsExtras={{ actionId: 'AppsMenuQuality', SourcePage: selectedPage }}
					/>
				</HasPermission>
			)}
			<HasPermission to='hcc_dashboard'>
				<PopoverItem
					icon={<OrgDashboardIcon />}
					isSelected={selectedPage === 'dashboard'}
					title='Navina Analytics'
					description='Insights and performance tracking for your value-based care journey'
					link='/analytics'
					analyticsExtras={{ actionId: 'AppMenuReporting', SourcePage: selectedPage }}
				/>
			</HasPermission>
			{showAdminTool && (
				<HasPermission to='admin_tool' allowAlsoToAdmin={false}>
					<PopoverItem
						icon={<AdminToolIcon />}
						isSelected={selectedPage === 'admin'}
						title='Navina Admin'
						description='Manage your users and configure permissions'
						link='/admin'
						analyticsExtras={{ actionId: 'AppsMenuAdminTool', SourcePage: selectedPage }}
					/>
				</HasPermission>
			)}
			<HasPermission to='files_manager'>
				<PopoverItemSmall
					icon={<FileManagerIcon />}
					isSelected={selectedPage === 'files'}
					title='File manager'
					link='/files'
					analyticsExtras={{ actionId: 'AppMenuFileManager', SourcePage: selectedPage }}
				/>
			</HasPermission>
		</Container>
	);
});
