import { TWO_TONE_GRAYED_ICON_SETTINGS, EMPTY_ICON_SETTINGS } from '../../../theme/theme';
import { LoadingIcon } from '../assets/LoadingIcon';
import { EntitiesFetchingStatus } from '../models/types';
import { ApiTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Empty } from 'antd';
import { observer } from 'mobx-react-lite';
import { type ComponentProps } from 'react';

const FullContainerMessage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	position: absolute;
	inset: 0;
	z-index: 100;
`;

interface EntitiesStatusMessageProps extends ComponentProps<typeof FullContainerMessage> {
	readonly entitiesFetchingStatus: EntitiesFetchingStatus;
}

export const EntitiesStatusMessage = observer(function EntitiesStatusMessage({
	entitiesFetchingStatus,
	...props
}: EntitiesStatusMessageProps) {
	return (
		<FullContainerMessage {...props}>
			{entitiesFetchingStatus === EntitiesFetchingStatus.Pending && <LoadingIcon />}
			{entitiesFetchingStatus === EntitiesFetchingStatus.Success && (
				<Empty
					{...{
						...EMPTY_ICON_SETTINGS,
						description: 'No appointments found for the selected dates',
						image: <InfoCircleTwoTone {...TWO_TONE_GRAYED_ICON_SETTINGS} />,
					}}
				/>
			)}
			{entitiesFetchingStatus === EntitiesFetchingStatus.Error && (
				<Empty
					{...{
						...EMPTY_ICON_SETTINGS,
						description: 'An error occurred while fetching appointments, please contact support or try another filter',
						image: <ApiTwoTone {...TWO_TONE_GRAYED_ICON_SETTINGS} />,
					}}
				/>
			)}
		</FullContainerMessage>
	);
});
