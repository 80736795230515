import { AppointmentRowModel, type IAppointmentRowModel } from '../AppointmentsSchedule/models/AppointmentRowModel';
import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import {
	GenderMicrocopy,
	type PatientDemographicsRequestPayload,
	type PatientDemographicsResponsePayload,
	type NID,
	type PatientLookupRequestPayload,
	type PatientLookupResponsePayload,
	Gender,
} from '@navina/api-types';
import { action, flow, makeAutoObservable } from 'mobx';
import type { CancellablePromise } from 'mobx/dist/internal';

export interface IPatientLookupStore
	extends Omit<PatientLookupResponsePayload, 'entities' | 'totalEntities'>,
		PatientDemographicsResponsePayload {
	readonly getPatientLookupAppointments: (
		requestPayload: PatientLookupRequestPayload,
	) => Promise<PatientLookupResponsePayload>;
	readonly getPatientDemographics: (
		requestPayload: PatientDemographicsRequestPayload,
	) => Promise<PatientDemographicsResponsePayload>;
	readonly patientAppointmentsRowModels: ReadonlyArray<IAppointmentRowModel>;
	readonly patientLookupDemographicsFetchingStatus: EntitiesFetchingStatus;
	readonly patientLookupAppointmentsFetchingStatus: EntitiesFetchingStatus;
	readonly nid: NID | null;
	readonly initializeNidAndFetchData: (nid: NID) => void;
	readonly setNid: (nid: NID) => void;
	readonly setPageNumberAndFetchAppointments: (
		pageNumber: PatientLookupRequestPayload['pagination']['currentPage'],
	) => void;
	readonly setPageNumberAndEntitiesPerPage: (props: {
		newPageNumber: PatientLookupRequestPayload['pagination']['currentPage'];
		newEntitiesPerPage: number;
	}) => void;
	readonly setTotalAppointmentRows: (totalAppointmentRows: number) => void;

	readonly patientTitle: string;

	readonly pageNumber: PatientLookupRequestPayload['pagination']['currentPage'];
	readonly appointmentRowsPerPage: number;
	readonly totalAppointmentRows: number;

	readonly formattedFullName: string;
	readonly formattedEmrPatientId: string;
	readonly formattedAge: string;
	readonly formattedGender: string;
	readonly formattedDateOfBirth: string;
	readonly formattedDataOfBirthWithAge: string;
}

export const ENTITIES_PER_PAGE_OPTIONS = [15, 30, 50, 100] as const satisfies Array<number>; // AntD does not support readonly arrays
const DEFAULT_PAGE_SIZE: (typeof ENTITIES_PER_PAGE_OPTIONS)[number] = 15;
const MINIMUM_PAGE_NUMBER = 1;

interface PatientLookupStoreParams
	extends Pick<IPatientLookupStore, 'getPatientLookupAppointments' | 'getPatientDemographics'> {}

export class PatientLookupStore implements IPatientLookupStore {
	readonly getPatientLookupAppointments: IPatientLookupStore['getPatientLookupAppointments'];
	readonly getPatientDemographics: IPatientLookupStore['getPatientDemographics'];

	nid: NID | null = null;

	patientLookupDemographicsFetchingStatus: EntitiesFetchingStatus = EntitiesFetchingStatus.Idle;
	patientLookupAppointmentsFetchingStatus: EntitiesFetchingStatus = EntitiesFetchingStatus.Idle;

	fullName: IPatientLookupStore['fullName'] = 'N/A';
	emrPatientId: IPatientLookupStore['emrPatientId'] = 'N/A';
	gender: IPatientLookupStore['gender'] = Gender.Unknown;
	age: IPatientLookupStore['age'] = null;
	iso8601DateOfBirth: IPatientLookupStore['iso8601DateOfBirth'] = 'N/A';

	patientAppointmentsRowModels: IPatientLookupStore['patientAppointmentsRowModels'] = [];

	pageNumber: PatientLookupRequestPayload['pagination']['currentPage'] = MINIMUM_PAGE_NUMBER;
	appointmentRowsPerPage: number = DEFAULT_PAGE_SIZE;
	totalAppointmentRows: number = 0;

	private constructor({ getPatientLookupAppointments, getPatientDemographics }: PatientLookupStoreParams) {
		makeAutoObservable(this, {
			setPatientAppointments: action,
			setPatientLookupDemographicsFetchingStatus: action,
			setPatientLookupAppointmentsFetchingStatus: action,
			setNid: action,
			setPageNumber: action,
			setAppointmentRowsPerPage: action,
			setTotalAppointmentRows: action,

			fetchPatientLookupAppointmentsFlow: flow,
			fetchPatientDemographicsFlow: flow,

			setFullName: action,
			setEmrPatientId: action,
			setGender: action,
			setAge: action,
		});

		this.getPatientLookupAppointments = getPatientLookupAppointments;
		this.getPatientDemographics = getPatientDemographics;
	}

	get formattedFullName(): NonNullable<typeof this.fullName> {
		return this.fullName ?? 'Unknown Patient';
	}

	get formattedEmrPatientId(): NonNullable<typeof this.emrPatientId> {
		return `#${this.emrPatientId}`;
	}

	get formattedAge(): string {
		const { age } = this;
		return age ? `${age.toLocaleString()} y/o` : 'Unknown Age';
	}

	get formattedGender(): string {
		return GenderMicrocopy[this.gender];
	}

	get formattedDateOfBirth(): string {
		return this.iso8601DateOfBirth;
	}

	get patientTitle(): IPatientLookupStore['patientTitle'] {
		return this.formattedFullName;
	}

	get formattedDataOfBirthWithAge(): string {
		const { formattedAge, formattedDateOfBirth } = this;
		return `${formattedDateOfBirth} (${formattedAge})`;
	}

	static readonly create = (propsAndParams: PatientLookupStoreParams): PatientLookupStore => {
		return new PatientLookupStore(propsAndParams);
	};

	readonly initializeNidAndFetchData = (nid: typeof this.nid): void => {
		this.setNid(nid);
		this.fetchPatientDemographicsFlow();
		this.fetchPatientLookupAppointments();
	};

	readonly setFullName = (fullName: typeof this.fullName): void => {
		this.fullName = fullName;
	};

	readonly setEmrPatientId = (emrPatientId: typeof this.emrPatientId): void => {
		this.emrPatientId = emrPatientId;
	};

	readonly setGender = (gender: typeof this.gender): void => {
		this.gender = gender;
	};

	readonly setAge = (age: typeof this.age): void => {
		this.age = age;
	};

	readonly setNid = (nid: typeof this.nid): void => {
		this.nid = nid;
	};

	readonly setIso8601DateOfBirth = (iso8601DateOfBirth: typeof this.iso8601DateOfBirth): void => {
		this.iso8601DateOfBirth = iso8601DateOfBirth;
	};

	readonly setPatientAppointments = (patientAppointments: typeof this.patientAppointmentsRowModels): void => {
		this.patientAppointmentsRowModels = patientAppointments;
	};

	readonly setPatientLookupDemographicsFetchingStatus = (
		status: typeof this.patientLookupDemographicsFetchingStatus,
	): void => {
		this.patientLookupDemographicsFetchingStatus = status;
	};

	readonly setPageNumberAndEntitiesPerPage: IPatientLookupStore['setPageNumberAndEntitiesPerPage'] = ({
		newPageNumber,
		newEntitiesPerPage,
	}): void => {
		this.setPageNumber(newPageNumber);
		this.setAppointmentRowsPerPage(newEntitiesPerPage);
	};

	readonly setPatientLookupAppointmentsFetchingStatus = (
		status: typeof this.patientLookupAppointmentsFetchingStatus,
	): void => {
		this.patientLookupAppointmentsFetchingStatus = status;
	};

	readonly setAppointmentRowsPerPage = (appointmentRowsPerPage: typeof this.appointmentRowsPerPage): void => {
		this.appointmentRowsPerPage = appointmentRowsPerPage;
	};

	readonly setTotalAppointmentRows = (totalAppointmentRows: typeof this.totalAppointmentRows): void => {
		this.totalAppointmentRows = totalAppointmentRows;
	};

	readonly setPageNumber = (pageNumber: typeof this.pageNumber): void => {
		if (pageNumber < MINIMUM_PAGE_NUMBER) {
			throw Error(`Invalid pageNumber '${pageNumber}'. It should be greater than or equal to ${MINIMUM_PAGE_NUMBER}`);
		}

		this.pageNumber = pageNumber;
	};

	readonly setPageNumberAndFetchAppointments = (pageNumber: typeof this.pageNumber): void => {
		this.setPageNumber(pageNumber);
		this.fetchPatientLookupAppointments();
	};

	private ongoingFetchPatientLookupAppointmentsFlow: CancellablePromise<void> | null = null;
	readonly fetchPatientLookupAppointments = (): void => {
		this.ongoingFetchPatientLookupAppointmentsFlow?.cancel();
		this.ongoingFetchPatientLookupAppointmentsFlow = this.fetchPatientLookupAppointmentsFlow();
	};

	readonly fetchPatientLookupAppointmentsFlow = flow(function* fetchPatientLookupAppointmentsFlow(
		this: PatientLookupStore,
	) {
		this.setPatientLookupAppointmentsFetchingStatus(EntitiesFetchingStatus.Pending);

		try {
			const { appointmentRowsPerPage, nid } = this;

			if (!nid) {
				throw Error('NID is not set');
			}

			const response: Awaited<ReturnType<IPatientLookupStore['getPatientLookupAppointments']>> =
				yield this.getPatientLookupAppointments({
					nid,
					pagination: {
						currentPage: this.pageNumber,
						entitiesPerPage: appointmentRowsPerPage,
					},
				});

			const { entities } = response;

			this.setPatientAppointments(
				entities.map(function createAppointmentRowModel(schedulePageSummary) {
					return AppointmentRowModel.create({ schedulePageSummary });
				}),
			);

			this.setTotalAppointmentRows(response.totalEntities);
			this.setPatientLookupAppointmentsFetchingStatus(EntitiesFetchingStatus.Success);
		} catch (error) {
			console.error('Error while fetching entities', error);
			this.setPatientLookupAppointmentsFetchingStatus(EntitiesFetchingStatus.Error);
		}
	});

	readonly fetchPatientDemographicsFlow = flow(function* fetchPatientDemographicsFlow(this: PatientLookupStore) {
		this.setPatientLookupDemographicsFetchingStatus(EntitiesFetchingStatus.Pending);

		try {
			const { nid } = this;

			if (!nid) {
				throw Error('NID is not set');
			}

			const response: Awaited<ReturnType<IPatientLookupStore['getPatientDemographics']>> =
				yield this.getPatientDemographics({
					nid,
				});

			const { emrPatientId, fullName, gender, age, iso8601DateOfBirth } = response;

			this.setAge(age);
			this.setEmrPatientId(emrPatientId);
			this.setFullName(fullName);
			this.setGender(gender);
			this.setIso8601DateOfBirth(iso8601DateOfBirth);

			this.setPatientLookupDemographicsFetchingStatus(EntitiesFetchingStatus.Success);
		} catch (error) {
			console.error('Error while fetching entities', error);
			this.setPatientLookupDemographicsFetchingStatus(EntitiesFetchingStatus.Error);
		}
	});
}
