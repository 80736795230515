import {
	type BaseAppointmentsScheduleStoreParams,
	getOrCreateBaseAppointmentsScheduleStore,
} from '../../components/AppointmentsSchedule/models/BaseAppointmentsScheduleStore';
import { getOrCreateBaseScheduleFiltersContextStore } from '../../components/AppointmentsSchedule/models/BaseScheduleSearchFiltersStore/BaseScheduleFiltersContextStore';
import {
	type BaseScheduleSearchFiltersStoreParams,
	getOrCreateBaseScheduleSearchFiltersStore,
} from '../../components/AppointmentsSchedule/models/BaseScheduleSearchFiltersStore/BaseScheduleSearchFiltersStore';
import { ScheduleFiltersCacheableEntity } from '../../components/AppointmentsSchedule/models/BaseScheduleSearchFiltersStore/Types';
import {
	PatientSelectorModel,
	type PatientSelectorModelParams,
} from '../../components/PatientSelector/PatientSelectorModel';
import {
	getOrCreateInitializedSelectedGroupPracticeStore,
	type ISelectedGroupPracticeModel,
	type SelectedGroupPracticeModelParams,
} from '../../components/SelectedGroupPractice/SelectedGroupPracticeModel';
import {
	fetchDepartmentsOptions,
	fetchInsuranceOptions,
	fetchProvidersOptions,
	loadDepartmentsOptions,
	loadInsuranceOptions,
	loadProvidersOptions,
} from '../../indexeddb/SearchFiltersCacheDb';
import { getOrCreateApiGateway } from '../../server/apiGateway';
import { getOrCreateAuthStore } from '../../stores/authStore';
import { sendSchedulePageAppointmentsLoadAnalytics } from './SchedulePage.analytics';

export function createInitializedSingletonSelectedGroupPracticeModel({
	getAllGroupPracticeList = async () => {
		const response = await getOrCreateApiGateway().getGroupPractices({});
		if (response === null) {
			throw Error('Failed to fetch group practices');
		}

		return response.data;
	},
	...props
}: Partial<SelectedGroupPracticeModelParams>): ISelectedGroupPracticeModel {
	const model = getOrCreateInitializedSelectedGroupPracticeStore({
		...props,
		getAllGroupPracticeList,
	});

	return model;
}

// TODO - this one is the first one which is not a singleton. Perhaps I should mention that in the name of the other factories
export function createPatientSelectorModel({
	getPatients = async (requestPayload) => {
		const response = await getOrCreateApiGateway().getPatients(requestPayload);
		if (response === null) {
			throw Error('Failed to fetch patients');
		}
		return response.data;
	},
	selectedGroupPracticeModel = createInitializedSingletonSelectedGroupPracticeModel({}),
	...props
}: Partial<PatientSelectorModelParams>) {
	const model = PatientSelectorModel.create({
		...props,
		getPatients,
		selectedGroupPracticeModel,
	});

	return model;
}

export function createScheduleSearchFiltersStore({
	getDepartmentsProviders = async () => {
		const response = await getOrCreateApiGateway().getClinicsDepartmentsProviders();
		if (response === null) {
			throw Error('Failed to fetch clinics, departments, and providers');
		}
		return response.data;
	},
	cachingStrategies,
	selectedGroupPracticeModel = createInitializedSingletonSelectedGroupPracticeModel({}),
	patientSelectorModel: patientSelectModel = createPatientSelectorModel({}),
	...props
}: Partial<BaseScheduleSearchFiltersStoreParams>): ReturnType<typeof getOrCreateBaseScheduleSearchFiltersStore> {
	const navinaUserId = getOrCreateAuthStore().navinaUserId;

	return getOrCreateBaseScheduleSearchFiltersStore({
		...props,
		getDepartmentsProviders,
		cachingStrategies: cachingStrategies ?? {
			[ScheduleFiltersCacheableEntity.Providers]: {
				getEntities: () => fetchProvidersOptions(navinaUserId),
				setEntities: (entities) => loadProvidersOptions(navinaUserId, entities),
			},
			[ScheduleFiltersCacheableEntity.Departments]: {
				getEntities: () => fetchDepartmentsOptions(navinaUserId),
				setEntities: (entities) => loadDepartmentsOptions(navinaUserId, entities),
			},
			[ScheduleFiltersCacheableEntity.InsuranceGroups]: {
				getEntities: () => fetchInsuranceOptions(navinaUserId),
				setEntities: (entities) => loadInsuranceOptions(navinaUserId, entities),
			},
		},
		patientSelectorModel: patientSelectModel,
		selectedGroupPracticeModel,
	});
}

export function createAppointmentsScheduleStore({
	scheduleFiltersContextStore: scheduleFiltersStore = getOrCreateBaseScheduleFiltersContextStore(),
	scheduleSearchFiltersStore = createScheduleSearchFiltersStore({}),
	getScheduleAppointments = async (requestPayload) => {
		const response = await getOrCreateApiGateway().getScheduleAppointments(requestPayload);
		if (response === null) {
			throw Error('Failed to fetch schedule appointments');
		}

		sendSchedulePageAppointmentsLoadAnalytics(response, requestPayload);

		return response.data;
	},
	selectedGroupPracticeModel = createInitializedSingletonSelectedGroupPracticeModel({}),
	...props
}: Partial<BaseAppointmentsScheduleStoreParams>): ReturnType<typeof getOrCreateBaseAppointmentsScheduleStore> {
	return getOrCreateBaseAppointmentsScheduleStore({
		...props,
		scheduleSearchFiltersStore,
		getScheduleAppointments,
		scheduleFiltersContextStore: scheduleFiltersStore,
		selectedGroupPracticeModel,
	});
}
