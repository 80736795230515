import { CoreText } from '../../../base-ui/CoreText';
import { UserIcon } from '../../../base-ui/icons/UserIcon';
import { useAuthStore } from '../../../stores/authStore';
import { theme } from '../../../theme/theme';
import { withHideActionFromViewOnly } from '../../../utilities/withAttributes';
import { getOrCreateAnalytics } from '../../../utils/analytics';
import { useEmrName } from '../../../utils/useEMRName';
import { useNavinaData } from '../../../utils/useNavinaData';
import { UserSettings } from './UserSettings';
import styled from '@emotion/styled/macro';
import { Button, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const SignOutButton = styled(Link)`
	border-radius: 20px;
	block-size: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-block: 12px;
	padding-inline: 13px;
	margin-inline-start: 25px;
	cursor: pointer;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none !important;
`;

const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${theme.paddings.lg};
`;

const SettingsFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${theme.paddings.sm};
`;

const HorizontalFlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const UserProfileButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
`;

export const UserMenuPopup = observer(function UserMenuPopup() {
	const containerRef = useRef<HTMLDivElement>();

	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

	const analytics = getOrCreateAnalytics();

	const userMenuOpenClick = useCallback((): void => {
		analytics.track(analytics.idsNames.SummaryClick, {
			actionId: 'AvatarClick',
			action: isUserMenuOpen ? 'Close' : 'Open',
		});

		setIsUserMenuOpen(!isUserMenuOpen);
	}, [isUserMenuOpen, setIsUserMenuOpen]);

	const onCloseUserMenu = useCallback((): void => {
		if (isUserMenuOpen) {
			analytics.track(analytics.idsNames.SummaryClick, { actionId: 'AvatarClick', action: 'CloseClickOutside' });
			setIsUserMenuOpen(false);
		}
	}, [setIsUserMenuOpen, isUserMenuOpen]);

	const authStore = useAuthStore();
	const email = authStore.getEmail;

	const signOutHandler = useCallback((): void => void authStore.logout(), [authStore]);

	useEffect((): VoidFunction => {
		const handleClickOutside = (e): void => {
			if (containerRef?.current?.contains(e.target)) {
				return;
			}
			onCloseUserMenu();
		};

		document.addEventListener('mousedown', handleClickOutside);

		return (): void => document.removeEventListener('mousedown', handleClickOutside);
	}, [containerRef, onCloseUserMenu]);

	const emrName = useEmrName();
	const { isOverlay } = useNavinaData();

	const isShowingSettings = (emrName === 'athena' || authStore.admin) && !isOverlay;

	return (
		<UserProfileButtonContainer data-tip data-for='user_menu_tooltip' ref={containerRef}>
			<Popover
				{...{
					placement: 'bottomRight',
					content: (
						<SettingsContainer>
							<SettingsFieldContainer>
								<CoreText type='body1BoldUnderline'>User Name</CoreText>
								<HorizontalFlexContainer>
									<CoreText type='body1Medium'>{email}</CoreText>
									{(!authStore.getIsSAML || authStore.isAdmin) && (
										<SignOutButton onClick={signOutHandler} to='/'>
											<CoreText type='captionHeader' text='Sign out' whiteSpace='nowrap' />
										</SignOutButton>
									)}
								</HorizontalFlexContainer>
							</SettingsFieldContainer>
							{isShowingSettings && (
								<SettingsFieldContainer {...withHideActionFromViewOnly}>
									<UserSettings />
								</SettingsFieldContainer>
							)}
						</SettingsContainer>
					),
					children: (
						<Button
							{...{
								defaultChecked: isUserMenuOpen,
								type: 'primary',
								shape: 'circle',
								icon: <UserIcon />,
								id: 'userMenuAvatar',
								onClick: userMenuOpenClick,
							}}
						/>
					),
				}}
			/>
		</UserProfileButtonContainer>
	);
});
