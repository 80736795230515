import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import { type ISelectedGroupPracticeModel } from '../SelectedGroupPractice/SelectedGroupPracticeModel';
import type {
	NID,
	PatientSelectorRequestPayload,
	PatientSelectorResponsePayload,
	NavinaGroupPracticeId,
	PatientSelectorEntity,
} from '@navina/api-types';
import { action, flow, makeAutoObservable, reaction } from 'mobx';
import type { DefaultOptionType } from 'rc-select/lib/Select';

const DATA_FETCHING_DEBOUNCE_DELAY_MS = 400;

interface GenericKeySearchFilterOptionType<T extends string | number | null | undefined> extends DefaultOptionType {
	readonly label: string;
	readonly value: T;
	readonly groupPracticeId?: number;
}

interface StringKeySearchFilterOptionType extends GenericKeySearchFilterOptionType<string> {}

interface PatientSearchFilterOptionType extends StringKeySearchFilterOptionType {
	readonly patient: PatientSelectorEntity;
}

type GetPatientsType = (
	schedulePageSearchRequestPayload: PatientSelectorRequestPayload,
) => Promise<PatientSelectorResponsePayload>;

function getSelectOptionsFromPatientOptions(...patientOptions: ReadonlyArray<PatientSelectorEntity>) {
	return patientOptions.map(
		(patient) =>
			({
				label: patient.fullName ?? 'Unknown name',
				value: patient.nid,
				patient,
			}) as const,
	);
}

export interface IPatientSelectorModel {
	readonly selectedGroupPracticeModel: ISelectedGroupPracticeModel;
	readonly getPatients: GetPatientsType;
	readonly selectedPatientIds: ReadonlyArray<NID>;
	readonly patientSelectOptions: ReadonlyArray<PatientSearchFilterOptionType>;
	readonly patientOptionsFetchingStatus: EntitiesFetchingStatus;
	readonly setSelectedPatientIds: (...selectedPatientIds: ReadonlyArray<NID>) => void;
	readonly resetSelectedPatientIds: VoidFunction;
	readonly resetPatientOptions: VoidFunction;
	readonly canSelectPatientId: boolean;
	readonly fetchPatientOptionsWithDebounce: (searchQuery: string) => void;
	readonly setPatientOptionsFetchingStatus: (status: EntitiesFetchingStatus) => void;
	readonly setPatientSelectOptions: (...options: ReadonlyArray<PatientSearchFilterOptionType>) => void;
}

export interface PatientSelectorModelParams {
	readonly getPatients: IPatientSelectorModel['getPatients'];
	readonly selectedGroupPracticeModel: IPatientSelectorModel['selectedGroupPracticeModel'];
	readonly initialSelectedPatientIds?: IPatientSelectorModel['selectedPatientIds'];
	readonly onSelectedPatientIdsChange?: (...selectedPatientIds: IPatientSelectorModel['selectedPatientIds']) => void;
}

export class PatientSelectorModel implements IPatientSelectorModel {
	private ongoingFetchPatientOptionsFlow: ReturnType<typeof this.fetchPatientOptionsFlow> | null = null;
	private onSelectedPatientIdsChange: PatientSelectorModelParams['onSelectedPatientIdsChange'];
	getPatients: IPatientSelectorModel['getPatients'];
	selectedGroupPracticeModel: IPatientSelectorModel['selectedGroupPracticeModel'];

	selectedPatientIds: IPatientSelectorModel['selectedPatientIds'] = [];
	patientSelectOptions: IPatientSelectorModel['patientSelectOptions'] = [];
	patientOptionsFetchingStatus: IPatientSelectorModel['patientOptionsFetchingStatus'] = EntitiesFetchingStatus.Idle;

	constructor({ getPatients, selectedGroupPracticeModel, onSelectedPatientIdsChange }: PatientSelectorModelParams) {
		makeAutoObservable(this, {
			setSelectedPatientIds: action,
			setPatientSelectOptions: action,
			setPatientOptionsFetchingStatus: action,
			setOnSelectedPatientIdsChange: action,
			setSelectedGroupPracticeModel: action,
			setGetPatients: action,
			fetchPatientOptionsFlow: flow,
		});

		this.setGetPatients(getPatients);
		this.setSelectedGroupPracticeModel(selectedGroupPracticeModel);
		this.setOnSelectedPatientIdsChange(onSelectedPatientIdsChange);
	}

	static readonly create = (propsAndParams: PatientSelectorModelParams): PatientSelectorModel => {
		return new PatientSelectorModel(propsAndParams);
	};

	readonly setGetPatients = (getPatients: typeof this.getPatients): void => {
		this.getPatients = getPatients;
	};

	readonly setSelectedGroupPracticeModel = (
		selectedGroupPracticeModel: typeof this.selectedGroupPracticeModel,
	): void => {
		this.selectedGroupPracticeModel = selectedGroupPracticeModel;
	};

	readonly setOnSelectedPatientIdsChange = (
		onSelectedPatientIdsChange: typeof this.onSelectedPatientIdsChange,
	): void => {
		this.onSelectedPatientIdsChange = onSelectedPatientIdsChange;
	};

	readonly initializeResetReaction = (): void => {
		const { reset } = this;

		reaction(
			() => this.selectedGroupPracticeModel.selectedGroupPracticeId,
			function reactToSelectedGroupPracticeId(selectedGroupPracticeId): void {
				if (selectedGroupPracticeId) {
					reset();
				}
			},
		);
	};

	readonly reset = (): void => {
		this.ongoingFetchPatientOptionsFlow?.cancel();
		this.resetPatientOptions();
		this.resetSelectedPatientIds();
		this.setPatientOptionsFetchingStatus(EntitiesFetchingStatus.Idle);
	};

	readonly resetSelectedPatientIds = (): void => this.setSelectedPatientIds();
	readonly resetPatientOptions = (): void => this.setPatientSelectOptions();

	get canSelectPatientId(): boolean {
		return true;
	}

	readonly setSelectedPatientIds = (...selectedPatientIds: typeof this.selectedPatientIds): void => {
		this.selectedPatientIds = selectedPatientIds;
		this.onSelectedPatientIdsChange?.(...selectedPatientIds);
	};
	readonly setPatientOptionsFetchingStatus = (status: typeof this.patientOptionsFetchingStatus): void => {
		this.patientOptionsFetchingStatus = status;
	};
	readonly setPatientSelectOptions = (...options: typeof this.patientSelectOptions): void => {
		this.patientSelectOptions = options;
	};

	private fetchPatientOptionsTimeoutId: number | null = null;

	readonly fetchPatientOptions = (searchQuery: string): void => {
		if (searchQuery.trim().length === 0) {
			this.reset();
			return;
		}

		const { selectedGroupPracticeId } = this.selectedGroupPracticeModel;
		if (!selectedGroupPracticeId) {
			throw new Error('Selected group practice ID is not set.');
		}

		this.ongoingFetchPatientOptionsFlow?.cancel();
		this.ongoingFetchPatientOptionsFlow = this.fetchPatientOptionsFlow(searchQuery, selectedGroupPracticeId);
	};

	readonly fetchPatientOptionsWithDebounce = (searchQuery: string): void => {
		if (this.fetchPatientOptionsTimeoutId) {
			window.clearTimeout(this.fetchPatientOptionsTimeoutId);
		}

		const { fetchPatientOptions } = this;
		this.fetchPatientOptionsTimeoutId = window.setTimeout(() => {
			fetchPatientOptions(searchQuery);
		}, DATA_FETCHING_DEBOUNCE_DELAY_MS);
	};

	readonly fetchPatientOptionsFlow = flow(function* fetchPatientOptionsFlow(
		this: IPatientSelectorModel,
		searchQuery: string,
		groupPracticeId: NavinaGroupPracticeId,
	) {
		this.setPatientOptionsFetchingStatus(EntitiesFetchingStatus.Pending);
		this.resetPatientOptions();

		try {
			const response: Awaited<ReturnType<IPatientSelectorModel['getPatients']>> = yield this.getPatients({
				freeText: searchQuery,
				groupPracticeId: groupPracticeId,
			} as const);

			const { entities: patients } = response;

			this.setPatientSelectOptions(...getSelectOptionsFromPatientOptions(...patients));
			this.setPatientOptionsFetchingStatus(EntitiesFetchingStatus.Success);
		} catch (error) {
			console.error('Error while fetching entities', error);
			this.setPatientOptionsFetchingStatus(EntitiesFetchingStatus.Error);
		}
	});
}
