import { getOrCreateApiGateway } from '../../server/apiGateway';
import { type CognitoNavinaJwt } from '../../stores/authStore';
import type { UserMetadata } from '../../utils/auth';
import { addCurrentTimeUnixToLocalStorage, getParamFromUrl } from '../../utils/common';
import {
	AUTH_0_CONNECTION_NAME,
	AUTH_0_TOKEN,
	EPIC_LOGIN_DOC_REDIRECT_TIME,
	GET_EPIC_TOKEN_AND_APP_CONTEXT_REQUEST_TIME,
	GET_EPIC_TOKEN_AND_APP_CONTEXT_RESPONSE_TIME,
	REDIRECTING_TO_SUMMARY_TIME,
	SMART_ON_FHIR_LUNCH_TIME,
	USER_METADATA,
} from '../../utils/consts';
import { browserHistory } from '../../utils/history';
import { getOrCreateLogger } from '../../utils/logger';
import { EPIC_CLIENT_AUD, EPIC_CLIENT_AUTH_URL, EPIC_CLIENT_TOKEN_ENDPOINT, EPIC_CLIENT_LAUNCH_ISS } from './consts';
import type { GetAppContextDataInputType } from './types';
import JwtDecode from 'jwt-decode';

const logger = getOrCreateLogger();

const getEpicURLFromWellKnown = async (
	iss: string,
): Promise<{
	authServerUrl: string;
	tokenEndpoint: string;
	aud: string;
}> => {
	console.log(iss);
	const wellKnownUrl = `${iss}/.well-known/smart-configuration`;
	const wellKnownUrlResponse = await fetch(wellKnownUrl);
	const wellKnownConfiguration = await wellKnownUrlResponse.text();
	const wellKnownConfigurationJson = JSON.parse(wellKnownConfiguration);
	const authServerUrl = wellKnownConfigurationJson.authorization_endpoint;
	const tokenEndpoint = wellKnownConfigurationJson.token_endpoint;
	console.log('url response', { authServerUrl, tokenEndpoint, aud: iss });
	return { authServerUrl, tokenEndpoint, aud: iss };
};

const storeEpicClientURLInLocalStorage = (input: {
	authServerUrl: string;
	tokenEndpoint: string;
	aud: string;
}): void => {
	localStorage.setItem(EPIC_CLIENT_TOKEN_ENDPOINT, input.tokenEndpoint);
	localStorage.setItem(EPIC_CLIENT_AUD, input.aud);
	localStorage.setItem(EPIC_CLIENT_AUTH_URL, input.authServerUrl);
};

const getAndAddEpicClientURLToLocalStorage = async (iss: string) => {
	console.log('getting token endpoint from well known because it is not in local storage');
	const { authServerUrl, tokenEndpoint, aud } = await getEpicURLFromWellKnown(iss);
	return { tokenEndpoint, aud, authServerUrl };
};

const handleMissingEpicAuthParams = async () => {
	const iss = localStorage.getItem(EPIC_CLIENT_LAUNCH_ISS);
	if (!iss) {
		logger.error('iss not found in local storage cannot get token endpoint from well known');
		throw Error('internal error, cannot get token endpoint from well known please try again later');
	}
	const epicUrls = await getAndAddEpicClientURLToLocalStorage(iss);
	storeEpicClientURLInLocalStorage(epicUrls);
	return epicUrls;
};

const handleTokenAndAppContextResponse = (appContextData: any): true | undefined => {
	logger.trace('appContextData', appContextData);
	const sid = appContextData.data.summaryId;
	const document_id = appContextData.data.documentId;

	if (sid && !document_id) {
		logger.trace('redirecting to summary page');
		addCurrentTimeUnixToLocalStorage(REDIRECTING_TO_SUMMARY_TIME);
		if (window.location.pathname.includes('/overlay')) {
			browserHistory.push(`/overlay/${sid}`);
		} else {
			window.location.href = `/overlay/${sid}`;
		}
		return true;
	}

	if (document_id) {
		addCurrentTimeUnixToLocalStorage(EPIC_LOGIN_DOC_REDIRECT_TIME);
		logger.trace('redirecting to document page');
		browserHistory.push(`/doc/${document_id}`);
		return true;
	}
};

const getAppContextDataInput = async (): Promise<GetAppContextDataInputType> => {
	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('code');
	const userMetadata: UserMetadata = JSON.parse(localStorage.getItem(USER_METADATA));
	const connectionName = localStorage.getItem(AUTH_0_CONNECTION_NAME);
	let tokenUrlEndpoint = localStorage.getItem(EPIC_CLIENT_TOKEN_ENDPOINT);

	if (!tokenUrlEndpoint) {
		const { tokenEndpoint } = await handleMissingEpicAuthParams();
		tokenUrlEndpoint = tokenEndpoint;
	}

	const dataSourceId =
		userMetadata.dataSourceId || JwtDecode(localStorage.getItem(AUTH_0_TOKEN))['https://navina.ai/dataSourceId'];

	return {
		code,
		connectionName,
		dataSourceId: dataSourceId as string,
		redirectUri: window.location.origin + window.location.pathname,
		tokenUrl: tokenUrlEndpoint,
	};
};

const handleEpicLoginAfterEpicAuth = async (): Promise<void> => {
	const appContextDataInput = await getAppContextDataInput();
	logger.trace('appContextData input', appContextDataInput);

	// executing post request to get the token
	addCurrentTimeUnixToLocalStorage(GET_EPIC_TOKEN_AND_APP_CONTEXT_REQUEST_TIME);
	const appContextData = await getOrCreateApiGateway().getEpicTokenAndAppContext(appContextDataInput);
	addCurrentTimeUnixToLocalStorage(GET_EPIC_TOKEN_AND_APP_CONTEXT_RESPONSE_TIME);

	const redirectedFromFunction = handleTokenAndAppContextResponse(appContextData);

	if (!redirectedFromFunction) {
		logger.error('no document id or summary id found redirecting to a unknown sid');
		// clearing local storage to block user from going back to wrong summary or document or schedule page
		localStorage.clear(); // to be logged in to auth0 next time
		browserHistory.push('/login');
	}
};

export const isTokenAboutToExpire = (authToken: string | null): boolean => {
	if (!authToken) {
		return true;
	}

	const exp = JwtDecode<CognitoNavinaJwt>(authToken).exp;
	const tokenExpirationInMillisecondsMinusOneHour = exp * 1_000 - 60 * 60 * 1_000;
	return tokenExpirationInMillisecondsMinusOneHour - Date.now() < 0;
};

const getCodeParamFromUrl = (): string | null => getParamFromUrl('code');

export const EpicAuthLoginFlow = (): void => {
	if (getCodeParamFromUrl()) {
		logger.trace('getting token from epic');
		// Epic Redirected back flow
		handleEpicLoginAfterEpicAuth();
	}
};

export const isPartOfEPICLoginFlow = (): boolean => Boolean(localStorage.getItem(SMART_ON_FHIR_LUNCH_TIME));
