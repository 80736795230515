import { IS_PRODUCTION } from '../config/Environment';
import { getCurrentConfiguration } from '../config/config';
import mixpanel, { type RequestOptions } from 'mixpanel-browser';
import QueryString from 'query-string';

function removeTrailingSlash(url: string): string {
	return url.endsWith('/') ? url.slice(0, -1) : url;
}

async function isValidMixpanelTrackEndpointUrl(hostUrl: string): Promise<boolean> {
	return new Promise((resolve): void => {
		fetch(`${removeTrailingSlash(hostUrl)}/track`, { method: 'HEAD' })
			.then((response) => {
				if (response.ok) {
					return resolve(true);
				}
				return resolve(false);
			})
			.catch((_error) => resolve(false));
	});
}

async function getFirstValidApiHostRecursivelyOrThrow(
	currentApiHost: string,
	availableApiHosts: ReadonlyArray<string>,
): Promise<string> {
	const isCurrentHostApiValidUrl = await isValidMixpanelTrackEndpointUrl(currentApiHost);
	if (isCurrentHostApiValidUrl) {
		return currentApiHost;
	}

	const [nextApiHost = null] = availableApiHosts;
	if (nextApiHost === null) {
		throw Error('All the available Mixpanel host URLs were invalid, Mixpanel could not be initialized');
	}

	return getFirstValidApiHostRecursivelyOrThrow(nextApiHost, availableApiHosts.slice(1));
}

interface PatientMetadata {
	readonly age: number;
	readonly sex: string;
	readonly plLength: number;
	readonly rawPlLength: number;
	readonly raf: number;
	readonly race: string;
	readonly visitType: string;
	readonly navigationSrc: string;
	readonly navigationFocus: string;
	readonly openedAs: string | undefined;
	readonly visitDate: string;
	readonly visitTime: string;
	readonly sid: string;
	readonly dataSource: number;
	readonly provider: string;
	readonly documentsNumber: number;
	readonly ascvdScore: number;
	readonly ascvdState: string;
	readonly insurance: string;
	readonly isPostVisit: boolean;
	readonly resetRAFMidYear: boolean;
}

interface ProviderMetadata {
	readonly role: string;
	readonly department: string;
	readonly clinic: string;
	readonly emrName: string;
}

interface AnalyticsTrackMetadata {
	readonly src: string;
	readonly openedAs: string;
	readonly fullScreen: boolean;
	readonly tab: string;
	readonly isQualityTab: boolean;
}

type CustomAnalyticMetadata = Partial<PatientMetadata> & Partial<ProviderMetadata> & Partial<AnalyticsTrackMetadata>;

class Analytics {
	extraMetadata: CustomAnalyticMetadata;
	patientMetadata: Partial<PatientMetadata> = {};
	providerMetadata: Partial<ProviderMetadata> = {};
	sid: string | null;
	analyticsTrackMetadata: Partial<AnalyticsTrackMetadata> = {};
	isHostApiValidUrl: boolean = false;

	readonly idsNames = {
		AllDocs_Open: 'AllDocs Open',
		AllDocs_Close: 'AllDocs Close',
		AllDocs_ClickRow: 'AllDocs DocumentRow Click',
		AllDocs_Click: 'AllDocs Click',
		AllDocs_DocumentTypeFilter_Click: 'AllDocs DocumentTypeFilter Click',
		AllDocs_DocumentSourceFilter_Click: 'AllDocs DocumentSourceFilter Click',
		AllDocs_FilterResults_Shown: 'AllDocs FilterResults Shown',
		SortDocumentName: 'Sort Document Name',
		SortDocumentDate: 'Sort Document Date',
		FilterDocument: 'Filter Document',
		ViewICDsClick: 'View ICDs Clicked',

		DocsSearch: 'Docs Search',
		DocSearchClear: 'Doc Search Clear',

		FeedbackClicked: 'Feedback Clicked',
		ContactUsClicked: 'Contact Us Clicked',

		SuggestionListSort: 'SuggestionList Sort',

		AuthPasswordLoginSubmitLogin: 'Auth_PasswordLogin SubmitLogin',
		AuthTwoStepVerificationSubmitMFA: 'Auth_TwoStepVerification SubmitMFA',

		SummaryListDateSearchByType: 'SummaryList DateSearch (by Type)',
		SummaryListDateSearchByRange: 'SummaryList DateSearch (by Range)',
		SummaryListTextSearch: 'SummaryList TextSearch',
		SummaryListLoad: 'SummaryList Load',
		SummaryListUnload: 'SummaryList Unload',
		SummaryListFilterClick: 'SummaryList Filter Click',

		DocumentTextCopied: 'Document Text Copied',
		DocumentSearched: 'Document Searched',
		DocumentOpened: 'Document Opened',
		DocumentClose: 'Document Close',
		DocumentLoaded: 'Document Loaded',
		DocumentClick: 'Document click',
		Document_DxMenuClick: 'Document DxMenuClick',
		Document_RenderingFailed: 'Document RenderingFailed',

		MultiplePatientsIdsOkClicked: 'MultiplePatientsIds_OkClicked',
		MultiplePatientsIdsView: 'MultiplePatientsIds_View',
		MedicationsSectionViewed: 'Medications Section Viewed',
		PreventiveSectionViewed: 'Preventive Section Viewed',
		HospitalNotesSectionViewed: 'Hospital Notes Section Viewed',
		LabsSectionViewed: 'Labs Section Viewed',
		DiagnosticImagingSectionViewed: 'Diagnostic Imaging Section Viewed',
		FamilyHistorySectionViewed: 'Family History Section Viewed',
		SurgicalHistorySectionViewed: 'Surgical History Section Viewed',
		DiagnosisOverviewSectionViewed: 'Diagnosis Overview Section Viewed',
		MedicationsRefillSectionViewed: 'Medications Refill Section Viewed',

		Link2EMRHeaderPatientNameClicked: 'Link2EMR - Header Patient Name Clicked',
		Link2EMRFloatingButtonClicked: 'Link2EMR - Floating Button Clicked',

		ViewSummaryLoad: 'ViewSummary Load',
		ViewSummaryUnload: 'ViewSummary Unload',

		SummaryTextCopied: 'Summary Text Copied',

		Error: 'Error',
		ErrorHTTPRequest: 'ErrorHTTPRequest',
		ErrorHTTPRequestTokenRefreshed: 'ErrorHTTPRequest TokenRefreshed',
		ErrorHTTPRequestReloadRequest: 'ErrorHTTPRequest ReloadRequest',

		LoginPageView: 'LoginPage View',
		LogInLoaded: 'Login Loaded',
		LogInSuccessful: 'LogIn Successful',
		LogInAttempt: 'LogIn Attempt',
		LogInFailed: 'LogIn Failed',
		LogOut: 'LogOut',
		LogInOtpAttemptFirstStage: 'LogIn Otp Attempt first stage',
		LogInOtpAttemptSecondStage: 'LogIn Otp Attempt second stage',
		LoginTypeChanged: 'Login type changed',
		LoginOtpFirstStageFailed: 'LogIn OTP first stage failed',
		LoginOtpSecondStageFailed: 'LogIn OTP second stage failed',
		LogInForgotPasswordLoaded: 'LogIn ForgotPassword Loaded',
		LogInForgotPasswordRequestSubmitted: 'LogIn ForgotPassword Request Submitted',
		LogInForgotPasswordRequestFailed: 'LogIn ForgotPassword Request Failed',
		LoginInErrorShown: 'Login ErrorMessage Shown',

		Reset: 'Reset',

		PushToEmr_checked: 'PushToEmr checked',
		PushToEmr_unchecked: 'PushToEmr unchecked',
		PushToEmr_added: 'PushToEmr added',
		PushToEmr_ErrorPopupView: 'PushToEmr ErrorPopupView',
		PushToEmr_ErrorPopupViewClick: 'PushToEmr ErrorPopupView Click',
		PushToEmr_clickeded: 'PushToEmr clickeded',
		PushToEmr_encounterInactivePopupView: 'PushToEmr encounterInactivePopupView',
		PushToEmr_confirmationPopupClick: 'PushToEmr confirmationPopupClick',
		PushToEmr_confirmationPopupView: 'PushToEmr confirmationPopupView',

		Push2EMRRequestStarted: 'Push2Emr Request Started',
		Push2EMRRequestCompleted: 'Push2Emr Request Completed',
		Push2EMRServiceRequestStarted: 'Push2Emr PushService Started',
		Push2EMRServiceRequestCompleted: 'Push2Emr PushService Completed',
		Push2EMRServiceRequestSkipped: 'Push2Emr PushService Skipped',
		Push2EMRDiagnosisAdded: 'Push2Emr DX Added',
		Push2EMRDiagnosisFailed: 'Push2Emr DX Failed',

		PmdShowMoreClicked: 'Pmd show more clicked',
		PmdShowMoreView: 'Pmd show more view',
		InitTextSearchFailed: 'Pdf viewer initial search text failed',
		PdfSearchErrorCloseClicked: 'Pdf search error close clicked',
		pdfClosed: 'Pdf viewer closed',
		PmdView: 'pmd view',
		RafGapView: 'raf gap view',
		PmdClick: 'pmd click',
		RafGapClick: 'raf gap click',
		DocumentToolbarClick: 'Document Toolbar Click',
		DocumentToolbarViewd: 'Document Toolbar Viewed',

		PmdRemoveDxPopupView: 'PMD RemoveDx Popup View',
		PmdRemoveDxPopupClick: 'PMD RemoveDx Popup Click',
		PmdTrashCanClicked: 'PMD TrashCan Clicked',
		PmdTrashCanShown: 'PMD Trashcan Shown',

		RafGapRemoveDxPopupView: 'RAFGap RemoveDx Popup View',
		RafGapRemoveDxPopupClick: 'RAFGap RemoveDx Popup Click',
		RafGapTrashCanClicked: 'RAFGap TrashCan Clicked',
		RafGapTrashCanShown: 'RAFGap Trashcan Shown',

		RafIcdCopied: 'RAFGap ICD Copied',

		RAFScoreEstimationView: 'RAFScoreEstimation View',

		NewFeatureAnnouncementShown: 'NewFeatureAnnouncementShown',
		NewFeatureAnnouncementClick: 'NewFeatureAnnouncementClick',
		NewFeaturePopupView: 'NewFeaturePopupView',
		NewFeaturePopupClick: 'NewFeaturePopupClick',

		PartialDataAnnouncementShown: 'PartialDataAnnouncementShown',
		PartialDataAnnouncementClick: 'PartialDataAnnouncementClick',
		PartialDataPopupView: 'PartialDataPopupView',
		PartialDataPopupClick: 'PartialDataPopupClick',

		AddonHealthMonitorReport: 'AddonHealthMonitor Report',
		AddonAssessmentAndPlanIncoming: 'AddonAssessmentAndPlan IncomingDx',
		AdtHl7AssessmentAndPlanIncoming: 'AdtHl7AssessmentAndPlan IncomingDx',

		EMROnlineSync: 'EMROnline Sync',
		EMROnlineAppointmentSync: 'EMROnline Appointment Sync',
		ManualEMROnlineSync: 'Manual EMROnline Sync',
		FailedManualEMROnlineSync: 'Failed Manual EMROnline Sync',

		InAppExplanationPopupView: 'InApp ExplanationPopUp View',
		InAppExplanationPopupClick: 'InApp ExplanationPopUp Click',
		InAppExplanationPopupShown: 'InApp ExplanationPopUp Shown',

		NavigationMenuClick: 'NavigationMenu Click',

		SettingsClick: 'Settings Click',
		SettingsResultShown: 'Settings Result Shown',

		SummaryClick: 'Summary Click',

		AddressedDxView: 'AddressedDx View',
		AddressedDxClick: 'AddressedDx Click',

		CartDxClick: 'CartDx Click',
		CartClick: 'Cart Click',
		CartDxView: 'CartDx View',
		CartPopupView: 'Cart Popup View',
		CartPopupClick: 'Cart Popup Click',
		CartIcdCopied: 'Cart ICD Copied',

		RecommendedDxView: 'RecommendedDx View',
		RecommendedDxClick: 'RecommendedDx Click',
		RecommendedDxGrayedOutShown: 'RecommendedDx GrayedOut Shown',

		NoteWizardView: 'NoteWizard View',
		NoteWizardClick: 'NoteWizard Click',

		AddDiagnosisWizardView: 'AddDiagnosisWizard View',
		AddDiagnosisWizardClick: 'AddDiagnosisWizard Click',
		AddDiagnosisWizardDisclaimerShown: 'AddDiagnosisWizard Disclaimer Shown',

		AuthInitDone: 'AuthInit Done',
		NoAccessPageView: 'NoAccessPage View',

		DashboardClick: 'DashboardClick',
		HccVersionsToggleClick: 'HccVersionsToggleClick',
		DashboardDocumentationButtonsShown: 'DashboardDocumentationButtonsShown',
		DashboardView: 'Dashboard View',
		TabUnload: 'TabUnload',

		NavinaFilterClick: 'NavinaFilterClick',
		NavinaFilterSelect: 'NavinaFilterSelect',
		NavinaFilterClear: 'NavinaFilterClear',

		ToastMessageView: 'ToastMessage View',
		ToastMessageClose: 'ToastMessage Close',

		EMRPostVisitiFrameView: 'EMR PostVisitiFrame View',
		EMRPostVisitiFrameClick: 'EMR PostVisitiFrame Click',

		AgilonNoteView: 'AgilonNote View',
		AgilonNoteClick: 'AgilonNote Click',

		BannerView: 'Banner View',
		BannerClick: 'Banner Click',

		LabTrendButtonView: 'LabsTrendButton Viewed',
		LabTrendButtonClick: 'LabsTrendButton Clicked',

		LabTrendModalHover: 'LabsTrendModal Hover',
		LabTrendModalClick: 'LabsTrendModal Clicked',
		LabTrendModalView: 'LabsTrendModal Viewed',
		LabGraphViewed: 'LabGraph Viewed',

		TooltipMessageShown: 'TooltipMessage Shown',
		GrayedOutElementShown: 'GrayedOut Element Shown',
		ClinicalAlertsSectionViewd: 'Clinical Alerts Section Viewed',

		ClinicalUpdatesButtonShown: 'ClinicalUpdatesButton Shown',
		ClinicalUpdatesButtonClick: 'ClinicalUpdatesButton Click',
		DiagnosticImagingSectionClick: 'Diagnostic Imaging Section Click',
		HospitalNotesSectionClick: 'Hospital Notes Section Click',

		FileManagerClick: 'FileManager Click',
		FileManagerToastShown: 'FileManagerToast Shown',
		UploadFileModalClick: 'UploadFileModal Click',
		FileManagerLoad: 'FileManager Load',
		FilesTableClick: 'FilesTable Click',

		QualityToolLoad: 'QualityTool Load',
		QualityHomepageLoad: 'QualityHomepage Load',
		MeasuresLoaded: 'MeasuresLoaded',
		QualityToolPageClick: 'QualityToolPage Click',
		MeasureClick: 'Measure Click',
		QualityFilterMenuClick: 'QualityFilterMenu Click',
		UpdateMeasureClick: 'UpdateMeasure Click',
		MeasureFollowUpDateClick: 'MeasureFollowUpDate Click',
		QualityToolToastShown: 'QualityToolToast Shown',
		SummaryNotePopoverShown: 'AddGeneralNoteWizard View',
		SummaryNotePopoverClick: 'AddGeneralNoteWizard Click',
		NoPatientDataPage: 'NoPatientDataPage',

		SmartOnFhirLaunch: 'Smart on FHIR Launch',
		QualityActionClicked: 'QualityActionClicked',

		ShallNotHappenHappened: 'ShallNotHappenHappened',

		QualityPageIn: 'QualityPageIn',
		QualityPageOut: 'QualityPageOut',

		QualityHomepageClick: 'QualityHomepage Click',

		SummariesListCacheChecked: 'SummariesListCacheChecked',
		UserTrackingTimeCheck: 'UserTrackingTimeCheck',

		SlownessOptimization: 'SlownessOptimization',

		CopyToClipboardFailed: 'Copy To Clipboard failed',

		PatientLookupSearchClick: 'PatientLookupSearch Click',

		PatientLookupPageLoad: 'PatientLookupPage Load',
		PatientLookupPageAppointmentsLoad: 'PatientLookupPage Appointments Load',
		PatientLookupPageClick: 'PatientLookupPage Click',

		PatientLookupHeaderClick: 'PatientLookupHeader Click',

		AdjacentAppointmentsClick: 'AdjacentAppointments Click',

		SchedulePageLoad: 'SchedulePage Load',
		SchedulePageAppointmentsLoad: 'SchedulePage Appointments Load',
		SchedulePageClick: 'SchedulePage Click',
	} as const;

	identify = (id: string, username: string): void => {
		if (id) {
			mixpanel.identify(id);
			if (username) {
				this.setPeople({ 'User ID': username, 'User Email': username });
			}
		}
	};

	track = async (name: string, props = {}): Promise<void> => {
		try {
			if (!this.isHostApiValidUrl) {
				const [firstMixpanelHost = null, ...restMixpanelHosts] = getCurrentConfiguration().MixpanelHost;

				if (firstMixpanelHost === null) {
					throw Error('Configurations options for Mixpanel host (MixpanelHost) are missing');
				}

				const firstValidApiHost = await getFirstValidApiHostRecursivelyOrThrow(firstMixpanelHost, restMixpanelHosts);

				if (firstValidApiHost) {
					mixpanel.set_config({ api_host: removeTrailingSlash(firstValidApiHost) });
					this.isHostApiValidUrl = true;
				}
			}
		} catch (error) {
			console.error('Error while trying to set Mixpanel API host', error);
		}

		if (this.extraMetadata) {
			props = { ...props, ...this.extraMetadata };
		}

		const currentHostUrl = mixpanel.get_config('api_host');
		if (typeof currentHostUrl !== 'string') {
			throw Error('Mixpanel invalid host');
		}
		props = { ...props, 'Mixpanel API Host': currentHostUrl };

		const paramsNames = QueryString.parse(window.location.search);

		props = {
			...props,
			service: 'summary-frontend',
			IsOverlay: window.location.href.toLocaleLowerCase().includes('/overlay/'),
			IsOverlayGeneralInfoChartPage:
				window.location.pathname.toLocaleLowerCase().includes('/overlay/') && paramsNames.generalInfoChartPage === '1',
			screenResolution: {
				innerWidth: window.innerWidth,
				innerHeight: window.innerHeight,
				outerWidth: window.outerWidth,
				outerHeight: window.outerHeight,
				devicePixelRatio: window.devicePixelRatio,
			},
			userAgent: navigator.userAgent,
		};

		const options = {} as const satisfies RequestOptions;

		mixpanel.track(name, props, options);

		console.log('analytic: ', name, props);
	};

	setPeople = (...args: Parameters<typeof mixpanel.people.set_once>): void => {
		mixpanel.people.set_once(...args);
	};

	reset = (): void => {
		window.setTimeout((): void => {
			this.track(this.idsNames.Reset);
			mixpanel.reset();
			this.patientMetadata = {};
			this.providerMetadata = {};
			this.sid = null;
		}, 700);
	};

	register = (providerMetadata: Partial<ProviderMetadata>): void => {
		this.providerMetadata = providerMetadata;
		this.updateExtraMetadata();
	};

	setPatientMetadata = (patientMetadata: Partial<PatientMetadata>): void => {
		this.patientMetadata = patientMetadata;
		this.updateExtraMetadata();
	};

	setAnalyticTrack = (analyticsTrack: Partial<AnalyticsTrackMetadata>): void => {
		this.analyticsTrackMetadata = { ...this.analyticsTrackMetadata, ...analyticsTrack };
		this.updateExtraMetadata();
	};

	// sid acts as a unique identifier for the current session in mixpanel
	// this is useful more then just in a summary (where sid is the summary id)
	// but also used in other places that wants to track a session
	// (for example - QualityTool)
	setSid = (sid: string): void => {
		this.sid = sid;
		this.updateExtraMetadata();
	};

	updateExtraMetadata = (): void => {
		const metadata = {
			...this.patientMetadata,
			...this.providerMetadata,
			...this.analyticsTrackMetadata,
			...(this.sid && { sid: this.sid }), // session id, read setSID() for more info
		} as const satisfies CustomAnalyticMetadata;

		this.extraMetadata = metadata;
	};

	timeEvent = (eventName: string): void => {
		mixpanel.time_event(eventName);
	};
}

let maybeAnalytics: Analytics | null = null;

function createAnalyticsAndInitMixpanel(): Analytics {
	const [firstHost = null] = getCurrentConfiguration().MixpanelHost;
	mixpanel.init(getCurrentConfiguration().MixpanelKey, {
		debug: IS_PRODUCTION,
		ignore_dnt: true,
		...(firstHost && { api_host: removeTrailingSlash(firstHost) }),
	});

	return new Analytics();
}

export function getOrCreateAnalytics(): Analytics {
	if (!maybeAnalytics) {
		maybeAnalytics = createAnalyticsAndInitMixpanel();
	}
	return maybeAnalytics;
}
