import type { Department, InsuranceGroup, Provider } from './Types';
import type { NavinaDataSourceId, NavinaGroupPracticeId } from '@navina/api-types';

function compareOptionsByLabel<T extends { readonly label: string }>(a: T, b: T): -1 | 0 | 1 {
	if (a.label < b.label) {
		return -1;
	}
	if (a.label > b.label) {
		return 1;
	}
	return 0;
}

// TODO: Change the reply from the server to have the same property name for ClinicId and DataSourceId (filterEntitiesByClinicId and filterEntitiesByDataSourceId are the same)
export function getEntitiesByClinicId<T extends { readonly clinicId: NavinaGroupPracticeId }>(
	entities: ReadonlyArray<T>,
	clinicId: NavinaGroupPracticeId,
) {
	return entities.filter((entity) => entity.clinicId === clinicId);
}

export function getEntitiesByDataSourceId<T extends { readonly dataSourceId: NavinaGroupPracticeId }>(
	entities: ReadonlyArray<T>,
	dataSourceId: NavinaDataSourceId,
) {
	return entities.filter((entity) => entity.dataSourceId === dataSourceId);
}

export function getSelectOptionsFromDepartmentOptions(...departmentOptions: ReadonlyArray<Department>) {
	return departmentOptions
		.map((department) => ({
			label: department.name,
			value: department.id,
			dataSourceId: department.clinicId,
		}))
		.sort(compareOptionsByLabel);
}

export function getSelectOptionsFromProviderOptions(...providerOptions: ReadonlyArray<Provider>) {
	return providerOptions
		.map((provider) => ({
			label: `${provider.firstName} ${provider.lastName}`,
			value: provider.id,
		}))
		.sort(compareOptionsByLabel);
}

export function getSelectOptionsFromInsuranceOptions(...insuranceOptions: ReadonlyArray<InsuranceGroup>) {
	return insuranceOptions
		.map((insuranceGroup) => ({
			label: insuranceGroup.name,
			value: insuranceGroup.id,
		}))
		.sort(compareOptionsByLabel);
}
