import { TWO_TONE_ICON_SETTINGS } from '../../../theme/theme';
import { BrowserSupport } from '../../BrowserSupport/BrowserSupport';
import { BrowserSupportModel, type IBrowserSupportModel } from '../../BrowserSupport/BrowserSupportModel';
import { ErrorPage } from '../ErrorPage';
import { ErrorMessage, ErrorMessageContainer, ErrorTitle } from '../ErrorPage.styled';
import { FrownTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ReactNode, ComponentProps } from 'react';

interface SomethingWentWrongProps extends Omit<ComponentProps<typeof ErrorPage>, 'children'> {
	readonly browserSupportModel?: IBrowserSupportModel;
	readonly action?: {
		readonly name: string;
		readonly onAction: VoidFunction;
	};
	readonly children?: ReactNode;
}

export const SomethingWentWrong = observer(function SomethingWentWrong({
	browserSupportModel = BrowserSupportModel.create({
		userAgent: navigator.userAgent,
	}),
	action,
	children,
	...props
}: SomethingWentWrongProps) {
	const { isBrowserSupported: isUserBrowserSupported } = browserSupportModel;

	return (
		<ErrorPage
			{...{
				icon: <FrownTwoTone {...TWO_TONE_ICON_SETTINGS} />,
				...props,
			}}
		>
			<ErrorMessageContainer>
				<div>
					<ErrorTitle>Something went wrong</ErrorTitle>
					<ErrorMessage>Please try again later or contact support if the issue persists.</ErrorMessage>
				</div>
				{action && (
					<Button
						{...{
							type: 'primary',
							onClick: action.onAction,
						}}
					>
						{action.name}
					</Button>
				)}
				<div>{children}</div>
				{!isUserBrowserSupported && <BrowserSupport {...{ browserSupportModel }} />}
			</ErrorMessageContainer>
		</ErrorPage>
	);
});
