import type { AppointmentRowModel, IAppointmentRowModel } from '../models/AppointmentRowModel';
import { EntitiesFetchingStatus } from '../models/types';
import {
	CustomizedLoadingTextContainer,
	CustomizedTextContainer,
	CustomizedTooltipTextContainer,
	EllipsisTextContainer,
	ResultsContainer,
	SmallSecondaryText,
	TableContainer,
	TimeColumnTextContainer,
} from './AppointmentResultsList.styled';
import { EntitiesStatusMessage } from './EntitiesStatusMessage';
import styled from '@emotion/styled/macro';
import { Skeleton, Table, Tooltip } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { type CellType } from 'rc-table/lib/interface';
import { useRef, useEffect, type ReactNode, type ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';

type TableProps = ComponentProps<typeof Table<IAppointmentRowModel>>;
type AppointmentColumn = ColumnType<IAppointmentRowModel>;
type AppointmentColumns = ColumnsType<IAppointmentRowModel>;

export const DATE_COLUMN = {
	title: 'Date',
	dataIndex: 'formattedDate',
	key: 'formattedDate',
	width: 60,
} as const satisfies AppointmentColumn;

export const TIME_COLUMN = {
	title: 'Time',
	dataIndex: 'formattedStartTime',
	key: 'formattedStartTime',
	render: function renderTime(formattedStartTime: string, row: AppointmentRowModel): ReactNode {
		const { timezoneFriendlyName } = row;
		return (
			<TimeColumnTextContainer>
				<EllipsisTextContainer>{formattedStartTime}</EllipsisTextContainer>
				<EllipsisTextContainer>
					<SmallSecondaryText>{timezoneFriendlyName}</SmallSecondaryText>
				</EllipsisTextContainer>
			</TimeColumnTextContainer>
		);
	},
	width: 60,
} as const satisfies AppointmentColumn;

const NAME_COLUMN = {
	title: 'Name',
	dataIndex: 'patientFullName',
	key: 'patientFullName',
	width: 90,
} as const satisfies AppointmentColumn;

export const GAPS_COLUMN = {
	title: 'HCC Gaps',
	dataIndex: 'gapsCount',
	key: 'gapsCount',
	width: 50,
} as const satisfies AppointmentColumn;

export const INSURANCE_COLUMN = {
	title: 'Insurance',
	dataIndex: 'insurance',
	key: 'insurance',
	width: 90,
} as const satisfies AppointmentColumn;

const PATIENT_ID_COLUMN = {
	title: 'Patient ID',
	dataIndex: 'emrPatientId',
	key: 'emrPatientId',
	width: 60,
} as const satisfies AppointmentColumn;

export const VISIT_REASON_COLUMN = {
	title: 'Visit Reason',
	dataIndex: 'visitReason',
	key: 'visitReason',
	width: 90,
} as const satisfies AppointmentColumn;

export const LAST_SCRUB_COLUMN = {
	title: 'Last Scrub',
	dataIndex: 'lastScrubFormattedDate',
	key: 'lastScrubFormattedDate',
	width: 50,
} as const satisfies AppointmentColumn;

export const LAST_VISIT_COLUMN = {
	title: 'Last Visit',
	dataIndex: 'lastOfficeVisitFormattedDate',
	key: 'lastOfficeVisitFormattedDate',
	width: 50,
} as const satisfies AppointmentColumn;

export const PROVIDER_COLUMN = {
	title: 'Provider',
	dataIndex: 'providerFullName',
	key: 'providerFullName',
	width: 80,
} as const satisfies AppointmentColumn;

const DEFAULT_COLUMNS = [
	TIME_COLUMN,
	NAME_COLUMN,
	GAPS_COLUMN,
	INSURANCE_COLUMN,
	PATIENT_ID_COLUMN,
	VISIT_REASON_COLUMN,
	LAST_SCRUB_COLUMN,
	LAST_VISIT_COLUMN,
	PROVIDER_COLUMN,
] as const satisfies AppointmentColumns;

const loadingItems: ReadonlyArray<IAppointmentRowModel> = Array.from({ length: 20 }).map(
	function createLoadingItem(_, index) {
		return {
			id: `item-${index}`,
			patientFullName: '',
			providerFullName: '',
			formattedStartTime: '',
			emrPatientId: '',
			visitReason: '',
			lastScrubFormattedDate: '',
			lastOfficeVisitFormattedDate: '',
			insurance: '',
			gapsCount: '',
			formattedDate: '',
			timezoneFriendlyName: '',
		} as const satisfies IAppointmentRowModel;
	},
);

const TopAlignedTableCell = styled.td`
	vertical-align: top;
`;

function TableCellLoading<RecordType>(props: CellType<RecordType>): ReactNode {
	return (
		<TopAlignedTableCell {...{ ...props, title: undefined }}>
			<CustomizedLoadingTextContainer>
				<Skeleton.Input />
			</CustomizedLoadingTextContainer>
		</TopAlignedTableCell>
	);
}

function TableCell<RecordType>({ children, ...props }: CellType<RecordType>): ReactNode {
	return (
		<TopAlignedTableCell {...{ ...props, title: undefined, style: { ...props.style, padding: '0' } }}>
			<Tooltip
				{...{
					title: <CustomizedTooltipTextContainer>{children}</CustomizedTooltipTextContainer>,
					overlayStyle: { pointerEvents: 'none' },
					mouseEnterDelay: 0.4,
				}}
			>
				<CustomizedTextContainer>{children}</CustomizedTextContainer>
			</Tooltip>
		</TopAlignedTableCell>
	);
}

interface AppointmentResultsListProps extends Omit<ComponentProps<typeof ResultsContainer>, 'children'> {
	readonly entityModels: ReadonlyArray<IAppointmentRowModel>;
	readonly entitiesFetchingStatus: EntitiesFetchingStatus;
	readonly columns?: ColumnsType<IAppointmentRowModel>;
}

export const AppointmentResultsList = observer(function AppointmentResultsList({
	entityModels,
	entitiesFetchingStatus,
	columns = DEFAULT_COLUMNS,
	...props
}: AppointmentResultsListProps) {
	const history = useHistory();
	const resultsContainerRef = useRef<HTMLDivElement>(null);

	useEffect(
		function scrollToTopOnFetch(): void {
			if (entitiesFetchingStatus === EntitiesFetchingStatus.Pending) {
				const resultsContainer = resultsContainerRef.current;
				if (resultsContainer && resultsContainer.scrollTop !== 0) {
					resultsContainer.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		},
		[entitiesFetchingStatus],
	);

	const hasResults = entityModels.length > 0;

	return (
		<ResultsContainer {...props}>
			<TableContainer
				{...{
					ref: resultsContainerRef,
				}}
			>
				<Table<IAppointmentRowModel>
					{...{
						components: {
							body: {
								cell:
									entitiesFetchingStatus === EntitiesFetchingStatus.Pending
										? TableCellLoading<IAppointmentRowModel>
										: TableCell<IAppointmentRowModel>,
							},
						},
						rowClassName: 'clickable',
						columns,
						rowKey: 'id',
						pagination: false,
						dataSource: entitiesFetchingStatus === EntitiesFetchingStatus.Pending ? loadingItems : entityModels,
						sticky: true,
						locale: {
							emptyText: null,
						},
						onRow: function extendRowProps(record): ReturnType<NonNullable<TableProps['onRow']>> {
							return {
								onClick: function moveToDetailsPage(): void {
									history.push(`/${record.id}`);
								},
							} as const;
						},
					}}
				/>
				<EntitiesStatusMessage
					{...{
						hidden: hasResults,
						entitiesFetchingStatus,
					}}
				/>
			</TableContainer>
		</ResultsContainer>
	);
});
