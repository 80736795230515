import { theme } from '../../theme/theme';
import {
	AppointmentResultsList,
	DATE_COLUMN,
	GAPS_COLUMN,
	INSURANCE_COLUMN,
	LAST_SCRUB_COLUMN,
	LAST_VISIT_COLUMN,
	PROVIDER_COLUMN,
	TIME_COLUMN,
	VISIT_REASON_COLUMN,
} from '../AppointmentsSchedule/AppointmentsResultsList/AppointmentResultsList';
import {
	AppointmentsTableContainer,
	PaginationContainer,
	TotalResultsContainer,
} from '../AppointmentsSchedule/AppointmentsSchedule.styled';
import { PaginationController } from '../AppointmentsSchedule/Pagination/PaginationController';
import { TotalResults } from '../AppointmentsSchedule/Pagination/TotalResults';
import { CardContainer } from '../AppointmentsSchedule/assets/CardContainer';
import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import { PatientLookupBreadcrumb } from './PatientLookupBreadcrumb';
import { PatientLookupDemographics } from './PatientLookupDemographics';
import { ENTITIES_PER_PAGE_OPTIONS, type IPatientLookupStore } from './PatientLookupStore';
import { PatientLookupTitle } from './PatientLookupTitle';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { type ComponentProps } from 'react';

const PatientLookupContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${theme.paddings.sm};
`;

interface PatientLookupProps extends Omit<ComponentProps<typeof PatientLookupContainer>, 'children'> {
	readonly patientLookupStore: IPatientLookupStore;
}

export const PatientLookup = observer(function PatientLookup({ patientLookupStore, ...props }: PatientLookupProps) {
	const {
		patientLookupDemographicsFetchingStatus: patientLookupFetchingStatus,
		patientLookupAppointmentsFetchingStatus,

		patientTitle,
		formattedDataOfBirthWithAge: dateOfBirthText,
		formattedEmrPatientId: patientEmrIdText,
		formattedGender: genderText,

		patientAppointmentsRowModels,

		totalAppointmentRows,
		pageNumber,
		appointmentRowsPerPage,

		setPageNumberAndFetchAppointments: setPageNumberAndFetchAppointmentsRows,
		setPageNumberAndEntitiesPerPage,
	} = patientLookupStore;

	return (
		<PatientLookupContainer {...props}>
			<TotalResultsContainer>
				<PatientLookupBreadcrumb
					{...{
						fetchingStatus: patientLookupFetchingStatus,
						patientTitle,
					}}
				/>
			</TotalResultsContainer>
			<PatientLookupTitle
				{...{
					fetchingStatus: patientLookupFetchingStatus,
				}}
			>
				{patientTitle}
			</PatientLookupTitle>
			<CardContainer>
				<PatientLookupDemographics
					{...{
						fetchingStatus: patientLookupFetchingStatus,
						dateOfBirthText,
						genderText,
						patientEmrIdText,
					}}
				/>
			</CardContainer>
			<TotalResultsContainer>
				<TotalResults
					{...{
						pageNumber,
						rowsPerPage: appointmentRowsPerPage,
						totalRows: totalAppointmentRows,
						fetchingStatus: patientLookupFetchingStatus,
						entityName: 'appointments',
					}}
				/>
			</TotalResultsContainer>

			<AppointmentsTableContainer>
				<AppointmentResultsList
					{...{
						columns: [
							DATE_COLUMN,
							TIME_COLUMN,
							GAPS_COLUMN,
							INSURANCE_COLUMN,
							VISIT_REASON_COLUMN,
							LAST_SCRUB_COLUMN,
							LAST_VISIT_COLUMN,
							PROVIDER_COLUMN,
						],
						entityModels: patientAppointmentsRowModels,
						entitiesFetchingStatus: patientLookupAppointmentsFetchingStatus,
						amountOfLoadingItems: appointmentRowsPerPage,
					}}
				/>
			</AppointmentsTableContainer>
			<PaginationContainer>
				<PaginationController
					{...{
						disabled: !(
							patientLookupAppointmentsFetchingStatus === EntitiesFetchingStatus.Success &&
							patientAppointmentsRowModels.length > 0
						),
						pageSizeOptions: ENTITIES_PER_PAGE_OPTIONS,
						showSizeChanger: true,
						current: pageNumber,
						pageSize: appointmentRowsPerPage,
						onChange: setPageNumberAndFetchAppointmentsRows,
						onShowSizeChange: function setPageNumberAndEntitiesPerPageSize(current: number, size: number): void {
							setPageNumberAndEntitiesPerPage({ newPageNumber: current, newEntitiesPerPage: size });
						},
						total: totalAppointmentRows,
					}}
				/>
			</PaginationContainer>
		</PatientLookupContainer>
	);
});
