import { type AuthStore, useAuthStore } from '../../stores/authStore';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';

type PermissionType =
	| 'hcc_dashboard'
	| 'quality_tool'
	| 'files_manager'
	| 'debug_panel'
	| 'view_only'
	| 'quality_files_ingestion_tool'
	| 'admin_tool';

interface HasPermissionProps {
	readonly to: PermissionType;
	readonly children: ReactNode;
	readonly allowAlsoToAdmin?: boolean;
	readonly fallback?: ReactNode;
}

function handlePotentialStringBoolean(value: string | boolean): boolean {
	if (typeof value === 'string') {
		return value.toLowerCase() === 'true';
	}
	return value;
}

export function checkIfHasPermission(authStore: AuthStore, to: PermissionType, allowAlsoToAdmin = true): boolean {
	return handlePotentialStringBoolean(authStore.permissions[to]) || (allowAlsoToAdmin && authStore.isAdmin);
}

// By default, we allow admins to view all components (allowAlsoToAdmin = true)
export const HasPermission = observer(function HasPermission({
	to,
	allowAlsoToAdmin = true,
	children,
	fallback,
}: HasPermissionProps) {
	const authStore = useAuthStore();
	return checkIfHasPermission(authStore, to, allowAlsoToAdmin) ? children : fallback;
});
