import type { GetClinicsDepartmentsProvidersResponsePayload } from '../components/AppointmentsSchedule/models/types';
import { IS_PRODUCTION } from '../config/Environment';
import type { NavinaConfiguration } from '../config/NavinaConfiguration';
import { getCurrentConfiguration, configurations } from '../config/config';
import type { ExpressPushResult } from '../modules/push2emr/push2emr-express/types';
import type { DiagnosesPushRequest, DiagnosesPushRequestEpic } from '../modules/push2emr/types';
import type { Action } from '../pages/summary/med-features/push-to-emr/types';
import { type AuthStore, getOrCreateAuthStore } from '../stores/authStore';
import type {
	DiagnosisWithActionsOfUserArray,
	emrNames,
	EmrOnlineSyncResponse,
	GetAppointmentDetailsResponse,
	QuicksightEmbedSettings,
	ScheduleFilter,
	SuggestedDiagnosesArray,
	SummaryActionsResponse,
} from '../types';
import { getOrCreateAnalytics } from '../utils/analytics';
import { NEXT_URL_QUERY_PARAMS, UNITY_ENCOUNTER_ID } from '../utils/consts';
import { getOrCreateApiSessionsServer } from './apiSessions';
import { createAxiosInstance, getErrorHandler, getSuccessHandler, type WithAxiosServer } from './createAxiosInstance';
import type { CorePushResult, GetReadyPostVisitsResponse, GetSummaryNotesResponse } from '@dxcapture/core';
import type {
	AdjacentAppointmentsRequestPayload,
	AdjacentAppointmentsResponsePayload,
	PatientDemographicsRequestPayload,
	PatientDemographicsResponsePayload,
	PatientLookupRequestPayload,
	PatientLookupResponsePayload,
	PatientSelectorRequestPayload,
	PatientSelectorResponsePayload,
	SchedulePageSearchRequestPayload,
	SchedulePageSearchResponsePayload,
	SelectedGroupPracticeRequestPayload,
	SelectedGroupPracticeResponsePayload,
	UserMetadataResponsePayload,
	UserMetadataRequestPayload,
} from '@navina/api-types';
import Axios, {
	type AxiosInstance,
	type AxiosRequestConfig,
	type AxiosResponse,
	type CancelToken,
	type CancelTokenSource,
} from 'axios';
import camelcaseKeys from 'camelcase-keys';
import jwtDecode from 'jwt-decode';

const CancellationTokenCreator = Axios.CancelToken;

const QA_BRANCH = (process.env.REACT_APP_AWS_BRANCH || '').startsWith('qa/') ? process.env.REACT_APP_AWS_BRANCH : '';

const TOKEN_EXPIRATION_BUFFER_DURATION_SECONDS = 60;

const WITH_QA_BRANCH_AND_MAYBE_CACHE = { qaBranch: QA_BRANCH, useCache: IS_PRODUCTION } as const;

interface AuthHeaders {
	token?: string;
	authorizationToken?: string;
	type?: string;
}

interface AuthInfo {
	headers?: AuthHeaders;
	path: string;
	isValid: boolean;
	isRefresh?: boolean;
}

export class ApiGatewayServer implements WithAxiosServer {
	readonly server: AxiosInstance;
	readonly localServer: AxiosInstance;
	readonly authStore: AuthStore;
	private readonly config: NavinaConfiguration;

	constructor() {
		this.config = getCurrentConfiguration();
		this.server = createAxiosInstance(this.config.ApiUrl);
		this.localServer = createAxiosInstance(configurations.local.ApiUrl);
		this.authStore = getOrCreateAuthStore();
	}

	protected readonly getAuthInfo = (path: string, token: string): AuthInfo => {
		if (!token) {
			return { path, isValid: false };
		}

		if (this.authStore.getIsSAML) {
			const tokenPayload = jwtDecode<{ exp: number }>(token);
			const tokenExpirationUnixTimestamp = tokenPayload.exp;

			const tokenExpirationTimeInMilliseconds = tokenExpirationUnixTimestamp * 1_000;
			const bufferDurationInMilliseconds = TOKEN_EXPIRATION_BUFFER_DURATION_SECONDS * 1_000;

			const currentTimeInMilliseconds = Date.now();

			const isTokenExpired =
				tokenExpirationTimeInMilliseconds - bufferDurationInMilliseconds < currentTimeInMilliseconds;

			if (isTokenExpired) {
				console.log('Token about to expire, redirecting to Auth0 to get new token', {
					path,
					currentUrl: window.location.href,
				});
				// We need double encoding since it is decoded twice before used in the url
				const nextUrl = encodeURIComponent(encodeURIComponent(`${window.location.pathname}${window.location.search}`));

				console.log('Redirecting to Auth0 to get new token', { nextUrl, inputPath: path });

				window.location.href = this.config.NavinaAuth0AuthURL(
					`${this.config.Auth0LoginRedirect}?${NEXT_URL_QUERY_PARAMS}=${nextUrl}`,
				);

				return { path, isRefresh: true, isValid: false };
			}

			return {
				headers: {
					authorizationToken: `Bearer ${token}`,
					type: 'TOKEN',
				},
				path: `v2/${path}`,
				isValid: true,
			};
		}

		return { headers: { token }, path, isValid: true };
	};

	protected readonly camelCaseResponse = <T extends { [key: string]: any }>(jsonObject: T): T => {
		return camelcaseKeys<T>(jsonObject, {
			deep: true,
			exclude: [/.* .*/, /.*-.*-.*-.*/],
		});
	};

	protected readonly baseRequest = async <TInput extends { [key: string]: any }, TOutput>(
		path: string,
		data?: TInput,
		cancelToken?: CancelTokenSource,
		isLocal = false,
	): Promise<null | AxiosResponse<TOutput>> => {
		const authInfo = this.getAuthInfo(path, this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			const axiosRequestConfig: AxiosRequestConfig = {
				headers: {
					'content-type': 'application/json',
					...authInfo.headers,
				},
				...(cancelToken && { cancelToken: cancelToken.token }),
			};

			const requestPromise: Promise<AxiosResponse<TOutput>> = (isLocal ? this.localServer : this.server).post(
				authInfo.path,
				JSON.stringify(data),
				axiosRequestConfig,
			);

			const awaitedResponse = await requestPromise;

			awaitedResponse.data = this.camelCaseResponse<TOutput>(awaitedResponse.data);

			return Promise.resolve(awaitedResponse);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly getFileURL = async (fileId: string, overrideEnv?: string): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getFileURL', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ id: fileId, overrideEnv }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly downloadFile = Axios.get;

	readonly getClinicsDepartmentsProviders =
		async (): Promise<null | AxiosResponse<GetClinicsDepartmentsProvidersResponsePayload>> => {
			const authInfo = this.getAuthInfo('getClinicsDepartmentsProviders', this.authStore.getToken);
			if (authInfo.isRefresh) {
				return Promise.resolve(null);
			}

			if (authInfo.isValid) {
				return this.server.post(
					authInfo.path,
					{},
					{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
				);
			}
		};

	readonly getUserClinicsHierarchy = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getUserClinicsHierarchy', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{},
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}
	};

	getSummariesListScheduleCancelToken: CancelTokenSource;

	readonly getSummariesListSchedule = async (
		date: string,
		scheduleFilter: ScheduleFilter,
		patientLookup?: string,
	): ReturnType<typeof this._getSummariesList> => {
		if (this.getSummariesListScheduleCancelToken) {
			this.getSummariesListScheduleCancelToken.cancel('NewerRequestArrived');
		}

		this.getSummariesListScheduleCancelToken = CancellationTokenCreator.source();

		return this._getSummariesList(date, scheduleFilter, this.getSummariesListScheduleCancelToken.token, patientLookup);
	};

	getSummariesListCurrentDayCancelToken: CancelTokenSource;

	readonly getSummariesListCurrentDay = async (
		date: string,
		scheduleFilter: ScheduleFilter,
		patientLookup?: string,
	): ReturnType<typeof this._getSummariesList> => {
		if (this.getSummariesListCurrentDayCancelToken) {
			this.getSummariesListCurrentDayCancelToken.cancel('NewerRequestArrived');
		}

		this.getSummariesListCurrentDayCancelToken = CancellationTokenCreator.source();

		return this._getSummariesList(
			date,
			scheduleFilter,
			this.getSummariesListCurrentDayCancelToken.token,
			patientLookup,
		);
	};

	readonly _getSummariesList = async (
		isoDate: string,
		scheduleFilter: ScheduleFilter,
		cancelToken: CancelToken,
		patientLookup?: string,
	): Promise<null | AxiosResponse> => {
		const params = { isoDate, ...scheduleFilter, patientLookup } as const;
		const analytics = getOrCreateAnalytics();

		const summaries = this.getSummariesFromSessionStorage(JSON.stringify(params));

		if (summaries) {
			analytics.track(analytics.idsNames.SummariesListCacheChecked, { hit: true });
			return Promise.resolve(summaries);
		}

		analytics.track(analytics.idsNames.SummariesListCacheChecked, { hit: false });

		const authInfo = this.getAuthInfo('getPermittedZones', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			try {
				const response = await this.server.post(authInfo.path, JSON.stringify({ ...params, qaBranch: QA_BRANCH }), {
					headers: { 'content-type': 'application/json', ...authInfo.headers },
					cancelToken: cancelToken,
				});

				this.tryStoringSummariesToSessionStorage(JSON.stringify(params), response);

				return response;
			} catch (err) {
				if (err.message !== 'NewerRequestArrived') {
					throw err.innerError;
				}
			}
		}

		console.warn('_getSummariesList, token is null, resetting authStore');
		return Promise.resolve(null);
	};

	readonly getDxCaptureData = async (summaryId: string) => {
		const authInfo = this.getAuthInfo('getDxCaptureData', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve({ refresh: true });
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				JSON.stringify({
					...WITH_QA_BRANCH_AND_MAYBE_CACHE,
					sid: summaryId,
				}),
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}

		console.warn('getDxCaptureData, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly getAppointmentDetails = async (summaryId: string) => {
		const authInfo = this.getAuthInfo('getAppointmentDetails', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve({ refresh: true });
		}

		if (authInfo.isValid) {
			return this.server.post<GetAppointmentDetailsResponse>(
				authInfo.path,
				JSON.stringify({ ...WITH_QA_BRANCH_AND_MAYBE_CACHE, sid: summaryId }),
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}

		console.warn('getAppointmentDetails, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly getSummariesFromSessionStorage = (filter: string) => {
		const summariesListValue = sessionStorage.getItem('summaries_list');

		if (summariesListValue) {
			const summariesList = JSON.parse(summariesListValue);

			if (summariesList.filter === filter && new Date(summariesList.ttl) > new Date()) {
				return summariesList.summaries;
			}
		}
	};

	readonly tryStoringSummariesToSessionStorage = (filter: string, summaries: unknown): void => {
		try {
			const oneHourInMilliseconds = 60 * 60 * 1000;
			const now = Date.now();
			const ttl = new Date(now + oneHourInMilliseconds);
			const value = { ttl, filter, summaries };
			sessionStorage.setItem('summaries_list', JSON.stringify(value));
		} catch (error) {
			sessionStorage.removeItem('summaries_list');
			console.error('Error storing summaries to sessionStorage', error);
		}
	};

	readonly getSummaryNotificationsBySummaryId = async (summaryId: string): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getSummaryNotifications', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ id: summaryId }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('GetSummaryNotifications, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly getUserMetadataV2 = async (
		idToken: string,
		dataSourceId: string,
		emrEncounterId?: string,
		unityPatientId?: string,
	): Promise<AxiosResponse<UserMetadataResponsePayload>> => {
		return Axios.post<UserMetadataResponsePayload>(
			`${this.config.ApiUrl}v2/getUserMetadata`,
			{ emrEncounterId, dataSourceId, unityPatientId } as const satisfies UserMetadataRequestPayload,
			{
				headers: {
					authorizationToken: `Bearer ${idToken}`,
					type: 'TOKEN',
					'content-type': 'application/json',
				},
			},
		);
	};

	readonly getEpicTokenAndAppContext = async (input: {
		code: string;
		connectionName: string;
		dataSourceId: string;
		redirectUri?: string;
	}): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getEpicTokenAndAppContext', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify(input), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		console.warn('GetSummaryNotifications, token is null, resetting authStore');
		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly sendFeedback = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('submitFeedback', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	searchInOpenSearchCancelToken: CancelTokenSource;

	readonly searchInDocs = async (
		searchTerm: string,
		NID: string,
		dataSourceId: number,
	): Promise<null | AxiosResponse> => {
		this.searchInOpenSearchCancelToken = CancellationTokenCreator.source();

		const authInfo = this.getAuthInfo('searchInOpenSearch', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{ searchTerm, NID, dataSourceId },
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
					cancelToken: this.searchInOpenSearchCancelToken.token,
				},
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly sendSummaryBugReport = async (report: {
		sid: string;
		feature: string;
		priority?: string;
		shortDescription: string;
		details?: string;
	}): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('submitBugReport', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post<{ result: { succeed: boolean; itemUrl?: string; message?: string } }>(
				authInfo.path,
				report,
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly pushToEmrPmdToPl = async (extraData: any) => {
		const authInfo = this.getAuthInfo('pushToEmrPmdToPl', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		try {
			if (authInfo.isValid) {
				const res = await this.server.post<ExpressPushResult[]>(authInfo.path, extraData, {
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				});

				return res.data;
			}
		} catch (err) {
			console.error('An Error occurred in push sockets', err);
			return Promise.resolve(null);
		}

		this.authStore.reset();
		console.log('!!! should push : ', extraData);
		return Promise.resolve(null);
	};

	readonly getEncounterStatus = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getEncounterStatus', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly updateSummaryInsightStatus = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('updateSummaryInsightStatus', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, extraData, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly updateUserConfig = async (extraData: unknown): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('updateUserConfig', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(
				authInfo.path,
				{ config: extraData },
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly getUserConfig = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getUserConfig', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, null, {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.resolve(null);
	};

	readonly addonHealthPing = async (): Promise<AxiosResponse> => {
		return this.server.post('addonHealthPing', {}, { headers: { 'content-type': 'application/json' } });
	};

	readonly passwordResetStartedReport = async (username: string, is180DaysReset = false): Promise<AxiosResponse> => {
		return this.server.post(
			'userResetPasswordAttempt',
			{ username, is180DaysReset },
			{ headers: { 'content-type': 'application/json' } },
		);
	};

	readonly getICDData = async (searchText = '') => {
		const authInfo = this.getAuthInfo('getICDData', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getICDData, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		console.log(`search: server search with prefix ${searchText}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ searchText }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	readonly getNoteTemplates = async (dataSource: number) => {
		const authInfo = this.getAuthInfo('getNoteTemplates', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getNoteTemplates, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ dataSource }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	readonly getSnomedFromICD = async (icdCode = '') => {
		const authInfo = this.getAuthInfo('getSnomedFromICD', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getSnomedFromICD, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([]);
		}

		console.log(`getSnomedToICD for ICD code ${icdCode}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ icdCode }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return [];
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return [];
		}
	};

	readonly getOnlineSyncIcds = async (
		sid: string,
		emrName: emrNames,
	): Promise<null | string[] | EmrOnlineSyncResponse> => {
		switch (emrName) {
			case 'epic':
				return this.getICDsFromFhirApiBySID(sid);
			case 'veradigm':
				return this.getICDsFromUnityApiBySID(sid);
			default:
				return Promise.resolve(null);
		}
	};

	readonly getICDsFromFhirApiBySID = async (sid: string): Promise<null | string[] | EmrOnlineSyncResponse> => {
		const authInfo = this.getAuthInfo('getEpicICDFromSID', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getEpicICDFromSID, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		console.log(`getEpicICDFromSID for SID ${sid}`);
		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid }), {
				headers: {
					'content-type': 'application/json',
					authorizationToken: `Bearer ${authInfo.headers.token}`,
					type: 'TOKEN',
					...authInfo.headers,
				},
			});

			if (!response || !response?.data?.message) {
				console.warn('An Error occurred', response);
				return null;
			}

			return response.data.message;
		} catch (err) {
			console.warn('An Error occurred', err);
			return null;
		}
	};

	readonly getICDsFromUnityApiBySID = async (sid: string): Promise<null | string[] | EmrOnlineSyncResponse> => {
		const authInfo = this.getAuthInfo('getICDsFromUnityApi', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getICDsFromUnityApi, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}
		const unityEncounterId = localStorage.getItem(UNITY_ENCOUNTER_ID);
		if (!unityEncounterId) {
			console.warn(`missing unityEncounterId skipping getICDsFromUnityApi call for SID ${sid}`);
			return null;
		}
		console.log(`getICDsFromUnityApi for SID ${sid}, unityEncounterId ${unityEncounterId}`);
		try {
			const response = await this.server.post(
				authInfo.path,
				JSON.stringify({ sid, unity_encounter_id: unityEncounterId }),
				{
					headers: {
						'content-type': 'application/json',
						authorizationToken: `Bearer ${authInfo.headers.token}`,
						type: 'TOKEN',
						...authInfo.headers,
					},
				},
			);

			if (!response || !response?.data?.message) {
				console.warn('Error:', response);
				return null;
			}

			return response.data.message;
		} catch (err) {
			console.warn('Error:', err);
			return null;
		}
	};

	readonly addSummaryNote = async (sid: string, note: string) => {
		const authInfo = this.getAuthInfo('addSummaryNote', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryNote, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		const response = await this.server.post<unknown[]>(authInfo.path, JSON.stringify({ sid, note }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	readonly getSummaryNotes = async (sid: string): Promise<GetSummaryNotesResponse | null> => {
		const authInfo = this.getAuthInfo('getSummaryNotes', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getSummaryNotes, token is null, resetting authStore');
			this.authStore.reset();
			Promise.resolve(null);
		}

		const response = await this.server.post<GetSummaryNotesResponse>(authInfo.path, JSON.stringify({ sid }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	readonly apiPush = async (
		payload: DiagnosesPushRequest | DiagnosesPushRequestEpic,
	): Promise<CorePushResult[] | null> => {
		const authInfo = this.getAuthInfo(`push2emrApi`, this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('apiPush, token is null, resetting authStore');
			this.authStore.reset();
			Promise.resolve(null);
		}

		const response = await this.server.post<CorePushResult[]>(authInfo.path, JSON.stringify({ ...payload }), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});

		return response.data;
	};

	readonly addSummaryInsightActions = async (
		nid: string,
		sid: string,
		actions: Action[],
		shouldScrub: boolean,
		isPostVisit = false,
		postVisitUpdatedIcds: string[] | undefined = undefined,
		suggestionsWithActions?: DiagnosisWithActionsOfUserArray,
		shouldRefetchSuggestions: boolean = false,
		isSummaryAction: boolean = true,
	): Promise<SummaryActionsResponse | null> => {
		const authInfo = this.getAuthInfo('addSummaryInsightActions', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryInsightActions, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		console.log(`addSummaryInsightActions for sid code ${sid}`, { actions, shouldScrub });

		try {
			const response = await this.server.post<
				SummaryActionsResponse | SuggestedDiagnosesArray /** For Rollout then remove it */ | null
			>(
				authInfo.path,
				JSON.stringify({
					nid,
					sid,
					shouldScrub,
					actions,
					isPostVisit,
					postVisitUpdatedIcds,
					suggestionsWithActions,
					shouldRefetchSuggestions,
					isSummaryAction,
				}),
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				throw Error(response.statusText);
			}
			/** For Rollout then remove it */
			// return response.data;
			return Array.isArray(response.data)
				? {
						failures: [],
						upsertedSuggestions: response.data,
					}
				: response.data;
		} catch (err) {
			console.warn('An Error occurred', err);
			throw err;
		}
	};

	readonly updatePostVisitViewed = async (
		sid: string,
		isDoneByProvider = false,
	): Promise<boolean | undefined | null | never[]> => {
		const authInfo = this.getAuthInfo('updatePostVisitViewed', this.authStore.getToken);

		if (authInfo.isRefresh) {
			Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updatePostVisitViewed, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([] as never[]);
		}

		console.log(`updatePostVisitViewed for sid code ${sid}`);
		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid, isDoneByProvider }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				return false;
			}

			return true;
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	readonly addSummaryInsightsAgilonNonDxActions = async (
		nid: string,
		sid: string,
		agilonSuggestionId: string,
		navinaSuggestionId: string,
		userSelection: string,
		updatedAt: string,
	): Promise<null | undefined> => {
		const authInfo = this.getAuthInfo('addSummaryInsightsAgilonNonDxActions', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('addSummaryInsightsAgilonNonDxActions, token is null, resetting authStore');
			this.authStore.reset();
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				{
					nid,
					sid,
					agilonSuggestionId,
					navinaSuggestionId,
					status: userSelection,
					updatedAt,
				},
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	readonly updateSummaryInsightsNote = async (
		nid: string,
		sid: string,
		snomed: string,
		icdCode: string,
		note: string,
		noteDate: string,
		shouldPush: boolean,
		isPostVisit: boolean,
		diagnosisUid?: string,
	): Promise<null | undefined> => {
		const authInfo = this.getAuthInfo('updateSummaryInsightsNotes', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updateSummaryInsightsNotes, token is null, resetting authStore');
			this.authStore.reset();
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				{
					nid: nid,
					sid: sid,
					id: icdCode,
					snomedCode: snomed,
					note: note,
					icdCode: icdCode,
					updatedAt: noteDate,
					noteDate: noteDate,
					shouldPush,
					isPostVisit,
					diagnosisUid,
				},
				{ headers: { 'content-type': 'application/json', ...authInfo.headers } },
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	getReadyPostVisitsCancelToken: CancelTokenSource;

	readonly getReadyPostVisits = async (): Promise<GetReadyPostVisitsResponse> => {
		if (this.getReadyPostVisitsCancelToken) {
			this.getReadyPostVisitsCancelToken.cancel('NewerRequestArrived');
		}

		this.getReadyPostVisitsCancelToken = CancellationTokenCreator.source();

		const authInfo = this.getAuthInfo('getReadyPostVisits', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getReadyPostVisits, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		try {
			const response = await this.server.post<GetReadyPostVisitsResponse>(authInfo.path, JSON.stringify({}), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
				cancelToken: this.getReadyPostVisitsCancelToken.token,
			});

			if (!response || !response?.data) {
				console.warn('An Error occurred', response);
				return await Promise.resolve(null);
			}

			return response.data;
		} catch (err) {
			console.warn('An Error occurred', err);
			return Promise.resolve(null);
		}
	};

	// noinspection JSUnusedGlobalSymbols - Used through analytics-frontend
	readonly getQuicksightEmbedURL = async (quicksightSettings: QuicksightEmbedSettings) => {
		const authInfo = this.getAuthInfo('getQuicksightEmbedUrl', this.authStore.getToken);
		if (authInfo.isRefresh) {
			return Promise.reject(Error('Needs Refresh'));
		}

		if (authInfo.isValid) {
			return this.server.post(authInfo.path, JSON.stringify({ ...quicksightSettings }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});
		}

		this.authStore.reset();
		return Promise.reject(Error('Not valid'));
	};

	readonly updateSummaryUpdateViewed = async (
		sid: string,
		summaryUpdateId: string,
		isRead: boolean,
	): Promise<null | boolean | never[]> => {
		const authInfo = this.getAuthInfo('updateSummaryUpdateViewed', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('updateSummaryUpdateViewed, token is null, resetting authStore');
			this.authStore.reset();

			return Promise.resolve([] as never[]);
		}

		console.log(`updateSummaryUpdateViewed for sid code ${sid}`);

		try {
			const response = await this.server.post(authInfo.path, JSON.stringify({ sid, summaryUpdateId, isRead }), {
				headers: { 'content-type': 'application/json', ...authInfo.headers },
			});

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				return false;
			}

			return true;
		} catch (err) {
			console.warn('An Error occurred', err);
		}
	};

	readonly submitInAppQaResults = async (
		docId: string,
		isTitleCorrect: boolean,
		taggingLoinc: string,
		description: string,
		level1: string,
		level2: string,
		level3: string,
		notes: string,
	): Promise<null | never[] | Error> => {
		const authInfo = this.getAuthInfo('submitInAppQaResults', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('submitInAppQaResults, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve([] as never[]);
		}

		try {
			const response = await this.server.post(
				authInfo.path,
				JSON.stringify({ docId, isTitleCorrect, taggingLoinc, description, level1, level2, level3, notes }),
				{
					headers: { 'content-type': 'application/json', ...authInfo.headers },
				},
			);

			if (!response || response.status !== 200) {
				console.warn('An Error occurred', response);
				throw Error(response.statusText);
			}
		} catch (err) {
			console.warn('An Error occurred', err);
			throw err;
		}
	};

	readonly getInAppQaLoincsHierarchy = async (): Promise<null | AxiosResponse> => {
		const authInfo = this.getAuthInfo('getInAppQaLoincsHierarchy', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getInAppQaLoincsHierarchy, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post(authInfo.path, JSON.stringify({}), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getScheduleAppointments = async (
		requestPayload: SchedulePageSearchRequestPayload,
	): Promise<null | AxiosResponse<SchedulePageSearchResponsePayload>> => {
		const authInfo = this.getAuthInfo('getScheduleAppointments', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getScheduleAppointments, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<SchedulePageSearchResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getAdjacentAppointments = async (
		requestPayload: AdjacentAppointmentsRequestPayload,
	): Promise<null | AxiosResponse<AdjacentAppointmentsResponsePayload>> => {
		const authInfo = this.getAuthInfo('getAdjacentAppointments', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getAdjacentAppointments, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<AdjacentAppointmentsResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getPatients = async (
		requestPayload: PatientSelectorRequestPayload,
	): Promise<null | AxiosResponse<PatientSelectorResponsePayload>> => {
		const authInfo = this.getAuthInfo('getPatients', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getScheduleAppointments, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<PatientSelectorResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getGroupPractices = async (
		requestPayload: SelectedGroupPracticeRequestPayload,
	): Promise<null | AxiosResponse<SelectedGroupPracticeResponsePayload>> => {
		const authInfo = this.getAuthInfo('getGroupPractices', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getGroupPractices, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<SelectedGroupPracticeResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getPatientLookup = async (
		requestPayload: PatientLookupRequestPayload,
	): Promise<null | AxiosResponse<PatientLookupResponsePayload>> => {
		const authInfo = this.getAuthInfo('getPatientLookup', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getPatientLookup, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<PatientLookupResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};

	readonly getPatientDemographics = async (
		requestPayload: PatientDemographicsRequestPayload,
	): Promise<null | AxiosResponse<PatientDemographicsResponsePayload>> => {
		const authInfo = this.getAuthInfo('getPatientDemographics', this.authStore.getToken);

		if (authInfo.isRefresh) {
			return Promise.resolve(null);
		}

		if (!authInfo.isValid) {
			console.warn('getPatientDemographics, token is null, resetting authStore');
			this.authStore.reset();
			return Promise.resolve(null);
		}

		return this.server.post<PatientDemographicsResponsePayload>(authInfo.path, JSON.stringify(requestPayload), {
			headers: { 'content-type': 'application/json', ...authInfo.headers },
		});
	};
}

let maybeApiGatewayServer: ApiGatewayServer | null = null;

function createApiGatewayServer(): ApiGatewayServer {
	const apiGatewayServer = new ApiGatewayServer();

	apiGatewayServer.server.interceptors.response.use(
		getSuccessHandler(),
		getErrorHandler(apiGatewayServer.server, getOrCreateApiSessionsServer()),
	);

	return apiGatewayServer;
}

export function getOrCreateApiGateway(): ApiGatewayServer {
	if (maybeApiGatewayServer === null) {
		maybeApiGatewayServer = createApiGatewayServer();
	}
	return maybeApiGatewayServer;
}
