import type { ProtectedRouteProps } from '../auth/ProtectedRoute';
import { EXACT_EMPTY_LAYOUT_ROUTE_PROPS } from './CommonRouteProps';
import { lazy } from 'react';

const SummaryPage = lazy(() => import(/* webpackChunkName: "SummaryPage" */ '../../pages/summary/SummaryPage'));

export const EXACT_SUMMARY_ROUTE_PROPS = {
	...EXACT_EMPTY_LAYOUT_ROUTE_PROPS,
	isSummaryView: true,
	component: SummaryPage,
} as const satisfies Omit<ProtectedRouteProps, 'path'>;
