import { theme } from '../../../theme/theme';
import { withAnalyticId, withAnalyticProps } from '../../../utilities/analytics/eventDelegation';
import styled from '@emotion/styled/macro';
import { Pagination, type PaginationProps } from 'antd';
import { observer } from 'mobx-react-lite';

const StyledPagination = styled(Pagination)`
	padding-block: ${theme.paddings.sm};
`;

interface PaginationControllerProps extends PaginationProps {}

export const PaginationController = observer(function PaginationController({ ...props }: PaginationControllerProps) {
	const { current, pageSize, total } = props;

	return (
		<StyledPagination
			{...{
				...withAnalyticId('Pagination'),
				...withAnalyticProps({
					'Current Page': current,
					'Entities Per Page': pageSize,
					'Total Entities': total,
				}),
				...props,
			}}
		/>
	);
});
