import { MAX_WIDTH_LOWER_WIDTH } from '../../base-ui/Resolutions';
import { PatientLookup } from '../../components/PatientLookup/PatientLookup';
import { PatientLookupStore, type IPatientLookupStore } from '../../components/PatientLookup/PatientLookupStore';
import { getOrCreateApiGateway } from '../../server/apiGateway';
import { theme } from '../../theme/theme';
import { withOnClickEventDelegation } from '../../utilities/analytics/eventDelegation';
import {
	sendPatientLookupAppointmentsLoadAnalytic,
	sendPatientLookupClickAnalytic,
	sendPatientLookupPageLoadAnalytic,
} from './PatientLookupPage.analytics';
import styled from '@emotion/styled/macro';
import type { PatientDemographicsRequestPayload, PatientLookupRequestPayload } from '@navina/api-types';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import type { match } from 'react-router-dom';

const PatientLookupPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	block-size: 100%;
	padding-block: ${theme.paddings.sm};
	padding-inline: ${theme.paddings.xxl};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding-block: ${theme.paddings.xs};
		padding-inline: ${theme.paddings.xxs};
	}
`;

const PatientLookupWrapper = styled(PatientLookup)`
	flex-grow: 1;
`;

interface PatientLookupPageProps {
	readonly computedMatch?: match<{ readonly documentId?: string; readonly navinaPatientId?: string }>;
}

export const PatientLookupPage = observer(function PatientLookupPage({
	computedMatch,
	...props
}: PatientLookupPageProps) {
	const { navinaPatientId } = computedMatch?.params ?? {};

	if (!navinaPatientId) {
		throw Error('Navina Patient ID not found');
	}

	const patientLookupStore = useMemo<IPatientLookupStore>(
		function createAndInitializePatientLookupStore() {
			const model = PatientLookupStore.create({
				getPatientLookupAppointments: async (requestPayload: PatientLookupRequestPayload) => {
					const response = await getOrCreateApiGateway().getPatientLookup(requestPayload);

					if (response === null) {
						throw Error('Failed to fetch patients');
					}

					sendPatientLookupAppointmentsLoadAnalytic(response);

					return response.data;
				},
				getPatientDemographics: async (requestPayload: PatientDemographicsRequestPayload) => {
					const response = await getOrCreateApiGateway().getPatientDemographics(requestPayload);

					if (response === null) {
						throw Error('Failed to fetch patient demographics');
					}

					return response.data;
				},
			});

			model.initializeNidAndFetchData(navinaPatientId);

			return model;
		},
		[navinaPatientId],
	);

	useEffect(sendPatientLookupPageLoadAnalytic, [window.location.pathname]);

	return (
		<PatientLookupPageContainer
			{...{
				...withOnClickEventDelegation(sendPatientLookupClickAnalytic),
				...props,
			}}
		>
			<PatientLookupWrapper
				{...{
					patientLookupStore,
				}}
			/>
		</PatientLookupPageContainer>
	);
});
