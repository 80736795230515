import { ErrorBoundary } from './ErrorBoundary';
import { NotFoundMessage } from './GeneralMessage';
import { NotificationProvider } from './contexts/Notification.context';
import { addFavicon } from './favicon';
import './main.scss';
import { PatientLookupPage } from './pages/PatientLookupPage/PatientLookupPage';
import { NavinaLoginStep } from './pages/auth/steps/NavinaLoginStepEnum';
import { AddTemplate } from './pages/quality-files-ingestion-tool/AddTemplate';
import { VeradigmLogin } from './pages/veradigm/login';
import { AnyoneRoute } from './react-router/auth/AnyoneRoute';
import { ExternalRedirectRoute } from './react-router/auth/ExternalRedirectRoute';
import { GuestOnlyRoute } from './react-router/auth/GuestOnlyRoute';
import { ProtectedRoute } from './react-router/auth/ProtectedRoute';
import { AuthLayout } from './react-router/layouts/AuthLayout';
import { BaseLayout } from './react-router/layouts/BaseLayout';
import { EmptyLayout } from './react-router/layouts/EmptyLayout';
import { ForeverSpinnerLayout } from './react-router/layouts/ForeverSpinnerLayout';
import { SpinnerLayout } from './react-router/layouts/SpinnerLayout';
import { EXACT_EMPTY_LAYOUT_ROUTE_PROPS } from './react-router/routes/CommonRouteProps';
import {
	EXACT_FILES_ROUTE_PROPS,
	EXACT_QUALITY_FIT_ROUTE_PROPS,
	EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
} from './react-router/routes/QualityRouteProps';
import { EXACT_SCHEDULE_ROUTE_PROPS } from './react-router/routes/ScheduleRouteProps';
import { EXACT_SUMMARY_ROUTE_PROPS } from './react-router/routes/SummaryRouteProps';
import { globalStyleWithCssVar } from './theme/globalStyle';
import { slownessMeasure } from './utilities/performances/SlownessTask';
import { browserHistory } from './utils/history';
import { NavinaDataProvider } from './utils/useNavinaData';
import { Global } from '@emotion/react/macro';
import 'normalize.css';
import { lazy } from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';

const SummaryNotFoundPage = lazy(() =>
	import(/* webpackChunkName: "SummaryNotFoundPage" */ './pages/summary/SummaryNotFoundPage').then((module) => ({
		default: module.SummaryNotFoundPage,
	})),
);

const EpicLogin = lazy(() => import(/* webpackChunkName: "EpicLogin" */ './pages/epic/login'));

const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/auth/Logout'));

const LoginForm = lazy(() => import(/* webpackChunkName: "LoginForm" */ './pages/auth/LoginForm'));

const DocumentViewerFullPage = lazy(() =>
	import(/* webpackChunkName: "DocumentViewerFullPage" */ './pages/summary/DocViewer/DocumentViewerFullPage').then(
		(module) => ({
			default: module.DocumentViewerFullPage,
		}),
	),
);

const AnalyticsPage = lazy(() =>
	import(/* webpackChunkName: "AnalyticsPage" */ './pages/analytics/AnalyticsPage').then((module) => ({
		default: module.AnalyticsPage,
	})),
);

const PostVisitPage = lazy(() => import(/* webpackChunkName: "PostVisitPage" */ './pages/post-visit/PostVisitPage'));

const AntdDemo = lazy(() => import(/* webpackChunkName: "AntdDemo" */ './pages/antd-demo/AntdDemo'));

const FileIngestionToolPage = lazy(() =>
	import(
		/* webpackChunkName: "QualityFilesIngestionToolPageContainer" */ './pages/quality-files-ingestion-tool/PageContainer'
	).then((module) => ({
		default: module.FileIngestionToolPage,
	})),
);

const FITTemplatesPage = lazy(() =>
	import(/* webpackChunkName: "QualityFitTemplatesPageContainer" */ './pages/quality-fit-templates/PageContainer').then(
		(module) => ({
			default: module.FITTemplatesPage,
		}),
	),
);

const PatientLookupHeader = lazy(() =>
	import(/* webpackChunkName: "PatientLookupHeader" */ './pages/PatientLookupPage/PatientLookupHeader').then(
		(module) => ({
			default: module.PatientLookupHeader,
		}),
	),
);

const QualityTabByNidPage = lazy(() =>
	import(/* webpackChunkName: "QualityMeasureBuilderPage" */ './pages/quality/quality-4-tab-by-nid/PageContainer').then(
		(module) => ({
			default: module.QualityTabByNid,
		}),
	),
);

const EmptyDiv = () => <div />;

export function App() {
	slownessMeasure.timeLog('React has started');
	addFavicon();

	return (
		<ErrorBoundary>
			<Global styles={globalStyleWithCssVar} />
			<Router history={browserHistory}>
				<NavinaDataProvider>
					<NotificationProvider>
						<Switch>
							<GuestOnlyRoute exact path='/sso' layout={AuthLayout} component={LoginForm} />
							<GuestOnlyRoute path='/sso/:nextPath+' layout={AuthLayout} component={LoginForm} />
							<AnyoneRoute path='/epic/login' layout={ForeverSpinnerLayout} component={EpicLogin} />
							<AnyoneRoute path='/overlay/epic/login' layout={ForeverSpinnerLayout} component={EpicLogin} />
							<AnyoneRoute path='/veradigm/launch' layout={SpinnerLayout} component={VeradigmLogin} />
							<GuestOnlyRoute path='/login/:nextPath+' layout={SpinnerLayout} component={LoginForm} />
							<GuestOnlyRoute exact path='/login' layout={SpinnerLayout} component={LoginForm} />
							<GuestOnlyRoute exact path='/overlay/login' layout={SpinnerLayout} component={LoginForm} />
							<ProtectedRoute
								exact
								path='/auth/changePassword'
								defaultStepId={NavinaLoginStep.ChangePassword}
								layout={AuthLayout}
								component={LoginForm}
							/>
							<GuestOnlyRoute exact path='/authenticatedUrl' layout={AuthLayout} component={LoginForm} />
							{/* https://github.com/aws-amplify/amplify-console/issues/97 */}
							<GuestOnlyRoute exact path='/authenticatedUrl/' layout={AuthLayout} component={LoginForm} />
							<ProtectedRoute exact path='/logout' layout={SpinnerLayout} component={Logout} />
							<ProtectedRoute
								{...{
									...EXACT_SCHEDULE_ROUTE_PROPS,
									path: '/',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SCHEDULE_ROUTE_PROPS,
									path: '/schedule',
								}}
							/>
							<ProtectedRoute
								exact
								path='/errorCheck'
								layout={BaseLayout}
								component={() => {
									throw Error('Test error');
								}}
							/>
							<ExternalRedirectRoute
								layout={EmptyLayout}
								component={EmptyDiv}
								exact
								path='/athena'
								url='https://chrome.google.com/webstore/detail/navina-burnout-research-t/plhceajggdihcnffppedpfjpflpmdakh?hl=en-GB&authuser=2'
							/>
							<ExternalRedirectRoute
								layout={EmptyLayout}
								component={EmptyDiv}
								exact
								path='/ecw'
								url='https://chrome.google.com/webstore/detail/navina-addon/clkcfpclllbaegehjacadbpejiocgkii?hl=en-GB&authuser=2'
							/>
							<ProtectedRoute
								{...{
									exact: true,
									layout: BaseLayout,
									path: '/patient-lookup/:navinaPatientId',
									component: PatientLookupPage,
									headerComponent: PatientLookupHeader,
									headerTitle: 'Patient Lookup',
									headerAppMenuSelectedPage: 'patient-portrait',
								}}
							/>
							<Redirect
								{...{
									from: '/qs',
									to: '/analytics',
								}}
							/>
							<ProtectedRoute
								{...{
									layout: BaseLayout,
									exact: true,
									path: '/analytics',
									component: AnalyticsPage,
									headerTitle: 'Navina Analytics',
									headerAppMenuSelectedPage: 'dashboard',
								}}
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/postVisit' component={PostVisitPage} />
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
									path: '/quality',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
									path: '/quality-workspace',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
									path: '/quality-archive',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
									path: '/quality-satisfaction-rate',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_FILES_ROUTE_PROPS,
									path: '/files',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_FIT_ROUTE_PROPS,
									path: '/quality-fit',
									component: FileIngestionToolPage,
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_FIT_ROUTE_PROPS,
									path: '/quality-fit-templates',
									component: FITTemplatesPage,
								}}
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/quality-fit/add-template' component={AddTemplate} />
							<ProtectedRoute
								{...{
									...EXACT_QUALITY_WORKSPACE_ROUTE_PROPS,
									path: '/quality-tab',
									component: QualityTabByNidPage,
									headerTitle: 'Quality patient view',
								}}
							/>
							<ProtectedRoute layout={EmptyLayout} exact path='/antd-demo' component={AntdDemo} />
							<ProtectedRoute
								{...{
									...EXACT_EMPTY_LAYOUT_ROUTE_PROPS,
									path: '/summary/guess-match-not-found',
									component: SummaryNotFoundPage,
									hideActionButton: false,
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/:summaryId',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/:summaryId',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/hcc/:summaryId',
									overlayPageName: 'hcc',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/clinical/:summaryId',
									overlayPageName: 'portrait',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/portrait/:summaryId',
									overlayPageName: 'portrait',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/documents/:summaryId',
									overlayPageName: 'documents',
								}}
							/>
							<ProtectedRoute
								{...{
									...EXACT_SUMMARY_ROUTE_PROPS,
									path: '/overlay/alerts/:summaryId',
									overlayPageName: 'alerts',
								}}
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/doc/:documentId'
								isSummaryView
								component={DocumentViewerFullPage}
							/>
							<ProtectedRoute
								layout={EmptyLayout}
								exact
								path='/document/:documentId'
								isSummaryView
								component={DocumentViewerFullPage}
							/>
							<AnyoneRoute layout={EmptyLayout} component={NotFoundMessage} />
						</Switch>
					</NotificationProvider>
				</NavinaDataProvider>
			</Router>
		</ErrorBoundary>
	);
}
