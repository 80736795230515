import { type CognitoNavinaJwt, useAuthStore } from '../stores/authStore';
import type { emrNames } from '../types';
import JwtDecode from 'jwt-decode';

export function useEmrName(): emrNames {
	const authStore = useAuthStore();

	let emrName;

	if (authStore && authStore.getToken) {
		const tokenInfo = JwtDecode<CognitoNavinaJwt>(authStore.getToken);
		if (tokenInfo.emrName) {
			emrName = tokenInfo.emrName;

			if (emrName) {
				emrName = emrName.toLowerCase() as emrNames;
			}
		}
	}

	return emrName;
}
