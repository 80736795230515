import { quaternaryTextStyle, theme } from '../../../theme/theme';
import styled from '@emotion/styled/macro';
import { ellipsis } from 'polished';

export const ResultsContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-block-size: 350px;
	background: ${theme.colors.white};
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0;
`;

export const TableContainer = styled.div`
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0;
	overflow-y: auto;
	position: relative;

	.clickable {
		cursor: pointer;
		:hover {
			box-shadow: ${theme.boxShadows.base};
		}
	}
`;

export const CustomizedLoadingTextContainer = styled.span`
	zoom: 0.5;
`;

export const EllipsisTextContainer = styled.div`
	${ellipsis()};
`;

export const TimeColumnTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	line-height: 1.2;
`;

export const CustomizedTextContainer = styled(EllipsisTextContainer)`
	padding: ${theme.paddings.sm};
`;

export const CustomizedTooltipTextContainer = styled.div`
	&,
	& * {
		color: ${theme.colors.white} !important;
	}
`;

export const SmallSecondaryText = styled.span`
	${quaternaryTextStyle}
	font-size: ${theme.font.sizes.sm};
`;
