import type { GetClinicsDepartmentsProvidersResponsePayload } from '../components/AppointmentsSchedule/models/types';
import { get, set } from 'idb-keyval';

interface Metadata {
	readonly key: string;
	readonly initializedTimeEpoch: number;
	readonly navinaUserId: string;
}

const PROVIDERS_KEY = 'providersOptions';
const DEPARTMENTS_KEY = 'departmentsOptions';
const INSURANCE_KEY = 'insuranceOptions';
const METADATA_PREFIX = 'metadata-';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const UPDATE_EXPIRATION_TIME = DAY_IN_MILLISECONDS;

type LoadOptionsType<T> = {
	readonly navinaUserId: string;
	readonly options: ReadonlyArray<T>;
	readonly optionsKey: string;
	readonly logName: string;
};
async function loadOptions<T>({ navinaUserId, options, optionsKey, logName }: LoadOptionsType<T>): Promise<void> {
	try {
		await set(optionsKey, options);
		await set(`${METADATA_PREFIX}${optionsKey}`, {
			key: optionsKey,
			initializedTimeEpoch: Date.now(),
			navinaUserId,
		} as Metadata);
	} catch (error) {
		console.error(`Error while loading ${logName} options:`, error);
	}
}

async function fetchOptions<T>(navinaUserId: string, optionsKey: string): Promise<T[]> {
	const cachedMetadata = await getValueFromMetadataOrNull(optionsKey);

	if (
		!cachedMetadata ||
		cachedMetadata.navinaUserId !== navinaUserId ||
		Date.now() - cachedMetadata.initializedTimeEpoch > UPDATE_EXPIRATION_TIME
	) {
		return [];
	}

	const options = await get<T[]>(optionsKey);
	return options || [];
}

async function getValueFromMetadataOrNull(key: string): Promise<Metadata | null> {
	try {
		const metadata = await get<Metadata>(`${METADATA_PREFIX}${key}`);
		return metadata || null;
	} catch {
		return null;
	}
}

// Load and Fetch Providers Options
export async function loadProvidersOptions(
	navinaUserId: string,
	providersOptions: GetClinicsDepartmentsProvidersResponsePayload['providers'],
): Promise<void> {
	await loadOptions({
		navinaUserId,
		options: providersOptions,
		optionsKey: PROVIDERS_KEY,
		logName: 'providers',
	});
}

export async function fetchProvidersOptions(
	navinaUserId: string,
): Promise<GetClinicsDepartmentsProvidersResponsePayload['providers']> {
	return fetchOptions(navinaUserId, PROVIDERS_KEY);
}

// Load and Fetch Departments Options
export async function loadDepartmentsOptions(
	navinaUserId: string,
	departmentsOptions: GetClinicsDepartmentsProvidersResponsePayload['departments'],
): Promise<void> {
	await loadOptions({
		navinaUserId,
		options: departmentsOptions,
		optionsKey: DEPARTMENTS_KEY,
		logName: 'departments',
	});
}

export async function fetchDepartmentsOptions(
	navinaUserId: string,
): Promise<GetClinicsDepartmentsProvidersResponsePayload['departments']> {
	return fetchOptions(navinaUserId, DEPARTMENTS_KEY);
}

// Load and Fetch Insurance Options
export async function loadInsuranceOptions(
	navinaUserId: string,
	insuranceOptions: GetClinicsDepartmentsProvidersResponsePayload['insuranceGroups'],
): Promise<void> {
	await loadOptions({
		navinaUserId,
		options: insuranceOptions,
		optionsKey: INSURANCE_KEY,
		logName: 'insurance',
	});
}

export async function fetchInsuranceOptions(
	navinaUserId: string,
): Promise<GetClinicsDepartmentsProvidersResponsePayload['insuranceGroups']> {
	return fetchOptions(navinaUserId, INSURANCE_KEY);
}
