import { NON_DATA_SOURCE_SELECT_PROPS } from '../../theme/theme';
import { LoadingIcon } from '../AppointmentsSchedule/assets/LoadingIcon';
import { EntitiesFetchingStatus } from '../AppointmentsSchedule/models/types';
import type { IPatientSelectorModel } from './PatientSelectorModel';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import { Gender, type NID } from '@navina/api-types';
import { Empty, Select, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ComponentProps, ReactNode } from 'react';

const MICRO_COPY = {
	PATIENT_PLACEHOLDER: 'Patient',
	PATIENT_IDLE_LABEL: 'Start typing to search for patients',
	PATIENT_PENDING_LABEL: 'Fetching patients',
	PATIENT_NOT_FOUND_LABEL: 'No patients found',
} as const;

const BlueManIcon = styled(ManOutlined)`
	color: #4f4fea;
`;

const PinkWomanIcon = styled(WomanOutlined)`
	color: #de7c8c;
`;

const GENDER_ICON_MAP = {
	[Gender.Male]: <BlueManIcon />,
	[Gender.Female]: <PinkWomanIcon />,
	[Gender.Unknown]: '',
} as const satisfies Record<Gender, ReactNode>;

function renderNotFoundContent(fetchingStatus: EntitiesFetchingStatus, optionsLength: number): ReactNode {
	if (fetchingStatus === EntitiesFetchingStatus.Idle) {
		return <Empty image={null} description={MICRO_COPY.PATIENT_IDLE_LABEL} />;
	}
	if (fetchingStatus === EntitiesFetchingStatus.Pending) {
		return <Empty image={<LoadingIcon />} description={MICRO_COPY.PATIENT_PENDING_LABEL} />;
	}
	if (fetchingStatus === EntitiesFetchingStatus.Success && optionsLength === 0) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={MICRO_COPY.PATIENT_NOT_FOUND_LABEL} />;
	}
	return <Empty image={null} description='' />;
}

const PatientIdListSelect = Select<ReadonlyArray<NID>>;

interface PatientSelectorProps extends ComponentProps<typeof PatientIdListSelect> {
	readonly patientSelectorModel: IPatientSelectorModel;
}

export const PatientSelector = observer(function PatientSelector({
	patientSelectorModel,
	placeholder = MICRO_COPY.PATIENT_PLACEHOLDER,
	...props
}: PatientSelectorProps) {
	const {
		setSelectedPatientIds,
		selectedPatientIds,
		patientOptionsFetchingStatus,
		fetchPatientOptionsWithDebounce,
		resetSelectedPatientIds,
		canSelectPatientId,
		patientSelectOptions,
	} = patientSelectorModel;

	return (
		<PatientIdListSelect
			{...{
				...NON_DATA_SOURCE_SELECT_PROPS,
				placeholder,
				onClear: resetSelectedPatientIds,
				onChange: function updatePatientIds(value): void {
					const newValues = Array.isArray(value) ? value : [value];
					setSelectedPatientIds(...newValues);
				},
				options: [...patientSelectOptions],
				optionRender: function renderOption({ label }, { index }): ReactNode {
					const patientSelectOption = patientSelectOptions[index];
					if (patientSelectOption) {
						const { gender, age, emrPatientId } = patientSelectOption.patient;

						return (
							<Space>
								<span>{GENDER_ICON_MAP[gender]}</span>
								<span>
									{[label, age && `${age.toLocaleString()} y/o`, `#${emrPatientId}`].filter(Boolean).join(' | ')}
								</span>
							</Space>
						);
					}

					return label;
				},
				notFoundContent: renderNotFoundContent(patientOptionsFetchingStatus, patientSelectOptions.length),
				value: selectedPatientIds,
				filterOption: false,
				onSearch: function setPatientSearchValueAndFetchPatients(value: string): void {
					fetchPatientOptionsWithDebounce(value);
				},
				disabled: !canSelectPatientId,
				...props,
			}}
		/>
	);
});
