import { type IPatientSelectorModel } from '../../../PatientSelector/PatientSelectorModel';
import { type ISelectedGroupPracticeModel } from '../../../SelectedGroupPractice/SelectedGroupPracticeModel';
import { EntitiesFetchingStatus, type GetClinicsDepartmentsProvidersResponsePayload } from '../types';
import {
	getEntitiesByClinicId,
	getEntitiesByDataSourceId,
	getSelectOptionsFromDepartmentOptions,
	getSelectOptionsFromInsuranceOptions,
	getSelectOptionsFromProviderOptions,
} from './RawOptionsToSelectOptionsConverters';
import {
	type ScheduleFiltersCachingStrategies,
	type Department,
	type InsuranceGroup,
	type Provider,
	ScheduleFiltersCacheableEntity,
} from './Types';
import type {
	ScheduleFilters,
	NavinaDepartmentId,
	NavinaProviderId,
	NavinaInsuranceGroupId,
	NavinaGroupPracticeId,
} from '@navina/api-types';
import { action, flow, makeAutoObservable, reaction } from 'mobx';
import type { DefaultOptionType } from 'rc-select/lib/Select';

// Type Definitions
interface GenericKeySearchFilterOptionType<T extends string | number | null | undefined> extends DefaultOptionType {
	readonly label: string;
	readonly value: T;
	readonly groupPracticeId?: number;
}

interface NumberKeySearchFilterOptionType extends GenericKeySearchFilterOptionType<number> {}

type GetClinicsDepartmentsProvidersType = () => Promise<GetClinicsDepartmentsProvidersResponsePayload>;

// Interface Definitions
export interface IScheduleSearchFiltersStore {
	// API Methods
	readonly getDepartmentsProviders: GetClinicsDepartmentsProvidersType;

	// Selected IDs
	readonly selectedDepartmentIds: ReadonlyArray<NavinaDepartmentId>;
	readonly selectedProviderIds: ReadonlyArray<NavinaProviderId>;
	readonly selectedInsuranceIds: ReadonlyArray<NavinaInsuranceGroupId>;

	// Raw Options
	readonly departmentOptionsFromAPI: ReadonlyArray<Department>;
	readonly providerOptionsFromAPI: ReadonlyArray<Provider>;
	readonly insuranceOptionsFromAPI: ReadonlyArray<InsuranceGroup>;

	// Select Options
	readonly departmentSelectOptions: ReadonlyArray<NumberKeySearchFilterOptionType>;
	readonly providerSelectOptions: ReadonlyArray<NumberKeySearchFilterOptionType>;
	readonly insuranceSelectOptions: ReadonlyArray<NumberKeySearchFilterOptionType>;

	// Fetching Statuses
	readonly departmentOptionsFetchingStatus: EntitiesFetchingStatus;
	readonly providerOptionsFetchingStatus: EntitiesFetchingStatus;
	readonly insuranceOptionsFetchingStatus: EntitiesFetchingStatus;

	readonly isAnyFilterPending: boolean;

	// Setters for Selected IDs
	readonly setSelectedDepartmentIds: (selectedDepartmentIds: ReadonlyArray<NavinaDepartmentId>) => void;
	readonly setSelectedProviderIds: (selectedProviderIds: ReadonlyArray<NavinaProviderId>) => void;
	readonly setSelectedInsuranceIds: (selectedInsuranceIds: ReadonlyArray<NavinaInsuranceGroupId>) => void;

	// Reset Selected IDs
	readonly resetSelectedDepartmentIds: VoidFunction;
	readonly resetSelectedProviderIds: VoidFunction;
	readonly resetSelectedInsuranceIds: VoidFunction;

	// Reset Options
	readonly resetDepartmentOptions: VoidFunction;
	readonly resetProviderOptions: VoidFunction;
	readonly resetInsuranceOptions: VoidFunction;

	// Selection Capabilities
	readonly canSelectDepartmentId: boolean;
	readonly canSelectProviderId: boolean;
	readonly canSelectInsuranceId: boolean;

	readonly canViewDepartmentSelector: boolean;
	readonly canViewProviderSelector: boolean;
	readonly canViewInsuranceSelector: boolean;

	// Other Methods
	readonly resetAllSelectedValues: VoidFunction;
	readonly getCurrentScheduleFilters: () => ScheduleFilters;
	readonly updateSelectOptionsBasedOnDataSource: (groupPracticeId: NavinaGroupPracticeId) => void;
	readonly refreshOptions: (groupPracticeId: NavinaGroupPracticeId) => void;

	// Computed Properties
	readonly activeFilterCategoriesCount: number;
	readonly canResetFilters: boolean;

	readonly initializeFetchAndSetRefreshReaction: VoidFunction;

	readonly selectedGroupPracticeModel: ISelectedGroupPracticeModel;
	readonly patientSelectorModel: IPatientSelectorModel;
}

export interface BaseScheduleSearchFiltersStoreParams {
	readonly getDepartmentsProviders: GetClinicsDepartmentsProvidersType;
	readonly cachingStrategies?: ScheduleFiltersCachingStrategies;
	readonly selectedGroupPracticeModel: IScheduleSearchFiltersStore['selectedGroupPracticeModel'];
	readonly patientSelectorModel: IScheduleSearchFiltersStore['patientSelectorModel'];
}

// Store Class
export class BaseScheduleSearchFiltersStore implements IScheduleSearchFiltersStore {
	readonly patientSelectorModel: IScheduleSearchFiltersStore['patientSelectorModel'];
	readonly selectedGroupPracticeModel: IScheduleSearchFiltersStore['selectedGroupPracticeModel'];
	private readonly cachingStrategies?: ScheduleFiltersCachingStrategies;

	// API Methods
	readonly getDepartmentsProviders: IScheduleSearchFiltersStore['getDepartmentsProviders'];

	// Selected IDs
	selectedDepartmentIds: IScheduleSearchFiltersStore['selectedDepartmentIds'] = [];
	selectedProviderIds: IScheduleSearchFiltersStore['selectedProviderIds'] = [];
	selectedInsuranceIds: IScheduleSearchFiltersStore['selectedInsuranceIds'] = [];

	// Raw Options
	departmentOptionsFromAPI: IScheduleSearchFiltersStore['departmentOptionsFromAPI'] = [];
	providerOptionsFromAPI: IScheduleSearchFiltersStore['providerOptionsFromAPI'] = [];
	insuranceOptionsFromAPI: IScheduleSearchFiltersStore['insuranceOptionsFromAPI'] = [];

	// Select Options
	departmentSelectOptions: IScheduleSearchFiltersStore['departmentSelectOptions'] = [];
	providerSelectOptions: IScheduleSearchFiltersStore['providerSelectOptions'] = [];
	insuranceSelectOptions: IScheduleSearchFiltersStore['insuranceSelectOptions'] = [];

	// Fetching Statuses
	departmentOptionsFetchingStatus: IScheduleSearchFiltersStore['departmentOptionsFetchingStatus'] =
		EntitiesFetchingStatus.Idle;
	providerOptionsFetchingStatus: IScheduleSearchFiltersStore['providerOptionsFetchingStatus'] =
		EntitiesFetchingStatus.Idle;
	insuranceOptionsFetchingStatus: IScheduleSearchFiltersStore['insuranceOptionsFetchingStatus'] =
		EntitiesFetchingStatus.Idle;

	private constructor({
		getDepartmentsProviders,
		cachingStrategies,
		selectedGroupPracticeModel,
		patientSelectorModel: patientSelectModel,
	}: BaseScheduleSearchFiltersStoreParams) {
		makeAutoObservable(this, {
			setSelectedDepartmentIds: action,
			setSelectedProviderIds: action,
			setSelectedInsuranceIds: action,

			setDepartmentOptionsFromAPI: action,
			setProviderOptionsFromAPI: action,
			setInsuranceOptionsFromAPI: action,

			setDepartmentSelectOptions: action,
			setProviderSelectOptions: action,
			setInsuranceSelectOptions: action,

			setDepartmentOptionsFetchingStatus: action,
			setProviderOptionsFetchingStatus: action,
			setInsuranceOptionsFetchingStatus: action,

			fetchDepartmentsProvidersFlow: flow,
		});

		this.getDepartmentsProviders = getDepartmentsProviders;
		this.cachingStrategies = cachingStrategies;
		this.selectedGroupPracticeModel = selectedGroupPracticeModel;
		this.patientSelectorModel = patientSelectModel;
	}

	readonly initializeFetchAndSetRefreshReaction = (): void => {
		this.fetchDepartmentsProvidersFlow();

		const { refreshOptions } = this;

		reaction(
			() => this.selectedGroupPracticeModel.selectedGroupPracticeId,
			function reactToSelectedGroupPracticeId(selectedGroupPracticeId): void {
				if (selectedGroupPracticeId) {
					refreshOptions(selectedGroupPracticeId);
				}
			},
		);
	};

	// Factory Method
	static readonly create = (params: BaseScheduleSearchFiltersStoreParams): BaseScheduleSearchFiltersStore => {
		return new BaseScheduleSearchFiltersStore(params);
	};

	// Public Methods

	/**
	 * Updates select options based on the selected data source.
	 */
	readonly updateSelectOptionsBasedOnDataSource = (groupPracticeId: NavinaGroupPracticeId): void => {
		const departmentOptions = getSelectOptionsFromDepartmentOptions(
			...getEntitiesByClinicId(this.departmentOptionsFromAPI, groupPracticeId),
		);
		this.setDepartmentSelectOptions(departmentOptions);
		const [firstDepartment, ...restDepartments] = departmentOptions;
		if (firstDepartment && restDepartments.length === 0) {
			this.setSelectedDepartmentIds([firstDepartment.value]);
		}

		const providerOptions = getSelectOptionsFromProviderOptions(
			...getEntitiesByClinicId(this.providerOptionsFromAPI, groupPracticeId),
		);
		this.setProviderSelectOptions(providerOptions);
		const [firstProvider, ...restProviders] = providerOptions;
		if (firstProvider && restProviders.length === 0) {
			this.setSelectedProviderIds([firstProvider.value]);
		}

		this.setInsuranceSelectOptions(
			getSelectOptionsFromInsuranceOptions(...getEntitiesByDataSourceId(this.insuranceOptionsFromAPI, groupPracticeId)),
		);
	};

	readonly setSelectedDepartmentIds = (selectedDepartmentIds: typeof this.selectedDepartmentIds): void => {
		this.selectedDepartmentIds = selectedDepartmentIds;
	};

	readonly setSelectedProviderIds = (selectedProviderIds: typeof this.selectedProviderIds): void => {
		this.selectedProviderIds = selectedProviderIds;
	};

	readonly setSelectedInsuranceIds = (selectedInsuranceIds: typeof this.selectedInsuranceIds): void => {
		this.selectedInsuranceIds = selectedInsuranceIds;
	};

	// Reset Selected IDs
	readonly resetSelectedDepartmentIds = (): void => this.setSelectedDepartmentIds([]);
	readonly resetSelectedProviderIds = (): void => this.setSelectedProviderIds([]);
	readonly resetSelectedInsuranceIds = (): void => this.setSelectedInsuranceIds([]);

	// Reset Options
	readonly resetDepartmentOptions = (): void => this.setDepartmentSelectOptions([]);
	readonly resetProviderOptions = (): void => this.setProviderSelectOptions([]);
	readonly resetInsuranceOptions = (): void => this.setInsuranceSelectOptions([]);

	/**
	 * Resets all selected values except the data source.
	 */
	readonly resetAllSelectedValues = (): void => {
		this.resetSelectedDepartmentIds();
		this.resetSelectedProviderIds();
		this.resetSelectedInsuranceIds();
		this.patientSelectorModel.resetSelectedPatientIds();
	};

	/**
	 * Gets the current schedule filters.
	 */
	readonly getCurrentScheduleFilters = (): ScheduleFilters => {
		return {
			selectedDepartmentIds: this.selectedDepartmentIds,
			selectedProviderIds: this.selectedProviderIds,
			selectedInsuranceFilterGroups: this.selectedInsuranceIds,
			selectedNids: this.patientSelectorModel.selectedPatientIds,
		};
	};

	readonly selectFirstDepartmentOptionOrThrow = (): void => {
		const [firstDepartment, ...restDepartments] = this.departmentSelectOptions;
		if (firstDepartment && restDepartments.length === 0) {
			this.setSelectedDepartmentIds([firstDepartment.value]);
		} else {
			throw new Error('There are more than one department options.');
		}
	};

	readonly selectFirstProviderOptionOrThrow = (): void => {
		const [firstProvider, ...restProviders] = this.providerSelectOptions;
		if (firstProvider && restProviders.length === 0) {
			this.setSelectedProviderIds([firstProvider.value]);
		} else {
			throw new Error('There are more than one provider options.');
		}
	};

	/**
	 * Sets the selected data source ID and refreshes all related options.
	 */
	readonly refreshOptions = (groupPracticeId: NavinaGroupPracticeId): void => {
		this.resetAllSelectedValues();
		this.patientSelectorModel.resetPatientOptions();
		this.updateSelectOptionsBasedOnDataSource(groupPracticeId);

		if (this.departmentSelectOptions.length === 1) {
			this.selectFirstDepartmentOptionOrThrow();
		}
		if (this.providerSelectOptions.length === 1) {
			this.selectFirstProviderOptionOrThrow();
		}
	};

	// Private Methods

	/**
	 * Fetches clinics, departments, providers, and insurance groups from cache or API.
	 */
	private readonly getCachedOrFetchDepartmentsProviders = async (): Promise<
		Omit<GetClinicsDepartmentsProvidersResponsePayload, 'clinics'>
	> => {
		const { Departments, Providers, InsuranceGroups } = ScheduleFiltersCacheableEntity;

		const departmentsFromCache = (await this.cachingStrategies?.[Departments].getEntities()) ?? [];
		const providersFromCache = (await this.cachingStrategies?.[Providers].getEntities()) ?? [];
		const insuranceGroupsFromCache = (await this.cachingStrategies?.[InsuranceGroups].getEntities()) ?? [];

		if (departmentsFromCache.length === 0 || providersFromCache.length === 0 || insuranceGroupsFromCache.length === 0) {
			return this.getDepartmentsProviders();
		}

		return {
			departments: departmentsFromCache,
			providers: providersFromCache,
			insuranceGroups: insuranceGroupsFromCache,
		};
	};

	/**
	 * Fetches departments, providers, and insurance groups.
	 */
	readonly fetchDepartmentsProvidersFlow = flow(function* fetchClinicsDepartmentsProvidersFlow(
		this: BaseScheduleSearchFiltersStore,
	) {
		this.resetDepartmentOptions();
		this.resetProviderOptions();
		this.resetInsuranceOptions();
		this.setDepartmentOptionsFetchingStatus(EntitiesFetchingStatus.Pending);
		this.setProviderOptionsFetchingStatus(EntitiesFetchingStatus.Pending);
		this.setInsuranceOptionsFetchingStatus(EntitiesFetchingStatus.Pending);

		try {
			const response = yield this.getCachedOrFetchDepartmentsProviders();

			const { departments, providers, insuranceGroups } = response;

			this.setDepartmentOptionsFromAPI(departments);
			this.setProviderOptionsFromAPI(providers);
			this.setInsuranceOptionsFromAPI(insuranceGroups);

			this.setDepartmentOptionsFetchingStatus(EntitiesFetchingStatus.Success);
			this.setProviderOptionsFetchingStatus(EntitiesFetchingStatus.Success);
			this.setInsuranceOptionsFetchingStatus(EntitiesFetchingStatus.Success);

			this.cachingStrategies?.[ScheduleFiltersCacheableEntity.Departments].setEntities(departments);
			this.cachingStrategies?.[ScheduleFiltersCacheableEntity.Providers].setEntities(providers);
			this.cachingStrategies?.[ScheduleFiltersCacheableEntity.InsuranceGroups].setEntities(insuranceGroups);

			const { selectedGroupPracticeId } = this.selectedGroupPracticeModel;
			if (!selectedGroupPracticeId) {
				throw new Error('Selected group practice ID is not set.');
			}
			this.refreshOptions(selectedGroupPracticeId);
		} catch (error) {
			console.error('Error while fetching entities', error);
			this.setDepartmentOptionsFetchingStatus(EntitiesFetchingStatus.Error);
			this.setProviderOptionsFetchingStatus(EntitiesFetchingStatus.Error);
			this.setInsuranceOptionsFetchingStatus(EntitiesFetchingStatus.Error);
		}
	});

	// Setters for Raw Options

	readonly setDepartmentOptionsFromAPI = (options: typeof this.departmentOptionsFromAPI): void => {
		this.departmentOptionsFromAPI = options;
	};

	readonly setProviderOptionsFromAPI = (options: typeof this.providerOptionsFromAPI): void => {
		this.providerOptionsFromAPI = options;
	};

	readonly setInsuranceOptionsFromAPI = (options: typeof this.insuranceOptionsFromAPI): void => {
		this.insuranceOptionsFromAPI = options;
	};

	// Setters for Fetching Statuses
	readonly setDepartmentOptionsFetchingStatus = (status: typeof this.departmentOptionsFetchingStatus): void => {
		this.departmentOptionsFetchingStatus = status;
	};

	readonly setProviderOptionsFetchingStatus = (status: typeof this.providerOptionsFetchingStatus): void => {
		this.providerOptionsFetchingStatus = status;
	};

	readonly setInsuranceOptionsFetchingStatus = (status: typeof this.insuranceOptionsFetchingStatus): void => {
		this.insuranceOptionsFetchingStatus = status;
	};

	// Setters for Select Options
	readonly setDepartmentSelectOptions = (options: typeof this.departmentSelectOptions): void => {
		this.departmentSelectOptions = options;
	};

	readonly setProviderSelectOptions = (options: typeof this.providerSelectOptions): void => {
		this.providerSelectOptions = options;
	};

	readonly setInsuranceSelectOptions = (options: typeof this.insuranceSelectOptions): void => {
		this.insuranceSelectOptions = options;
	};

	// Computed Properties

	/**
	 * Calculates the number of active filters.
	 */
	get activeFilterCategoriesCount(): number {
		const selectedFiltersAndOptions = {
			departments: {
				selectedFilter: this.selectedDepartmentIds,
				amountOfOptions: this.departmentSelectOptions.length,
			},
			providers: {
				selectedFilter: this.selectedProviderIds,
				amountOfOptions: this.providerSelectOptions.length,
			},
			insurances: {
				selectedFilter: this.selectedInsuranceIds,
				amountOfOptions: this.insuranceSelectOptions.length,
			},
			patients: {
				selectedFilter: this.patientSelectorModel.selectedPatientIds,
				amountOfOptions: this.patientSelectorModel.patientSelectOptions.length,
			},
		} as const;

		return Object.values(selectedFiltersAndOptions).reduce(
			(acc, filter) => acc + (filter.selectedFilter.length && filter.amountOfOptions > 1 ? 1 : 0),
			0,
		);
	}

	get canViewDepartmentSelector(): boolean {
		return this.canSelectDepartmentId || this.selectedDepartmentIds.length > 0;
	}

	get canViewProviderSelector(): boolean {
		return this.canSelectProviderId || this.selectedProviderIds.length > 0;
	}

	get canViewInsuranceSelector(): boolean {
		return this.canSelectInsuranceId || this.selectedInsuranceIds.length > 0;
	}

	get canSelectDepartmentId(): boolean {
		return this.departmentSelectOptions.length > 1;
	}

	get canSelectProviderId(): boolean {
		return this.providerSelectOptions.length > 1;
	}

	get canSelectInsuranceId(): boolean {
		return this.insuranceSelectOptions.length > 1;
	}

	get canResetFilters(): boolean {
		return this.activeFilterCategoriesCount > 0;
	}

	get isAnyFilterPending(): boolean {
		return [
			this.departmentOptionsFetchingStatus,
			this.providerOptionsFetchingStatus,
			this.insuranceOptionsFetchingStatus,
		].includes(EntitiesFetchingStatus.Pending);
	}
}

let maybeBaseScheduleSearchFiltersStore: IScheduleSearchFiltersStore | null = null;

/**
 * Retrieves or creates a singleton instance of BaseScheduleSearchFiltersStore.
 */
export function getOrCreateBaseScheduleSearchFiltersStore(
	params: BaseScheduleSearchFiltersStoreParams,
): IScheduleSearchFiltersStore {
	if (maybeBaseScheduleSearchFiltersStore) {
		return maybeBaseScheduleSearchFiltersStore;
	}

	maybeBaseScheduleSearchFiltersStore = BaseScheduleSearchFiltersStore.create(params);
	maybeBaseScheduleSearchFiltersStore.initializeFetchAndSetRefreshReaction();

	return maybeBaseScheduleSearchFiltersStore;
}
