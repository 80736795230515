import type { NavinaConfiguration } from '../config/NavinaConfiguration';
import { getCurrentConfiguration } from '../config/config';
import { isPartOfEPICLoginFlow } from '../pages/epic/epicLoginUtils';
import { getOrCreateApiGateway } from '../server/apiGateway';
import { getOrCreateAuthStore, type NavinaPermissions } from '../stores/authStore';
import { identityProviders } from '../types';
import { getFormattedCognitoDate } from '../utilities/dateFormatting';
import { addCurrentTimeUnixToLocalStorage } from './common';
import {
	AUTH_0_TOKEN,
	CREATE_VERADIGM_UNITY_JWT_TOKEN,
	EPIC_LOGIN_DOC_REDIRECT_TIME,
	NEXT_URL_QUERY_PARAMS,
	REQUEST_TIME_GET_USER_META_DATA,
	REQUEST_TO_AUTH0_TOKEN_TIME,
	RESPONSE_FROM_AUTH0_TOKEN_TIME,
	RESPONSE_TIME_GET_USER_META_DATA,
	UNITY_ENCOUNTER_ID,
	USER_METADATA,
} from './consts';
import { getOrCreateLogger } from './logger';
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
	type CognitoUserSession,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import QueryString from 'query-string';

const logger = getOrCreateLogger();

type AuthDataCallback = (
	username: string,
	session: CognitoUserSession,
	auth0Token: string,
	userMetadata: UserMetadata,
	isSSO?: boolean,
) => void;

export interface UserMetadata {
	analyticsMetadata: Record<string, string>;
	permissions: NavinaPermissions;
	emrUsername: string;
	emrName: string;
	isAdmin: boolean;
	navinaUsername: string;
	navinaEmail: string;
	sid?: string;
	dataSourceId: string;
}

interface Auth0TokenPayload {
	code: string;
	redirectUri: string;
	clientId: string;
}

interface UnityTokenPayload {
	ssoToken: string;
}

// TODO - Can we extend the existing NavinaJwt type instead of creating a new one?

interface Auth0VeradigmUnityJwtToken {
	readonly 'https://navina.ai/unity_patient_id'?: string;
	readonly 'https://navina.ai/unity_encounter_id'?: string;
}

interface Auth0AthenaJwtToken {
	readonly 'https://navina.ai/emr_encounter_id'?: string;
}

interface Auth0JwtToken extends Auth0VeradigmUnityJwtToken, Auth0AthenaJwtToken {
	readonly aud: string;
	readonly exp: number;
	readonly 'https://navina.ai/dataSourceId': string;
	readonly 'https://navina.ai/email': string;
	readonly 'https://navina.ai/doc_id'?: string;
	readonly iat: number;
	readonly iss: string;
	readonly sid: string;
	readonly sub: string;
}

export enum SignInStatus {
	initial,
	success,
	firstLoginPasswordChange,
	oldPassword,
	oldLogin,
	failure,
	totpRequired,
	totpFailure,
	credentialsFailure,
	passwordAttemptsExceeded,
	recoverPasswordRequest,
	generalError,
}

const savingTokenRelevantDataToLocalStorage = (token: string, decodedToken: Auth0JwtToken): void => {
	localStorage.setItem(AUTH_0_TOKEN, token);
	localStorage.setItem('TokenExpTime', String(decodedToken.exp));
};

const saveNextUrlToLocalStorage = (nextUrl: string): void => {
	localStorage.setItem(NEXT_URL_QUERY_PARAMS, nextUrl);
};

const saveRelevantUserMetadataToLocalStorage = (userMetadata: UserMetadata): void => {
	localStorage.setItem(USER_METADATA, JSON.stringify(userMetadata));
	localStorage.setItem('emrUsername', userMetadata.emrUsername);
	localStorage.setItem('navinaEmail', userMetadata.navinaEmail);
};

export class Auth {
	private userPool: CognitoUserPool;
	private cognitoUser: CognitoUser;
	private config: NavinaConfiguration;

	constructor() {
		this.config = getCurrentConfiguration();
		this.userPool = new CognitoUserPool({
			UserPoolId: this.config.CognitoUserPoolId,
			ClientId: this.config.CognitoClientId,
		});

		this.cognitoUser = this.userPool.getCurrentUser();
	}

	sendMFA = (
		verificationCode: string,
		callback?: (email: string, session: CognitoUserSession, status: SignInStatus) => void,
	): void => {
		this.cognitoUser.sendMFACode(
			verificationCode,
			{
				onSuccess(result): void {
					callback(null, result, SignInStatus.success);
				},
				onFailure(err): void {
					if (err.name === 'CodeMismatchException' && err.message === 'Invalid code received for user') {
						logger.trace('Wrong verification code', verificationCode);
						callback(null, null, SignInStatus.totpFailure);
					}
				},
			},
			'SOFTWARE_TOKEN_MFA',
		);
	};

	/** Sign in and authenticate a user */
	signIn = (
		email: string,
		password: string,
		afterLoginCallback?: (email: string, session: CognitoUserSession, status: SignInStatus) => void,
		verificationCode?: string,
	): void => {
		const authData = {
			Username: email,
			Password: password,
			ValidationData: { isAuthenticatedLink: 'true' },
			ClientMetadata: { Info1: 'Info2' },
		};

		const authDetails = new AuthenticationDetails(authData);
		const userData = { Username: email, Pool: this.userPool };

		this.cognitoUser = new CognitoUser(userData);

		const currentCognitoUser = this.cognitoUser;
		logger.trace('login attempt', { username: email });

		this.cognitoUser.authenticateUser(authDetails, {
			onSuccess(result: CognitoUserSession): void {
				logger.setUsername(email);
				logger.trace('login succeeded', { username: email });
				afterLoginCallback(email, result, SignInStatus.success);
			},
			onFailure(err): void {
				if (
					(err.name === 'UserLambdaValidationException' &&
						err.message === 'PreAuthentication failed with error PASSWORD_CHANGE_REQUIRED.') ||
					err.code === 'PasswordResetRequiredException'
				) {
					logger.trace('password change required, old password', email);
					afterLoginCallback(null, null, SignInStatus.oldPassword);
					return;
				}

				if (
					err.name === 'UserLambdaValidationException' &&
					err.message === 'PreAuthentication failed with error GENERAL_ERROR.'
				) {
					logger.error('general error', { email, err });
					afterLoginCallback(null, null, SignInStatus.generalError);
					return;
				}

				if (
					err.name === 'UserLambdaValidationException' &&
					err.message === 'PreAuthentication failed with error LAST_LOGIN_TOO_OLD.'
				) {
					logger.trace('login too old', email);
					afterLoginCallback(null, null, SignInStatus.oldLogin);
					return;
				}

				if (err.name === 'CodeMismatchException' && err.message === 'Invalid code received for user') {
					logger.trace('Wrong verification code', verificationCode);
					afterLoginCallback(null, null, SignInStatus.totpFailure);
					return;
				}

				if (err.code === 'PasswordResetRequiredException' || err.name === 'Password reset required for the user') {
					logger.trace('password change required, initial password');
					afterLoginCallback(null, null, SignInStatus.firstLoginPasswordChange);
					return;
				}

				if (
					err.message === 'PreAuthentication failed with error INCORRECT_USERNAME_PASSWORD.' ||
					(err.name === 'NotAuthorizedException' && err.message === 'Incorrect username or password.')
				) {
					afterLoginCallback(null, null, SignInStatus.credentialsFailure);
					return;
				}

				if (err.name === 'NotAuthorizedException' && err.message === 'Password attempts exceeded') {
					afterLoginCallback(null, null, SignInStatus.passwordAttemptsExceeded);
					return;
				}

				logger.error(err);
				logger.trace('login failed', { username: email });

				afterLoginCallback(null, null, SignInStatus.failure);
			},
			totpRequired(): void {
				logger.trace('TOTP requested', { username: email });

				if (!verificationCode) {
					afterLoginCallback(email, null, SignInStatus.totpRequired);
				} else {
					currentCognitoUser.sendMFACode(verificationCode, this, 'SOFTWARE_TOKEN_MFA');
				}
			},
			newPasswordRequired(_userAttributes, _requiredAttributes): void {
				logger.trace('password change required, initial password');
				afterLoginCallback(null, null, SignInStatus.firstLoginPasswordChange);
			},
		});
	};

	refresh = async (
		callback?: (email: string, session: CognitoUserSession, status?: SignInStatus) => void,
	): Promise<string> => {
		return new Promise<string>((resolve, reject): void => {
			const cognitoUser = this.userPool.getCurrentUser();
			if (cognitoUser) {
				cognitoUser.getSession((err: Error, session: CognitoUserSession): void => {
					if (!session || err) {
						callback(null, null);
						console.error("Session is null when refreshing session, shouldn't be", session);
						reject(err);
						return;
					}

					cognitoUser.refreshSession(
						session.getRefreshToken(),
						(refreshSessionError: any, refreshedSession: CognitoUserSession): void => {
							if (refreshedSession) {
								callback(cognitoUser.getUsername(), refreshedSession);
								resolve(refreshedSession.getRefreshToken().getToken());
							} else {
								console.error('failed refreshing token: ', refreshSessionError);
								callback(null, null);
								reject(refreshSessionError);
							}
						},
					);
				});
			} else {
				console.warn('refreshToken: no cognito user, probably never logged in');
				callback(null, null);
				reject(Error('no cognito user'));
			}
		});
	};

	refreshToken = async (): Promise<CognitoUserSession> => {
		return new Promise<CognitoUserSession>((resolve, reject): void => {
			const cognitoUser = this.userPool.getCurrentUser();
			if (cognitoUser) {
				cognitoUser.getSession((err: Error, session: CognitoUserSession): void => {
					if (!session || err) {
						console.error("Session is null when refreshing session, shouldn't be", session);
						return;
					}
					cognitoUser.refreshSession(
						session.getRefreshToken(),
						(refreshSessionError: any, refreshedSession: CognitoUserSession) => {
							if (refreshedSession) {
								resolve(refreshedSession);
							} else {
								console.error('failed refreshing token: ', refreshSessionError);
								reject(refreshSessionError);
							}
						},
					);
				});
			} else {
				reject(Error('no cognito user'));
			}
		});
	};

	signOut = (isSaml = false): void => {
		const cognitoUser = this.userPool.getCurrentUser();
		cognitoUser && cognitoUser.signOut();
		logger.setUsername(undefined);

		if (isSaml) {
			localStorage.setItem('isSaml', 'true');
			localStorage.removeItem(AUTH_0_TOKEN);
			window.location.href = `${this.config.NavinaAuth0BaseURL}v2/logout?returnTo=${this.config.Auth0LoginRedirect}&client_id=${
				this.config.Auth0ClientID
			}`;
		}
	};

	completeNewPasswordChallenge = (newPassword: string, successCallback: VoidFunction): void => {
		this.cognitoUser.completeNewPasswordChallenge(newPassword, null, {
			onSuccess(result): void {
				logger.trace('completeNewPasswordChallenge', result);
				successCallback();
			},
			onFailure(err): void {
				logger.error('error on completeNewPasswordChallenge', err);
			},
		});
	};

	requestPasswordReset = (username: string): Promise<string> => {
		return new Promise<string>((resolve, reject): void => {
			if (!this.cognitoUser) this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });

			console.log('reset attempt of password');
			this.cognitoUser.forgotPassword({
				onSuccess(): void {
					logger.trace('requestPasswordReset');
					resolve('requestPasswordReset');
				},
				onFailure(err): void {
					logger.error('error on requestPasswordReset', err);
					reject(err);
				},
			});
		});
	};

	confirmForgotPassword = (
		username: string,
		newPassword: string,
		emailCode: string,
		onSuccess: (succeed: boolean) => void,
		onFailure: (error: Error) => void,
	): void => {
		if (!this.cognitoUser) {
			this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
		}

		this.cognitoUser.confirmPassword(emailCode, newPassword, {
			onSuccess: (): void => {
				logger.trace('confirmForgotPassword');
				this.cognitoUser.updateAttributes(
					[
						{
							Name: 'custom:last_password_change',
							Value: getFormattedCognitoDate({ date: new Date(), shouldUseUTC: true }),
						},
					],
					(err, res): void => console.log('updateAttribute err: ', err, 'res: ', res),
				);
				onSuccess(true);
			},
			onFailure(err: Error): void {
				logger.error('error on confirmForgotPassword', err);
				onFailure(err);
			},
		});
	};

	changePassword = (oldPassword: string, newPassword: string, callback: (err: any, result: any) => void): void => {
		const cognitoUser = this.userPool.getCurrentUser();
		cognitoUser.getSession((err, res): void => {
			if (!err && res) {
				const accessToken = res.accessToken.jwtToken;
				axios
					.post(
						`${this.config.ApiUrl}changePassword`,
						{ accessToken, oldPassword, newPassword },
						{
							headers: { token: accessToken, 'content-type': 'application/json' },
						},
					)
					.then((changePasswordResponse): void => {
						console.log(changePasswordResponse);
						callback(null, 'success');
					})
					.catch((changePasswordError): void => {
						console.log(changePasswordError.response);
						callback(changePasswordError?.response?.data?.result?.message || 'Error changing password', null);
					});
			}
		});
	};

	getAuthData = (callback: AuthDataCallback): void => {
		const usingSSO = this.handleAuthData(callback);
		if (!usingSSO) {
			this.handleCognitoAuthData(callback);
		}
	};

	private handleCognitoAuthData = (callback: AuthDataCallback): void => {
		try {
			// first check if user is in cognito
			let userInCognito = true;
			let username: string | null = null;
			let cognitoUser: CognitoUser | undefined;

			try {
				cognitoUser = this.userPool.getCurrentUser();
				if (!cognitoUser) {
					userInCognito = false;
				}

				if (userInCognito) {
					username = cognitoUser.getUsername();
					if (!username) {
						userInCognito = false;
					}
				}
			} catch (error) {
				console.log('Error while trying to find user in cognito', error);
				userInCognito = false;
			}

			console.log(`Cognito user: ${userInCognito}`);

			if (!userInCognito) {
				logger.trace('User not logged in');
				callback(null, null, null, null);
			} else {
				logger.setUsername(username);
				cognitoUser.getSession((err, res): void => {
					if (err) {
						console.log('User not logged in');
						callback(null, null, null, null);
					} else {
						console.log('User already logged in');
						callback(username, res, null, null);
					}
				});
			}
		} catch (error) {
			console.log('ERROR occurred, User not logged in');
			console.warn(error);
			callback(null, null, null, null);
		}
	};

	private handleTokenRetrieval = async (
		apiEndpoint: string,
		payload: Auth0TokenPayload | UnityTokenPayload,
		callback: AuthDataCallback,
	): Promise<void> => {
		try {
			const trackingEpicFLow = isPartOfEPICLoginFlow();
			if (trackingEpicFLow) {
				addCurrentTimeUnixToLocalStorage(REQUEST_TO_AUTH0_TOKEN_TIME);
			}

			const response = await axios.post(`${this.config.ApiUrl}${apiEndpoint}`, payload, {
				headers: { 'content-type': 'application/json' },
			});

			if (trackingEpicFLow) {
				addCurrentTimeUnixToLocalStorage(RESPONSE_FROM_AUTH0_TOKEN_TIME);
			}

			console.log(`Token retrieved and processing: ${response.data.jwtToken || response.data.id_token}`);

			await this.handleAuth0Token(response.data.jwtToken || response.data.id_token, callback);
		} catch (error) {
			logger.error(`Failed to process token for endpoint ${apiEndpoint}`, { error });
		}
	};

	private initializeAuthProcess = (
		authType: identityProviders,
		auth0Code: string,
		unitySSOToken: string,
		callback: AuthDataCallback,
	): void => {
		const apiEndpoint = authType === identityProviders.Navnia ? CREATE_VERADIGM_UNITY_JWT_TOKEN : AUTH_0_TOKEN;
		const payload =
			authType === identityProviders.Navnia
				? { ssoToken: unitySSOToken }
				: {
						code: auth0Code,
						redirectUri: this.config.Auth0LoginRedirect,
						clientId: this.config.Auth0ClientID,
					};

		const paramToRemove = authType === identityProviders.Navnia ? 'sso' : 'code';
		this.removeUrlParam(paramToRemove);

		const authStore = getOrCreateAuthStore();
		authStore.setIsInitializing(true);

		this.handleTokenRetrieval(apiEndpoint, payload, callback);
	};

	private handleAuthData = (callback: AuthDataCallback): boolean => {
		const queryParams = QueryString.parse(window.location.search);
		const auth0Code = queryParams.code as string;
		const unitySSOToken = queryParams.sso as string;

		const isUnityLogin = Boolean(unitySSOToken) && !window.location.pathname.includes('veradigm');
		const isAuth0Login = Boolean(auth0Code) && !window.location.pathname.includes('epic');

		if (isUnityLogin || isAuth0Login) {
			const authType = isUnityLogin ? identityProviders.Navnia : identityProviders.Auth0;
			this.initializeAuthProcess(authType, auth0Code, unitySSOToken, callback);
			return true;
		}

		// Check if user logged in with SSO before
		const auth0Token = localStorage.getItem(AUTH_0_TOKEN);
		if (auth0Token) {
			const emrUsername = localStorage.getItem('emrUsername');
			const userMetadata: UserMetadata = JSON.parse(localStorage.getItem(USER_METADATA));
			console.log('Auth0 user already logged in', userMetadata);
			callback(emrUsername, null, auth0Token, userMetadata, true);
			return true;
		}

		callback(null, null, null, null);
		return false;
	};

	private handleAuth0Token = async (idToken: string, callback: AuthDataCallback): Promise<void> => {
		const decodedIdToken = JwtDecode<Auth0JwtToken>(idToken);
		const dataSourceId = decodedIdToken['https://navina.ai/dataSourceId'];
		const email = decodedIdToken['https://navina.ai/email'];
		const epicEmrEncounterId = decodedIdToken?.['https://navina.ai/emr_encounter_id'];
		const docId = decodedIdToken?.['https://navina.ai/doc_id'];
		const unityPatientId = decodedIdToken?.['https://navina.ai/unity_patient_id'];
		const unityEncounterId = decodedIdToken?.['https://navina.ai/unity_encounter_id'];

		logger.trace('Auth0 user in now logging in from identity provider', {
			dataSourceId,
			email,
			encounterId: epicEmrEncounterId,
			docId,
			token: idToken,
		});

		savingTokenRelevantDataToLocalStorage(idToken, decodedIdToken);

		try {
			const trackingEpicFLow = isPartOfEPICLoginFlow();
			if (trackingEpicFLow) {
				addCurrentTimeUnixToLocalStorage(REQUEST_TIME_GET_USER_META_DATA);
			}

			const response = await getOrCreateApiGateway().getUserMetadataV2(
				idToken,
				dataSourceId,
				epicEmrEncounterId,
				unityPatientId,
			);

			const userMetadata: UserMetadata = {
				analyticsMetadata: response.data.analytics_metadata,
				permissions: response.data.permissions,
				emrUsername: response.data.emr_username_new,
				emrName: response.data.emr_name,
				isAdmin: response.data.is_admin,
				navinaUsername: response.data.navina_username,
				sid: response.data?.sid || undefined,
				navinaEmail: response.data?.navina_email || '',
				dataSourceId: dataSourceId,
			};

			if (trackingEpicFLow) {
				addCurrentTimeUnixToLocalStorage(RESPONSE_TIME_GET_USER_META_DATA);
			}

			if (userMetadata.sid || docId) {
				const nextUrlDocOrSummary = docId ? `/doc/${docId}` : `/${userMetadata.sid}`;
				const nextUrl = window.location.pathname.includes('overlay')
					? `/overlay${nextUrlDocOrSummary}`
					: nextUrlDocOrSummary;

				if (docId && trackingEpicFLow) {
					addCurrentTimeUnixToLocalStorage(EPIC_LOGIN_DOC_REDIRECT_TIME);
				}

				saveNextUrlToLocalStorage(nextUrl);
			}

			if (
				userMetadata.emrName.toLowerCase() === 'epic' && // Epic user
				!userMetadata.sid &&
				!docId
			) {
				// No summary ID or document ID
				console.log('redirecting to schedule page'); // scheduling page for non existing sid just for epic
				saveNextUrlToLocalStorage('/');
			}

			saveRelevantUserMetadataToLocalStorage(userMetadata);
			localStorage.setItem(UNITY_ENCOUNTER_ID, unityEncounterId || '');

			console.log('Got user metadata', userMetadata);
			callback(userMetadata.emrUsername, null, idToken, userMetadata, true);
		} catch (err) {
			logger.trace('User not logged in (error)');
			console.log(err);
			callback(null, null, idToken, null, true);
		}
	};

	private removeUrlParam = (paramName: string): void => {
		const currentUrl = new URL(window.location.href);
		currentUrl.searchParams.delete(paramName);
		const newUrl = currentUrl.toString();
		window.history.replaceState(null, '', newUrl);
	};
}
