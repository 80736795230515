import AntdTokens from './antd-tokens/light.json';
import { type AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { css } from '@emotion/react/macro';
import type { SelectProps } from 'antd';

// TODO - consider moving to a separate file as described in our Notion's "Styling" page
const { padding, paddingMD, paddingSM, paddingXS, paddingXXS, paddingLG, paddingXL, paddingXXL } = AntdTokens;

// TODO - apply Nick's notes about this file from here:
// https://github.com/Navina-ai/summary-frontend/pull/1353/files#diff-199c072c046a1708097c9654913d55ca83ca8c22d8a74ed7bfb9f92e5adea4ef

const paddings = {
	xxs: `${paddingXXS}px` as '4px',
	xs: `${paddingXS}px` as '8px',
	sm: `${paddingSM}px` as '12px',
	base: `${padding}px` as '16px',
	md: `${paddingMD}px` as '20px',
	lg: `${paddingLG}px` as '24px',
	xl: `${paddingXL}px` as '32px',
	xxl: `${paddingXXL}px` as '48px',
} as const;

const { margin, marginSM, marginXS, marginXXS, marginLG, marginXL } = AntdTokens;

const margins = {
	xxs: `${marginXXS}px` as '4px',
	xs: `${marginXS}px` as '8px',
	sm: `${marginSM}px` as '12px',
	base: `${margin}px` as '16px',
	lg: `${marginLG}px` as '24px',
	xl: `${marginXL}px` as '48px',
} as const;

// "purple.1": "#efedf7",
// "purple.2": "#cfcbe6",
// "purple.3": "#b0aad4",
// "purple.4": "#9189c2",
// "purple.5": "#7268b0",
// "purple.6": "#6454bf",
// "purple.7": "#5448a4",
// "purple.8": "#453b87",
// "purple.9": "#362e6a",
// "purple.10": "#27214d",
const {
	'purple.1': purple1,
	'purple.2': purple2,
	'purple.3': purple3,
	'purple.4': purple4,
	'purple.5': purple5,
	'purple.6': purple6,
	'purple.7': purple7,
	'purple.8': purple8,
	'purple.9': purple9,
	'purple.10': purple10,
} = AntdTokens;

const colors = {
	// Primary
	// Secondary
	// Dark / Light
	white: 'white',
	whiteTransparent: 'rgba(255, 255, 255, 0.7)',
	purple1: purple1 as '#efedf7',
	purple2: purple2 as '#cfcbe6',
	purple3: purple3 as '#b0aad4',
	purple4: purple4 as '#9189c2',
	purple5: purple5 as '#7268b0',
	purple6: purple6 as '#6454bf',
	purple7: purple7 as '#5448a4',
	purple8: purple8 as '#453b87',
	purple9: purple9 as '#362e6a',
	purple10: purple10 as '#27214d',
	primary: '#322b5f',
} as const;

const {
	fontSizeSM,
	fontSize,
	fontSizeLG,
	fontSizeXL,
	fontSizeHeading1,
	fontSizeHeading2,
	fontSizeHeading3,
	fontSizeHeading4,
	fontSizeHeading5,
} = AntdTokens;
const fontSizes = {
	xs: `10px`,
	sm: `${fontSizeSM}px` as '12px',
	base: `${fontSize}px` as '14px',
	lg: `${fontSizeLG}px` as '16px',
	xl: `${fontSizeXL}px` as '20px',
	title1: `${fontSizeHeading1}px` as '38px',
	title2: `${fontSizeHeading2}px` as '30px',
	title3: `${fontSizeHeading3}px` as '24px',
	title4: `${fontSizeHeading4}px` as '20px',
	title5: `${fontSizeHeading5}px` as '16px',
} as const;

const { fontFamily } = AntdTokens;
const fontFamilies = {
	base: fontFamily,
} as const;

const fontLineHeights = {
	base: 1.5,
} as const;

const {
	colorText,
	colorPrimaryText,
	colorPrimaryTextActive,
	colorTextSecondary,
	colorTextTertiary,
	colorTextQuaternary,
} = AntdTokens;
const fontColors = {
	base: colorText,
	primary: colorPrimaryText,
	primaryActive: colorPrimaryTextActive,
	secondary: colorTextSecondary,
	tertiary: colorTextTertiary,
	quaternary: colorTextQuaternary,
} as const;

const fontWeights = {
	base: 400,
	500: 500,
	600: 600,
	bold: 700,
} as const;

const { boxShadow, boxShadowSecondary } = AntdTokens;

const boxShadows = {
	base: boxShadow,
	secondary: boxShadowSecondary,
} as const;

const { colorBorder, colorBorderSecondary } = AntdTokens;
const borderColors = {
	base: colorBorder as 'rgba(0, 0, 0, 0.15)',
	secondary: colorBorderSecondary as 'rgba(0, 0, 0, 0.06)',
} as const;

const { borderRadius, borderRadiusXS, borderRadiusSM, borderRadiusLG, borderRadiusOuter } = AntdTokens;
const borderRadii = {
	xs: `${borderRadiusXS}px` as '2px',
	sm: `${borderRadiusSM}px` as '4px',
	base: `${borderRadius}px` as '6px',
	lg: `${borderRadiusLG}px` as '8px',
	outer: `${borderRadiusOuter}px` as '4px',
} as const;

const outlines = {
	base: `1px solid ${colorBorder}` as '1px solid rgba(0, 0, 0, 0.15)',
	secondary: `1px solid ${colorBorderSecondary}` as '1px solid rgba(0, 0, 0, 0.06)',
} as const;

export const theme = {
	paddings,
	margins,
	colors,
	font: {
		sizes: fontSizes,
		families: fontFamilies,
		colors: fontColors,
		weights: fontWeights,
		lineHeights: fontLineHeights,
	},
	boxShadows,
	border: {
		colors: borderColors,
		radii: borderRadii,
	},
	outlines,
} as const;

export const baseTextStyle = css`
	font-family: ${theme.font.families.base};
	line-height: ${theme.font.lineHeights.base};
	color: ${theme.font.colors.base};
	font-size: ${theme.font.sizes.base};
`;

export const secondaryTextStyle = css`
	${baseTextStyle}
	color: ${theme.font.colors.secondary};
`;

export const quaternaryTextStyle = css`
	${baseTextStyle}
	color: ${theme.font.colors.quaternary};
`;

export const TWO_TONE_ICON_SETTINGS = {
	twoToneColor: [theme.colors.purple6, theme.colors.purple1],
} as const satisfies AntdIconProps;

export const TWO_TONE_GRAYED_ICON_SETTINGS = {
	...{ TWO_TONE_ICON_SETTINGS },
	style: { filter: 'grayscale(100%)', opacity: 0.7 },
} as const satisfies AntdIconProps;

export const EMPTY_ICON_SETTINGS = {
	imageStyle: { fontSize: 44, height: 60 },
} as const;

// TODO - consider move to "with" file
const COMMON_SELECT_PROPS = {
	showAction: ['focus', 'click'],
	style: { inlineSize: '100%' },
	showSearch: true,
} as const satisfies Partial<SelectProps>;

export const NON_DATA_SOURCE_SELECT_PROPS = {
	...COMMON_SELECT_PROPS,
	mode: 'multiple',
	allowClear: true,
} as const satisfies Partial<SelectProps>;
