import type { SchedulePageSearchRequestPayload } from '@navina/api-types';
import { action, makeAutoObservable } from 'mobx';

export interface IScheduleFiltersContextStore {
	readonly payload: SchedulePageSearchRequestPayload;
	readonly setSchedulePageSearchRequestPayload: (payload: SchedulePageSearchRequestPayload) => void;
}

export class BaseScheduleFiltersContextStore implements IScheduleFiltersContextStore {
	payload: SchedulePageSearchRequestPayload;

	private constructor() {
		makeAutoObservable(this, {
			setSchedulePageSearchRequestPayload: action,
		});
	}

	static readonly create = (): BaseScheduleFiltersContextStore => {
		return new BaseScheduleFiltersContextStore();
	};

	readonly setSchedulePageSearchRequestPayload = (payload: SchedulePageSearchRequestPayload): void => {
		this.payload = payload;
	};
}

let maybeBaseScheduleFiltersContextStore: IScheduleFiltersContextStore | null = null;

export function getOrCreateBaseScheduleFiltersContextStore(): IScheduleFiltersContextStore {
	if (maybeBaseScheduleFiltersContextStore) {
		return maybeBaseScheduleFiltersContextStore;
	}

	maybeBaseScheduleFiltersContextStore = BaseScheduleFiltersContextStore.create();

	return maybeBaseScheduleFiltersContextStore;
}
